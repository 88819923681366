import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faEye, faSearch, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { LinearProgress, Button, CircularProgress } from '@mui/material';
import { FiDownload, FiUpload } from 'react-icons/fi';
import api from '../api/axiosConfig';
import PopupAddProduct from '../screens/product/AddProduct';
import PopupDetailProduct from '../screens/product/DetailProduct';
import PopupEditProduct from '../screens/product/EditProduct';

const ProductListLayout = ({ products, setProductName, setCategory, getProducts, currentPage, setCurrentPage, onSearch, setStatus, loading }) => {
  const [totalPage, setTotalPage] = useState(0);
  const [categories, setCategories] = useState([]);
  const [showPopupProduct, setShowPopupProduct] = useState(false);
  const [showPopupDetail, setShowPopupDetail] = useState(false);
  const [showPopupEdit, setShowPopupEdit] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const pageSize = products ? products.size : 10;
  const [downloading, setDownloading] = useState(false);

  const handleCloseProduct = () => {
    getProducts();
    setShowPopupProduct(false)
  };
  const handleViewDetail = (product) => {
    setSelectedProduct(product);
    setShowPopupDetail(true);
  };
  const handleCloseDetail = () => setShowPopupDetail(false);
  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setShowPopupEdit(true);
  };
  const handleCloseEdit = () => {
    getProducts();
    setShowPopupEdit(false)
  };

  useEffect(() => {
    if (showPopupProduct || showPopupDetail || showPopupEdit) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showPopupProduct, showPopupDetail, showPopupEdit]);

  useEffect(() => {
    if (products) {
      setTotalPage(calculateTotalPages(products.total, products.size));
    }
  }, [products]);

  const fetchData = useCallback(async () => {
    try {
      const response = await getData();
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching levels:", error);
    }
    console.log(products);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const getData = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await api.get("api/category/get-all-category-list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      throw new Error('Lỗi khi lấy dữ liệu: ' + error.message);
    }
  };

  useEffect(() => {
    if (products) {
      setTotalPage(calculateTotalPages(products.total, products.size));
    }
  }, [products]);

  const calculateTotalPages = (total, size) => {
    if (size === 0) return 0;
    return Math.ceil(total / size);
  };

  const getVisiblePages = () => {
    const maxButtons = 3;
    const half = Math.floor(maxButtons / 2);
    let startPage, endPage;

    if (totalPage <= maxButtons) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (currentPage <= half) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + half >= totalPage) {
        startPage = totalPage - maxButtons + 1;
        endPage = totalPage;
      } else {
        startPage = currentPage - half;
        endPage = currentPage + half;
      }
    }
    return { startPage, endPage };
  };

  const { startPage, endPage } = getVisiblePages();
  const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  const fileInputRef = useRef(null);

  const handleChangeStatus = async (productId, productName, currentStatus) => {
    const newStatus = currentStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    const result = await Swal.fire({
      title: `<span style="color: #09446d;">Thay đổi trạng thái sản phẩm "${productName}"?</span>`,
      text: `Bạn muốn thay đổi trạng thái sang ${newStatus === 'ACTIVE' ? 'Hoạt động' : 'Ngưng hoạt động'}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E03232',
      cancelButtonColor: '#09446D',
      confirmButtonText: 'Thay đổi',
      cancelButtonText: 'Hủy',
    });
    if (result.isConfirmed) {
      try {
        const token = sessionStorage.getItem('token');
        const { data } = await api.put(`/api/product/change-status-product?id=${productId}`,
          { productId, status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(data);
        getProducts();
      } catch (error) {
        toast.error('Không thể thay đổi trạng thái, vui lòng thử lại.');
      }
    }
  };

  const getExcel = async () => {
    setDownloading(true);
    const token = sessionStorage.getItem('token');
    try {
      let url = `/api/product/download-template-import`;
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
      });
      const blobUrl = window.URL.createObjectURL(response.data);
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = 'Mau_them_san_pham.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error);
      toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
    } finally {
      setDownloading(false);
    }
  };

  const getExportExcel = async () => {
    setDownloading(true);
    const token = sessionStorage.getItem('token');
    try {
      let url = `/api/product/export-excel`;
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
      });
      const blobUrl = window.URL.createObjectURL(response.data);
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = 'Danh_sach_san_pham.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error);
      toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
    } finally {
      setDownloading(false);
    }
  };

  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    const token = sessionStorage.getItem('token');
    if (file) {
      const maxSize = 2 * 1024 * 1024;
      if (file.size > maxSize) {
        toast.error("Kích thước file phải bé hơn 2MB");
        fileInput.value = "";
        return;
      }
      try {
        const formData = new FormData();
        formData.append("file", file);
        const { data } = await api.post("/api/product/import-products", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
          },
        });
        getProducts();
        toast.success(data);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        fileInput.value = "";
      }
    }
  };

  const handleImport = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="container">
      <div className="header">
        <h2 className='title-name'>Sản Phẩm</h2>
        <div className="btnSearch" style={{ marginBottom: '0' }}>
          <select className="custom-select" name="category" onChange={(e) => setCategory(e.target.value)} >
            <option value='' selected>Nhóm sản phẩm</option>
            {categories && categories.map((category, index) => (
              <option value={category.categoryId}>{category.name}</option>
            ))}
          </select>
          <select className="custom-select" name="status" onChange={(e) => setStatus(e.target.value)}>
            <option value="" selected>Trạng thái</option>
            <option value="ACTIVE">Hoạt động</option>
            <option value="INACTIVE">Ngưng hoạt động</option>
          </select>
          <div className="search-input-container">
            <input
              name="productName"
              type="text"
              placeholder="Nhập tên sản phẩm..."
              onChange={(e) => setProductName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearch();
                }
              }}
              autocomplete="off"
            />
            <FontAwesomeIcon icon={faSearch} className="search-icon" onClick={onSearch} />
          </div>
          <div className="btnAdd">
            <Button variant="contained" onClick={() => setShowPopupProduct(true)} style={{ fontSize: '14px', padding: '6px 14px' }}>
              Thêm
            </Button>
          </div>
        </div>
        <div className="file-buttons">
          <Button className="import-btn" style={{ backgroundColor: "#5bc0de", color: '#fff' }} onClick={getExcel}>
            Tải mẫu excel
            <FontAwesomeIcon icon={faFileArrowDown} />
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
          <Button className="import-btn" style={{ backgroundColor: "#28a745", color: "#fff" }} onClick={handleImport} >
            Nhập file
            <FiUpload />
          </Button>
          <Button className="export-btn" style={{ backgroundColor: "#09446d", color: '#fff' }} onClick={getExportExcel}>
            Xuất file
            <FiDownload />
          </Button>
        </div>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <table className="list">
          <thead>
            <tr>
              <th>STT</th>
              <th>Mã</th>
              <th>Tên sản phẩm</th>
              <th>Hãng sản xuất</th>
              <th>Nhóm sản phẩm</th>
              <th>Đơn vị sản phẩm</th>
              <th>Giá nhập</th>
              <th>Giá bán</th>
              <th>Tồn</th>
              <th>Trạng thái</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={11} style={{ padding: '20px' }}>
                  <LinearProgress color="inherit" />
                </td>
              </tr>
            ) : (
              products.size > 0 ? products.listData.map((product, index) => (
                <tr key={product.id}>
                  <td>{(currentPage - 1) * pageSize + index + 1}</td>
                  <td>{product.productCode}</td>
                  <td style={{ maxWidth: '150px' }}>{product.productName}</td>
                  <td style={{ maxWidth: '150px' }}>{product.manufacturer}</td>
                  <td style={{ maxWidth: '120px' }}>{product.category}</td>
                  <td style={{ width: '80px' }}>
                    {product.productUnitList
                      .filter((unit) => unit.conversionFactor !== 0)
                      .sort((a, b) => a.conversionFactor - b.conversionFactor).length > 0 ? (
                      product.productUnitList
                        .filter((unit) => unit.conversionFactor !== 0)
                        .sort((a, b) => a.conversionFactor - b.conversionFactor)
                        .map((unit) => (
                          <p key={unit.id}>{unit.unitName}</p>
                        ))
                    ) : (
                      <span>N/A</span>
                    )}
                  </td>
                  <td>
                    {product.productUnitList
                      .filter((unit) => unit.importPrice !== 0 && unit.conversionFactor !== 0)
                      .sort((a, b) => a.conversionFactor - b.conversionFactor).length > 0 ? (
                      product.productUnitList
                        .filter((unit) => unit.importPrice !== 0 && unit.conversionFactor !== 0)
                        .sort((a, b) => a.conversionFactor - b.conversionFactor)
                        .map((unit) => (
                          <p key={unit.id}>
                            {new Intl.NumberFormat('vi-VN', {
                              style: 'currency', currency: 'VND',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(unit.importPrice)}
                          </p>
                        ))
                    ) : (
                      <span>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(0)}</span>
                    )}
                  </td>
                  <td>
                    {product.productUnitList
                      .filter((unit) => unit.retailPrice !== 0 && unit.conversionFactor !== 0)
                      .sort((a, b) => a.conversionFactor - b.conversionFactor).length > 0 ? (
                      product.productUnitList
                        .filter((unit) => unit.retailPrice !== 0 && unit.conversionFactor !== 0)
                        .sort((a, b) => a.conversionFactor - b.conversionFactor)
                        .map((unit) => (
                          <p key={unit.id}>
                            {new Intl.NumberFormat('vi-VN', {
                              style: 'currency', currency: 'VND',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(unit.retailPrice)}
                          </p>
                        ))
                    ) : (
                      <span>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(0)}</span>
                    )}
                  </td>
                  <td>{product.totalQuantity || 0}</td>
                  <td>
                    <button
                      style={{ border: 'none', cursor: 'pointer', transition: 'transform 0.1s ease' }}
                      className={product.status === 'ACTIVE' ? 'status-active' : 'status-inactive'}
                      onClick={(e) => {
                        if (sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER')) {
                          e.target.style.transform = 'scale(0.95)';
                          setTimeout(() => {
                            e.target.style.transform = 'scale(1)';
                          }, 100);
                          handleChangeStatus(product.id, product.productName, product.status);
                        }
                      }}
                    >
                      {product.status === 'ACTIVE' ? 'Hoạt động' : 'Ngưng hoạt động'}
                    </button>
                  </td>
                  <td>
                    <button className="detail-btn" onClick={() => handleViewDetail(product)}>
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                    {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                      <button className="edit-btn" onClick={() => handleEditProduct(product)}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>)}
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan={11} style={{ padding: '20px' }}>
                    Sản phẩm không tồn tại
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {totalPage > 1 && (
        <div className="pagination">
          {currentPage === 1 ? (
            <button disabled>&laquo;</button>
          ) : (
            <button onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</button>
          )}
          {startPage > 1 && (
            <>
              <button onClick={() => setCurrentPage(1)}>1</button>
              {startPage > 2 && (
                <button disabled>...</button>
              )}
            </>
          )}
          {visiblePages.map((page) => (
            <button onClick={() => setCurrentPage(page)}
              key={page}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </button>
          ))}
          {endPage < totalPage && (
            <>
              {endPage < totalPage - 1 && (
                <button disabled>...</button>
              )}
              <button onClick={() => setCurrentPage(totalPage)}>{totalPage}</button>
            </>
          )}
          {currentPage === totalPage ? (
            <button disabled>&raquo;</button>
          ) : (
            <button onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</button>
          )}
        </div>
      )}
      {downloading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 99999,
          }}
        >
          <CircularProgress size="5rem" />
        </div>
      )}
      {showPopupProduct && (<PopupAddProduct onPopupClose={handleCloseProduct} />)}
      {showPopupDetail && selectedProduct && (<PopupDetailProduct product={selectedProduct} onPopupClose={handleCloseDetail} />)}
      {showPopupEdit && selectedProduct && <PopupEditProduct product={selectedProduct} onPopupClose={handleCloseEdit} />}
    </div>
  );
};

export default ProductListLayout;