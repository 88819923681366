import React, { useEffect, useState } from 'react';
import '../styles/Footer.css';

const LoginFooter = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollPosition >= documentHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const checkPageHeight = () => {
      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;

      if (documentHeight <= windowHeight) {
        setIsVisible(true);
      }
    };

    checkPageHeight();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className={`footer2 ${isVisible ? 'visible' : ''}`}>
      <div className="footer-content">
        <p>Pharmacy &copy; 2024 SEP490_G27</p>
      </div>
    </footer>
  );
};

export default LoginFooter;