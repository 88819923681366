import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Box, LinearProgress, Modal, Button } from '@mui/material';
import Swal from 'sweetalert2';
import api from "../api/axiosConfig";
import AddTypeForm from '../screens/product/AddTypeForm';
import EditTypeForm from '../screens/product/EditTypeForm';
import { toast } from 'react-toastify';

const TypeLayout = () => {
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [types, setTypes] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [size, setSize] = useState(10);
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    fetchCategories(currentPage, size, description, status);
  }, [currentPage, size, description, status, isOpenAdd, isOpenEdit]);

  const fetchCategories = async (page, size, description, status) => {
    setLoading(true);
    const token = sessionStorage.getItem('token');
    const params = {
      page: page - 1,
      size,
    }
    if (description) {
      params.name = description;
    }
    if (status && status !== 'All') {
      params.status = status;
    }
    try {
      const response = await api.get(`/api/category/get-all-category`, {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTypes(response.data.listData);
      setTotalPage(Math.ceil(response.data.total / size));
    } catch (error) {
      if (error.status === 404) {
        setTypes([]);
      } else {
        toast.error("Lỗi khi tải dữ liệu. Vui lòng thử lại sau!");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddProduct = () => {
    setIsOpenAdd(true);
  };

  const handleEditProduct = async (id) => {
    const token = sessionStorage.getItem('token');
    const { data } = await api.get(`/api/category/get-category-by-id?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setSelectedProduct(data);
    setIsOpenEdit(true);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getVisiblePages = () => {
    const maxButtons = 3;
    const half = Math.floor(maxButtons / 2);
    let startPage, endPage;

    if (totalPage <= maxButtons) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (currentPage <= half) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + half >= totalPage) {
        startPage = totalPage - maxButtons + 1;
        endPage = totalPage;
      } else {
        startPage = currentPage - half;
        endPage = currentPage + half;
      }
    }
    return { startPage, endPage };
  };

  const handleCloseAdd = () => {
    setIsOpenAdd(false);
  };

  const handleCloseEdit = () => {
    setIsOpenEdit(false);
  };

  const { startPage, endPage } = getVisiblePages();
  const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

  const handleChangeStatus = async (typeId, typeName, currentStatus) => {
    const newStatus = currentStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    const result = await Swal.fire({
      title: `<span style="color: #09446d;">Thay đổi trạng thái nhóm sản phẩm "${typeName}"?</span>`,
      text: `Bạn muốn thay đổi trạng thái sang ${newStatus === 'ACTIVE' ? 'Hoạt động' : 'Ngưng hoạt động'}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E03232',
      cancelButtonColor: '#09446D',
      confirmButtonText: 'Thay đổi',
      cancelButtonText: 'Hủy',
    });
    if (result.isConfirmed) {
      try {
        const token = sessionStorage.getItem('token');
        const { data } = await api.put(
          `/api/category/change-status-category?id=${typeId}`,
          { typeId, status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchCategories(currentPage, size, description, status);
        toast.success(data);
      } catch (error) {
        toast.error('Không thể thay đổi trạng thái, vui lòng thử lại.');
      }
    }
  };

  return (
    <div className="container">
      <div className="header">
        <h2 className="title-name">Nhóm Sản Phẩm</h2>
        <div className="btnSearch">
          <select className="custom-select" name="status" onChange={(e) => setStatus(e.target.value)}>
            <option value="" selected>Trạng thái</option>
            <option value="ACTIVE">Hoạt động</option>
            <option value="INACTIVE">Ngưng hoạt động</option>
          </select>
          <div className="search-input-container">
            <input
              name="description"
              type="text"
              placeholder="Nhập nhóm sản phẩm..."
              onChange={(e) => setDescription(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  fetchCategories(currentPage, size, description);
                }
              }}
              autocomplete="off"
            />
            <FontAwesomeIcon icon={faSearch} className="search-icon" onClick={() => fetchCategories(currentPage, size, description, status)} />
          </div>
          <div className="btnAdd">
            <Button variant="contained" onClick={handleAddProduct} style={{ fontSize: '14px', padding: '6px 14px' }}>
              Thêm
            </Button>
          </div>
          <Modal
            open={isOpenAdd}
            onClose={handleCloseAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <AddTypeForm onCancel={() => { setIsOpenAdd(false) }} />
            </Box>
          </Modal>
          <Modal
            open={isOpenEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <EditTypeForm dataToEdit={selectedProduct} onCancel={() => { setIsOpenEdit(false) }} />
            </Box>
          </Modal>
        </div>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <table className="list">
          <thead>
            <tr>
              <th>STT</th>
              <th>Tên nhóm sản phẩm</th>
              <th>Mô tả</th>
              <th>Trạng thái</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} style={{ padding: '20px' }}>
                  <LinearProgress color="inherit" />
                </td>
              </tr>
            ) : (
              types.length !== 0 ? types.map((type, index) => (
                <tr key={type.id}>
                  <td>{(currentPage - 1) * size + index + 1}</td>
                  <td>{type.name}</td>
                  <td>{type.description || 'N/A'}</td>
                  <td>
                    <button
                      style={{ border: 'none', cursor: 'pointer', transition: 'transform 0.1s ease' }}
                      className={type.status === 'ACTIVE' ? 'status-active' : 'status-inactive'}
                      disabled={!sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER')} 
                      onClick={(e) => {
                        e.target.style.transform = 'scale(0.95)';
                        setTimeout(() => {
                          e.target.style.transform = 'scale(1)';
                        }, 100);
                        handleChangeStatus(type.id, type.name, type.status);
                      }}
                    >
                      {type.status === 'ACTIVE' ? 'Hoạt động' : 'Ngưng hoạt động'}
                    </button>
                  </td>
                  <td>
                    <button className="edit-btn" onClick={() => { handleEditProduct(type.id) }}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan={5} style={{ padding: '20px' }}>
                    Nhóm sản phẩm không tồn tại
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {totalPage > 1 && types?.length > 0 && (
        <div className="pagination">
          {currentPage === 1 ? (
            <button disabled>&laquo;</button>
          ) : (
            <a href="#" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</a>
          )}
          {startPage > 1 && (
            <>
              <a href="#" onClick={() => handlePageChange(1)}>1</a>
              {startPage > 2 && <button disabled>...</button>}
            </>
          )}
          {visiblePages.map((page) => (
            <a href="#" key={page} onClick={() => handlePageChange(page)}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </a>
          ))}
          {endPage < totalPage && (
            <>
              {endPage < totalPage - 1 && <button disabled>...</button>}
              <a href="#" onClick={() => handlePageChange(totalPage)}>{totalPage}</a>
            </>
          )}
          {currentPage === totalPage ? (
            <button disabled>&raquo;</button>
          ) : (
            <a href="#" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</a>
          )}
        </div>
      )}
    </div>
  );
};

export default TypeLayout;
