import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPenToSquare, faTrashCan, faSearch, faSave } from '@fortawesome/free-solid-svg-icons';
import { Autocomplete, Button, TextField, Divider, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import api from '../../api/axiosConfig';
import '../../styles/Import.css';
import Select from 'react-select';

const ReturnSupplierCreate = () => {
    const [products, setProducts] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [unitPrice, setUnitPrice] = useState(0);
    const [productName, setProductName] = useState('');
    const [supplier, setSupplier] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [options, setOptions] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [minQuantity, setMinQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);
    const [formData, setFormData] = useState({});
    const [note, setNote] = useState(null);
    const [batchNumber, setBatchNumber] = useState(null);
    const [remainingQuantity, setRemainingQuantity] = useState(0);
    const [conversionFactor, setConversionFactor] = useState(0);
    const [indexRow, setIndexRow] = useState(null);
    const [payPrice, setPayPrice] = useState(null);
    const navigate = useNavigate();
    const [finalDiscount, setFinalDiscount] = useState(0);
    const [searching, setSearching] = useState(false);
    const [downloading, setDownloading] = useState(false);
    let abortController = null;

    const getProducts = async (productName, signal) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `api/export-slip/get-import-item-by-product-name?productName=${productName}`;
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                signal,
            });
            const data = response.data;
            setProducts(data);
            console.log(data);
        } catch (error) {
            if (error.status === 404) {
                toast.error(error.response.data.message);
            } else if (error.status >= 400 && error.status < 500) {
                toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
            }
        } finally {
            setSearching(false);
        }
    };

    const getSuppliers = async () => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/supplier/get-all-supplier`;
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data = response.data;
            const holder = data.map(item => ({
                value: item.id,
                label: item.supplierName,
            }));
            setOptions(holder);
        } catch (error) {
            if (error.status === 404) {
                toast.error(error.response.data.message);
            } else if (error.status >= 400 && error.status < 500) {
                toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
            }
        }
    }

    useEffect(() => {
        if (selectedProduct) {
            const holder = selectedProduct.importItems.find(item => item.id.toString() === batchNumber.id.toString())
            setRemainingQuantity(holder?.remainingQuantity);
        }
    }, [batchNumber])

    useEffect(() => {
        getSuppliers();
    }, [])

    const onSearchChange = (e) => {
        setActiveIndex(-1);
        const value = e.target.value;
        setProductName(value);
        setSelectedProduct(null);
        setShowDropdown(false);
        if (abortController) {
            abortController.abort();
        }
        if (value) {
            setSearching(true);
            abortController = new AbortController();
            getProducts(value, abortController.signal);
            setShowDropdown(true);
        } else {
            setSearching(false);
            setShowDropdown(false);
            setBatchNumber('');
            setRemainingQuantity(0);
            setSelectedUnit(null);
            setUnitPrice(0);
            setMinQuantity(0);
            setQuantity(0);
            setDiscount(0);
        }
    };

    useEffect(() => {
        const total = unitPrice * quantity;
        const discountValue = 1 - (discount / 100);
        setTotalPrice(total * discountValue);
    }, [unitPrice, quantity, discount])

    const handleSupplierChange = (selectedOption) => {
        setSupplier(selectedOption);
    };

    const addProductToReceipt = () => {
        const holder = selectedProduct.importItems.find(item => item.id.toString() === batchNumber.id.toString());
        const totalPrice = unitPrice * quantity;
        setFormData((prevFormData) => ({
            products: [
                ...(prevFormData.products || []),
                {
                    id: selectedProduct.id,
                    productCode: selectedProduct.productCode,
                    productName: selectedProduct.productName,
                    batchNumber: holder?.batchNumber,
                    importDate: holder?.importDate,
                    invoiceNumber: holder?.invoiceNumber,
                    remainingQuantity: holder?.remainingQuantity,
                    unitName: selectedUnit.unitName,
                    unitPrice: unitPrice,
                    productId: selectedProduct?.productUnits[0]?.productId,
                    importItemId: holder?.id,
                    quantity: quantity || 1,
                    discount: discount || 0,
                    conversionFactor: conversionFactor,
                    totalPrice: totalPrice,
                    importItems: selectedProduct?.importItems,
                    productUnitList: selectedProduct?.productUnits.map((pUnit) => ({
                        unitName: pUnit.unitName,
                        conversionFactor: pUnit.conversionFactor,
                        unitPrice: pUnit.importPrice,
                    })),
                }
            ]
        }));
        setIndexRow(formData?.products?.length || 0);
        setFinalPrice(finalPrice + totalPrice);
        setProductName('');
        setBatchNumber('');
        setRemainingQuantity(0);
        setSelectedProduct(null);
        setSelectedUnit(null);
        setUnitPrice(0);
        setMinQuantity(0);
        setQuantity(0);
        setDiscount(0);
        setConversionFactor(0);
    }

    useEffect(() => {
        if (selectedProduct) {
            addProductToReceipt();
        }
    }, [selectedProduct])

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '280px',
            padding: '0',
            border: '1px solid #ccc',
            borderRadius: '2px',
            fontSize: '14px',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#f3f3f3',
            color: '#333',
            borderRadius: '3px',
            padding: '3px',
            margin: '2px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#333',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#333',
            cursor: 'pointer',
        }),
    };

    const handleSelectItem = (item) => {
        const firstUnitWithConversionFactor = item.productUnits.find(unit => unit.conversionFactor > 0);
        const firstItemWithRemaining = item.importItems.find(item => item.remainingQuantity > 0);
        setSelectedProduct(item);
        setBatchNumber(firstItemWithRemaining);
        setRemainingQuantity(item.totalQuantity);
        setProductName(item.productCode + ' - ' + item.productName);
        setSelectedUnit(firstUnitWithConversionFactor?.unit);
        setUnitPrice(firstUnitWithConversionFactor?.importPrice);
        setConversionFactor(firstUnitWithConversionFactor?.conversionFactor);
        setMinQuantity(1);
        setQuantity(1);
        setShowDropdown(false);
        setDiscount(0);
    };

    const handleDeleteProduct = (indexToDelete) => {
        setFinalPrice(finalPrice - formData.products[indexToDelete].totalPrice);
        setFormData((prevFormData) => ({
            ...prevFormData,
            products: prevFormData.products.filter((_, index) => index !== indexToDelete)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!supplier) {
            toast.error("Nhà cung cấp không được trống");
            return;
        }
        setDownloading(true);
        const u = sessionStorage.getItem('id');
        const submitData = {
            id: 0,
            typeDelivery: 'RETURN_TO_SUPPLIER',
            discount: 0,
            totalAmount: payPrice,
            note: note,
            userId: u,
            exportSlipItems: formData.products?.map((product) => ({
                quantity: product.quantity,
                unitPrice: product.unitPrice,
                unit: product.unitName,
                discount: product.discount,
                tax: product.vat,
                totalAmount: product.totalPrice,
                productId: product.productId,
                importItemId: product.importItemId,
                conversionFactor: product.conversionFactor,
                batchNumber: product.batchNumber,
                invoiceNumber: product.invoiceNumber
            })),
            productCount: 0,
            exportDate: "2024-10-19T11:39:53.673Z",
            supplierId: supplier?.value
        };
        console.log("submit data:");
        console.log(submitData);
        const token = sessionStorage.getItem('token');
        try {
            const { data } = await api.post(`api/export-slip/create-export-slip`, submitData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setTimeout(() => {
                toast.success(data);
            }, 500);
            navigate(`/export`);
        } catch (err) {
            console.log(err);
            if (err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error('Không thể tạo phiếu trả nhà cung cấp, vui lòng thử lại.');
            }
        } finally {
            setDownloading(false);
        }
    };

    const today = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    const calculateTotalAmount = (unitPrice, quantity, discount) => {
        const total = unitPrice * quantity;
        const discountValue = 1 - (discount / 100);
        const totalPrice = total * discountValue;
        return totalPrice;
    };

    const handleFieldChange = (index, fieldName, fieldValue) => {
        setFormData((prevFormData) => {
            const updatedImportItems = [...prevFormData.products];
            let tempAmount = updatedImportItems[index].totalPrice;
            const tempUnitPrice = updatedImportItems[index].unitPrice;
            const tempQuantity = updatedImportItems[index].quantity;
            const tempDiscount = updatedImportItems[index].discount;
            if (fieldName === 'unitPrice') {
                tempAmount = calculateTotalAmount(fieldValue, tempQuantity, tempDiscount)
            } else if (fieldName === 'quantity') {
                tempAmount = calculateTotalAmount(tempUnitPrice, fieldValue, tempDiscount)
            } else if (fieldName === 'discount') {
                tempAmount = calculateTotalAmount(tempUnitPrice, tempQuantity, fieldValue)
            }
            updatedImportItems[index] = {
                ...updatedImportItems[index],
                [fieldName]: fieldValue,
                totalPrice: tempAmount,
            };
            setFinalPrice(calculateTotalPrice(updatedImportItems));
            return {
                ...prevFormData,
                products: updatedImportItems
            };
        });
    };

    const calculateTotalDiscount = (list) => {
        let totalDiscount = 0;
        for (var i = 0; i < list?.length; i++) {
            const total = list[i].unitPrice * list[i].quantity;
            let discountValue = 1;
            if (list[i].discount && list[i].discount > 0) {
                discountValue = 1 - (list[i].discount / 100);
            }
            const totalPrice = total * discountValue;
            const discount = total - totalPrice;
            totalDiscount += discount;
        }
        return totalDiscount;
    }

    useEffect(() => {
        if (formData?.products) {
            setFinalPrice(calculateTotalPrice(formData?.products));
            setFinalDiscount(calculateTotalDiscount(formData?.products))
        }
    }, [formData])

    useEffect(() => {
        setPayPrice(finalPrice - finalDiscount);
    }, [finalPrice, finalDiscount]);

    const calculateTotalPrice = (list) => {
        let totalPrice = 0;
        for (var i = 0; i < list.length; i++) {
            const total = list[i].unitPrice * list[i].quantity;
            totalPrice += total;
        }
        return totalPrice;
    };

    const renderDate = (value) => {
        const date = new Date(Math.floor(value) * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const [activeIndex, setActiveIndex] = useState(-1);
    const itemRefs = useRef([]);

    useEffect(() => {
        if (activeIndex >= 0 && itemRefs.current[activeIndex]) {
            itemRefs.current[activeIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [activeIndex]);

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setShowDropdown(true);
            setActiveIndex((prevIndex) => Math.min(prevIndex + 1, products.length - 1));
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (e.key === "Enter" && activeIndex >= 0) {
            handleSelectItem(products[activeIndex]);
        }
    };

    return (
        <div className="receipt-container">
            <div className="header">
                <h3 className='title-name' style={{ marginBottom: '0', marginLeft: '10px' }}>
                    <Link to="/export" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Phiếu Xuất
                    </Link>
                    <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronRight} />
                    <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faChevronRight} />
                    Tạo Phiếu Xuất
                </h3>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: '4', textAlign: 'start', margin: '0 10px', borderRadius: '10px' }}>
                    <div className="search-container" style={{ position: 'relative', textAlign: 'start', marginBottom: '10px', width: '25vw' }}>
                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        <input
                            name="productName"
                            type="text"
                            value={productName}
                            placeholder="Nhập tên sản phẩm..."
                            onChange={onSearchChange}
                            onKeyDown={handleKeyDown}
                            autocomplete="off"
                        />
                        {showDropdown && (
                            <div
                                style={{ position: 'absolute', top: '100%', left: 0, height: 'auto', maxHeight: '100px', overflowY: 'auto', width: '100%', backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', zIndex: 1000 }}>
                                {products.length > 0 ? (
                                    products
                                        .filter(product => product.totalQuantity > 0)
                                        .map((product, index) => (
                                            <div key={index} ref={(el) => (itemRefs.current[index] = el)} className="onHover" onClick={() => handleSelectItem(product)} style={{ padding: '10px', cursor: 'pointer', backgroundColor: activeIndex === index ? "#f0f0f0" : "white" }}>
                                                <span>
                                                    {product.productName}<br />
                                                    <strong>Mã:</strong>{product.productCode}
                                                    <strong> - Tồn:</strong>{product.totalQuantity} ({product.productUnits?.find((unit) => unit.conversionFactor === 1).unitName})
                                                    <strong> - SĐK:</strong>{product.registrationNumber}
                                                    <strong> - Hãng SX:</strong>{product.manufacturer}
                                                </span>
                                            </div>
                                        ))
                                ) : (
                                    <div style={{ padding: '10px', cursor: 'pointer' }}>
                                        Không tìm thấy sản phẩm
                                    </div>
                                )}
                            </div>
                        )}
                        {searching && (
                            <div style={{ position: 'absolute', top: '100%', left: 0, height: 'auto', maxHeight: '100px', overflowY: 'auto', padding: '20px 0', width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', zIndex: 1000 }}>
                                <CircularProgress size={40} />
                            </div>
                        )}
                    </div>
                    <div className="import-list" style={{ height: '450px', overflow: 'auto' }}>
                        <table style={{ boxShadow: 'none' }}>
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Mã - Tên sản phẩm</th>
                                    <th>Đơn vị</th>
                                    <th>Đơn giá</th>
                                    <th>Số lượng</th>
                                    <th>C.K (%)</th>
                                    <th>Số lô</th>
                                    <th>Mã phiếu nhập</th>
                                    <th>Ngày nhập</th>
                                    <th>Tồn</th>
                                    <th>Thành tiền</th>
                                    <th>#</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {formData.products && formData.products.length > 0 && (
                                    formData.products.map((product, index) => (
                                        <>
                                            {index === indexRow ? (
                                                <tr key={index + 1} style={{ textAlign: 'center' }}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textAlign: 'left' }}>{product.productCode} - {product.productName}</td>
                                                    <td>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Autocomplete
                                                                style={{ width: '80px' }}
                                                                value={product.productUnitList.find(unit => unit.unitName === product.unitName) || null}
                                                                onChange={(newValue) => {
                                                                    handleFieldChange(index, 'unitName', newValue ? newValue.unitName : '')
                                                                    handleFieldChange(index, 'unitPrice', newValue ? newValue.unitPrice : 0)
                                                                    handleFieldChange(index, 'conversionFactor', newValue ? newValue.conversionFactor : 0)
                                                                }}
                                                                options={product.productUnitList.filter((item) => item.conversionFactor > 0)}
                                                                getOptionLabel={(option) => option.unitName}
                                                                renderInput={(params) => <TextField {...params} variant='standard' />}
                                                                disableClearable
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.unitPrice)}</td>
                                                    <td>
                                                        <TextField
                                                            style={{ width: '60px' }}
                                                            name="quantity"
                                                            type="number"
                                                            value={product.quantity}
                                                            onChange={(e) => {
                                                                if (e.target.value <= 0) {
                                                                    handleFieldChange(index, 'quantity', 1)
                                                                } else if (e.target.value > Math.floor(product?.remainingQuantity / product?.conversionFactor) || 0) {
                                                                    handleFieldChange(index, 'quantity', Math.floor(product?.remainingQuantity / product?.conversionFactor) || 0)
                                                                } else {
                                                                    handleFieldChange(index, 'quantity', e.target.value)
                                                                }
                                                            }}
                                                            inputProps={{
                                                                onKeyPress: (e) => {
                                                                    if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                                        e.preventDefault();
                                                                    }
                                                                }
                                                            }}
                                                            variant="standard"
                                                        />
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            style={{ width: '50px' }}
                                                            name="ck"
                                                            type="number"
                                                            value={product.discount}
                                                            onChange={(e) => {
                                                                if (e.target.value > 100) {
                                                                    handleFieldChange(index, 'discount', 100)
                                                                } else {
                                                                    handleFieldChange(index, 'discount', e.target.value)
                                                                }
                                                            }}
                                                            inputProps={{
                                                                onKeyPress: (e) => {
                                                                    if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                                        e.preventDefault();
                                                                    }
                                                                },
                                                                min: 0,
                                                                max: 100,
                                                            }}
                                                            variant="standard"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Autocomplete
                                                                style={{ width: '100px' }}
                                                                value={product.importItems.find(item => item.invoiceNumber === product.invoiceNumber) || null}
                                                                onChange={(newValue) => {
                                                                    handleFieldChange(index, 'batchNumber', newValue ? newValue.batchNumber : '');
                                                                    handleFieldChange(index, 'importDate', newValue ? newValue.importDate : '');
                                                                    handleFieldChange(index, 'remainingQuantity', newValue ? newValue.remainingQuantity : '');
                                                                    handleFieldChange(index, 'invoiceNumber', newValue ? newValue.invoiceNumber : '');
                                                                }}
                                                                options={product.importItems.filter((it) => it.remainingQuantity > 0)}
                                                                getOptionLabel={(option) => option.batchNumber}
                                                                renderInput={(params) => <TextField {...params} variant='standard' />}
                                                                disableClearable
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>{product.invoiceNumber}</td>
                                                    <td>{renderDate(product.importDate)}</td>
                                                    <td>{Math.floor(product?.remainingQuantity / product?.conversionFactor) || 0}</td>
                                                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.totalPrice)}</td>
                                                    <td>
                                                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                            <button className="edit-btn"
                                                                onClick={() => setIndexRow(null)}
                                                            >
                                                                <FontAwesomeIcon icon={faSave} />
                                                            </button>
                                                            <button className="delete-btn"
                                                                onClick={() => handleDeleteProduct(index)}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr key={index + 1} style={{ textAlign: 'center' }}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textAlign: 'left' }}>{product.productCode} - {product.productName}</td>
                                                    <td>{product.unitName}</td>
                                                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.unitPrice)}</td>
                                                    <td>{product.quantity}</td>
                                                    <td>{product.discount}</td>
                                                    <td>{product.batchNumber}</td>
                                                    <td>{product.invoiceNumber}</td>
                                                    <td>{renderDate(product.importDate)}</td>
                                                    <td>{product.remainingQuantity}</td>
                                                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.totalPrice)}</td>
                                                    <td>
                                                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                            <button className="edit-btn"
                                                                onClick={() => setIndexRow(index)}
                                                            >
                                                                <FontAwesomeIcon icon={faPenToSquare} />
                                                            </button>
                                                            <button className="delete-btn"
                                                                onClick={() => handleDeleteProduct(index)}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ backgroundColor: 'white', marginRight: '5px', textAlign: 'start', padding: '15px 25px', borderRadius: '10px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)', flex: '1' }}>
                    <div style={{ alignItems: 'center', textAlign: 'right', fontSize: '13px' }}>{today}</div>
                    <Divider />
                    <h4 className='title-name' style={{ color: '#022742' }}>Thông tin phiếu trả nhà cung cấp</h4>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="search-container">
                            <Select
                                value={supplier}
                                onChange={handleSupplierChange}
                                options={options}
                                required
                                styles={customStyles}
                                placeholder="Chọn nhà cung cấp"
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className='fieldContent'><span>Số lượng sản phẩm:</span></div>
                        <p style={{ color: '#09446d', fontSize: '14px' }}>{formData?.products?.length} sản phẩm</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className='fieldContent'><span>Tổng tiền hàng:</span></div>
                        <p style={{ color: '#09446d', fontSize: '14px' }}>
                            {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(finalPrice ? finalPrice : 0)}
                        </p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className='fieldContent'><span>Tổng chiết khấu:</span></div>
                        <p style={{ color: '#09446d', fontSize: '14px' }}>
                            {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(finalDiscount ? finalDiscount : 0)}
                        </p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className='fieldContent' style={{ fontWeight: 'bold' }}><span>Nhà cung cấp cần trả:</span></div>
                        <p style={{ color: '#09446d', fontWeight: 'bold', fontSize: '14px' }}>
                            {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(payPrice ? payPrice : 0)}
                        </p>
                    </div>
                    <TextField
                        name="note"
                        type="text"
                        label="Lí do trả nhà cung cấp"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        variant="standard"
                        fullWidth
                        inputProps={{
                            autoComplete: "off",
                        }}
                    />
                    <div style={{ display: 'flex', flex: '1', marginTop: '72px', justifyContent: 'center' }}>
                        <Button onClick={handleSubmit} variant="contained" className='btn' >
                            Ghi phiếu
                        </Button>
                        <Button type='btn' variant="contained" className='btn' onClick={() => { navigate('/export') }}>
                            Hủy
                        </Button>
                    </div>
                </div>
            </div>
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
        </div>
    );
};

export default ReturnSupplierCreate;