import React, { useState, useEffect } from 'react';
import axiosConfig from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const AddCustomer = ({ customer, onCancel, setSelectedCustomer }) => {
  const [downloading, setDownloading] = useState(false);
  const [formData, setFormData] = useState({
    customerName: '',
    address: '',
    phoneNumber: '',
    yob: '',
    gender: null,
  });

  useEffect(() => {
    if (customer) {
      setFormData({
        customerName: customer.customerName || '',
        address: customer.address || '',
        phoneNumber: customer.phoneNumber || '',
        yob: customer.yob || '',
        gender: customer.gender || '',
      });
    }
  }, [customer]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index + 1]?.focus();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDownloading(true);
    const token = sessionStorage.getItem('token');
    try {
      const response = await axiosConfig.post('/api/customer/create-customer', {
        ...formData,
        yob: parseInt(formData.yob),
        gender: formData.gender,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (setSelectedCustomer) {
        setSelectedCustomer(response.data);
      }
      toast.success("Tạo thành công");
      setTimeout(() => {
        onCancel();
      }, 1000);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Không thể thêm khách hàng, vui lòng thử lại.');
    } finally {
      setDownloading(false);
    }
  };

  const styles = {
    container: {
      width: '500px',
      marginTop: '80px',
      backgroundColor: '#ffffff',
      padding: '30px',
      borderRadius: '10px',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    formColumn: {
      width: '100%',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '15px',
    },
    fieldText: {
      fontWeight: 'bold',
      color: '#072f4d',
      marginBottom: '5px',
    },
    input: {
      width: '97%',
      padding: '6px',
      border: '2px solid #ccc',
      borderRadius: '6px',
      outline: 'none',
      fontSize: '14px',
      transition: 'border-color 0.3s ease',
    },
    selectContainer: {
      display: 'flex',
      gap: '10px',
      marginBottom: '10px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '25px',
    },
    button: {
      backgroundColor: '#072f4d',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 20px',
      cursor: 'pointer',
      margin: '0 10px',
      transition: 'background-color 0.3s ease',
    },
    buttonHover: {
      backgroundColor: '#1c3f5d',
    },
  };

  return (
    <form onSubmit={handleSubmit} style={styles.container}>
      <h2 style={{ textAlign: 'center' }}>Thêm khách hàng</h2>
      <div style={styles.formColumn}>
        <div style={styles.fieldContainer}>
          <label style={styles.fieldText}>
            Tên khách hàng <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type='text'
            name='customerName'
            value={formData.customerName}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label style={styles.fieldText}>Địa chỉ</label>
          <input
            type='text'
            name='address'
            value={formData.address}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label style={styles.fieldText}>
            Số điện thoại <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type='text'
            name='phoneNumber'
            value={formData.phoneNumber}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label style={styles.fieldText}>
            Năm sinh
          </label>
          <input
            type='number'
            name='yob'
            value={formData.yob}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label style={styles.fieldText}>Giới tính</label>
          <select
            name='gender'
            value={formData.gender}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={{
              width: '99.5%',
              padding: '6px',
              border: '2px solid #ccc',
              borderRadius: '6px',
              outline: 'none',
              fontSize: '14px',
              transition: 'border-color 0.3s ease',
            }}
          >
            <option value=''>Chọn giới tính</option>
            <option value='MALE'>Nam</option>
            <option value='FEMALE'>Nữ</option>
          </select>
        </div>
      </div>

      <div style={styles.buttons}>
        <button
          type='submit'
          style={{ ...styles.button, ':hover': styles.buttonHover }}
        >
          Thêm
        </button>
        <button
          type='button'
          onClick={onCancel}
          style={{ ...styles.button, ':hover': styles.buttonHover }}
        >
          Hủy
        </button>
      </div>
      {downloading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 99999,
          }}
        >
          <CircularProgress size="5rem" />
        </div>
      )}
    </form>
  );
};

export default AddCustomer;