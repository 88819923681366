import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPlus, faTrashCan, faPenToSquare, faSearch, faSave } from '@fortawesome/free-solid-svg-icons';
import { Box, Modal, TextField, Autocomplete, Drawer, Divider, MenuItem, Menu, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import api from '../api/axiosConfig';
import { useNavigate, Link } from 'react-router-dom';
import AddCustomer from '../screens/customer/AddCustomer';
import { usePayOS } from "payos-checkout";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useLocation } from 'react-router-dom';

const SaleReceiptLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [products, setProducts] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [productName, setProductName] = useState('');
    const [customer, setCustomer] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [finalPrice, setFinalPrice] = useState(0);
    const [payPrice, setPayPrice] = useState(null);
    const [formData, setFormData] = useState({});
    const [paymentMethod, setPaymentMethod] = useState('Chuyển khoản');
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [indexRow, setIndexRow] = useState(0);
    const [isPayOSOpen, setIsPayOSOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [bill, setBill] = useState(null);
    const [finalDiscount, setFinalDiscount] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [tempOrder, setTempOrder] = useState(null);
    const [searching, setSearching] = useState(false);
    const [downloading, setDownloading] = useState(false);
    let abortController = null;

    const [payOSConfig, setPayOSConfig] = useState({
        RETURN_URL: '',
        ELEMENT_ID: '',
        CHECKOUT_URL: '',
        embedded: true,
        onSuccess: () => { },
        onCancel: () => { },
    });

    const { open } = usePayOS(payOSConfig);
    const handleClose = () => setAnchorEl(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const getCustomer = async () => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/customer/get-customer-by-id?id=1`;
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data = response.data;
            setSelectedCustomer(data);
            setCustomerName(data.customerName);
            setCustomer(data);
            setPhoneNumber(data.phoneNumber);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    useEffect(() => {
        if (products) {
            setTotalPage(calculateTotalPages(products.total, products.size));
        }
    }, [products]);

    useEffect(() => {
        getCustomer();
    }, []);

    const calculateTotalPages = (total, size) => {
        if (size === 0) return 0;
        return Math.ceil(total / size);
    };

    const getVisiblePages = () => {
        const maxButtons = 3;
        const half = Math.floor(maxButtons / 2);
        let startPage, endPage;
        if (totalPage <= maxButtons) {
            startPage = 1;
            endPage = totalPage;
        } else {
            if (currentPage <= half) {
                startPage = 1;
                endPage = maxButtons;
            } else if (currentPage + half >= totalPage) {
                startPage = totalPage - maxButtons + 1;
                endPage = totalPage;
            } else {
                startPage = currentPage - half;
                endPage = currentPage + half;
            }
        }
        return { startPage, endPage };
    }

    const { startPage, endPage } = getVisiblePages();
    const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    const getProducts = async (page, productName) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/product/get-all-product-sale-order-paging?page=${page - 1}&size=10`;
            if (productName) url += `&productName=${productName}`;
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data = response.data;
            console.log(response);
            setProducts(data);
        } catch (error) {
            if (error.status === 404) {
                setProducts([]);
            } else if (error.status >= 400 && error.status < 500) {
                toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
            }
        }
    };

    const handlePayment = async (data) => {
        if (!selectedCustomer) {
            toast.error("Vui lòng chọn khách hàng!");
            return;
        }
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/payment/create-payment-link`;
            let path = window.location.origin;
            console.log("path", JSON.stringify(location));
            const response = await api.post(
                url,
                {
                    totalAmount: payPrice,
                    description: 'testing',
                    returnUrl: path + '/sale/receipt',
                    cancelUrl: path + '/sale/receipt/create',
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            setPayOSConfig({
                RETURN_URL: path + "/sale/receipt",
                ELEMENT_ID: 'payOS',
                CHECKOUT_URL: `https://pay.payos.vn/web/` + response.data.data.paymentLinkId,
                embedded: true,
                onSuccess: () => { handlePaymentSuccess(data) },
                onCancel: () => {
                    setIsPayOSOpen(false)
                },
            });
            setIsPayOSOpen(true);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    useEffect(() => {
        if (isPayOSOpen === true) {
            open();
        }
    }, [isPayOSOpen])

    const handlePaymentSuccess = async (data) => {
        const token = sessionStorage.getItem('token');
        if (!data) {
            toast.error('Không tìm thấy đơn hàng');
            setIsPayOSOpen(false);
            setTempOrder(null);
            return;
        }
        try {
            let url = `/api/sale/complete-payment/${data}`;
            await api.put(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            toast.success("Tạo thành công");
            handleShowBill(data);
            setTempOrder(null);
            setIsPayOSOpen(false);
            setOpen(false);
        } catch (error) {
            setIsPayOSOpen(false);
            setTempOrder(null);
            toast.error('Không thể tạo hóa đơn, vui lòng thử lại.');
        }
    }

    useEffect(() => {
        getProducts(currentPage, productName);
    }, [currentPage, productName]);

    const getCustomers = async (phoneNumber, signal) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/customer/get-customer-by-phone-number?phoneNumber=${phoneNumber}`;
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                signal
            });
            const data = response.data;
            setCustomer(data);
        } catch (error) {
            if (error.status === 404) {
                toast.error(error.response.data.message);
            } else if (error.status >= 400 && error.status < 500) {
                toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
            }
        } finally {
            setSearching(false);
        }
    }

    const handleSetSelectedCustomer = (e) => {
        console.log(e);
        setCustomerName(e.customerName);
        setSelectedCustomer(e);
        setPhoneNumber('');
    }

    const handleCustomerChange = (e) => {
        const value = e.target.value;
        setCustomerName(value);
        setPhoneNumber('');
        setSelectedCustomer(null);
        setShowDropdown1(false);
        if (abortController) {
            abortController.abort();
        }
        if (value) {
            setSearching(true);
            abortController = new AbortController();
            getCustomers(value, abortController.signal);
            setShowDropdown1(true);
        } else {
            setSearching(false);
            setShowDropdown1(false);
        }
    };

    const handleSelectCustomer = (customer) => {
        setShowDropdown1(false);
        setSelectedCustomer(customer);
        setCustomerName(customer.customerName);
        setPhoneNumber(customer.phoneNumber);
    };

    const [open1, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
        if (newOpen === false) {
            setIsPayOSOpen(false);
        }
    };

    const addProductToReceipt = (selectedProduct) => {
        if (formData?.products?.find(product => product.id === selectedProduct.id)) {
            toast.error("Sản phẩm đã tồn tại trong hóa đơn!");
            return;
        }
        const maxConversionUnit = selectedProduct.productUnitList.reduce((max, current) => {
            return current.conversionFactor > (max?.conversionFactor || 0) ? current : max;
        }, null);
        setFormData((prevFormData) => ({
            products: [
                ...(prevFormData.products || []),
                {
                    id: selectedProduct.id,
                    productCode: selectedProduct.productCode,
                    productName: selectedProduct.productName,
                    unitName: maxConversionUnit?.unitName,
                    unitPrice: maxConversionUnit?.retailPrice,
                    conversionFactor: maxConversionUnit?.conversionFactor,
                    remainingQuantity: selectedProduct.totalQuantity,
                    quantity: 1,
                    totalPrice: maxConversionUnit?.retailPrice,
                    productUnitList: selectedProduct?.productUnitList.map((pUnit) => ({
                        unitName: pUnit.unitName,
                        conversionFactor: pUnit.conversionFactor,
                        unitPrice: pUnit.retailPrice,
                    })),
                }
            ]
        }));
        if (Array.isArray(formData?.products)) {
            setIndexRow(formData.products.length);
        }
    };

    const handleDeleteProduct = (indexToDelete) => {
        setFinalPrice(finalPrice - formData.products[indexToDelete].totalPrice);
        setIndexRow(null);
        const updatedProducts = formData.products.filter((_, index) => index !== indexToDelete);
        setFormData((prevFormData) => ({
            ...prevFormData,
            products: updatedProducts
        }));
    };

    const handleNavigation = () => {
        setIsOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setIsOpenAdd(false);
    };

    const handleSubmit = async (e) => {
        console.log(formData);
        if (!selectedCustomer) {
            toast.error("Khách hàng không được trống");
            return;
        }
        setDownloading(true);
        const u = sessionStorage.getItem('id');
        const [day, month, year] = currentDate.split('/');
        const saleDate = new Date(`${year}-${month}-${day}T00:00:00Z`).toISOString();
        const submitData = {
            paymentMethod: e === 'Chuyển khoản' ? 'TRANSFER' : 'CASH',
            totalAmount: payPrice,
            discount: finalDiscount,
            userId: u,
            saleDate: saleDate,
            customerId: selectedCustomer.id,
            orderType: 'NORMAL',
            saleOrderItems: formData.products?.map((product) => ({
                quantity: product.quantity,
                unitPrice: product.unitPrice,
                unit: product.unitName,
                discount: product.discount || 0,
                totalAmount: product.totalPrice,
                productId: product.id,
                batchNumber: product.batchNumber,
                conversionFactor: product.conversionFactor
            })),
        };
        const token = sessionStorage.getItem('token');
        try {
            const { data } = await api.post(`api/sale/create-sale-order`, submitData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            if (e === 'Chuyển khoản') {
                handlePayment(data);
                setTempOrder(data);
            } else {
                setTimeout(() => {
                    handleShowBill(data);
                    setOpen(false);
                    toast.success("Tạo thành công");
                }, 500);
            }
        } catch (err) {
            console.log(err);
            if (err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error('Không thể tạo hóa đơn, vui lòng thử lại.');
            }
        } finally {
            setDownloading(false);
        }
    };

    const handleShowBill = async (id) => {
        const token = sessionStorage.getItem('token');
        try {
            const { data } = await api.get(`api/sale/pdf/${id}?paperSize=A5`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            getCustomer();
            setFinalDiscount(0);
            setFinalPrice(0);
            setPayPrice(0);
            setFormData([]);
            setBill(data);
            console.log(data);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error('Không thể xuất hóa đơn, vui lòng thử lại.');
            }
        }
    };

    const today = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    const calculateTotalAmount = (unitPrice, quantity, discount) => {
        const total = unitPrice * quantity;
        const discountValue = 1 - (discount / 100);
        const totalPrice = total * discountValue;
        return totalPrice;
    };

    const handleFieldChange = (index, fieldName, fieldValue) => {
        setFormData((prevFormData) => {
            const updatedImportItems = [...prevFormData.products];
            let tempAmount = updatedImportItems[index].totalPrice || 0;
            const tempUnitPrice = updatedImportItems[index].unitPrice || 0;
            const tempQuantity = updatedImportItems[index].quantity || 0;
            const tempDiscount = updatedImportItems[index].discount || 0;
            if (fieldName === 'unitPrice') {
                tempAmount = calculateTotalAmount(fieldValue, tempQuantity, tempDiscount)
            } else if (fieldName === 'quantity') {
                tempAmount = calculateTotalAmount(tempUnitPrice, fieldValue, tempDiscount)
            } else if (fieldName === 'discount') {
                tempAmount = calculateTotalAmount(tempUnitPrice, tempQuantity, fieldValue)
            }
            updatedImportItems[index] = {
                ...updatedImportItems[index],
                [fieldName]: fieldValue,
                totalPrice: tempAmount,
            };
            return {
                ...prevFormData,
                products: updatedImportItems
            };
        });
    };

    useEffect(() => {
        setPayPrice(finalPrice - finalDiscount);
    }, [finalPrice, finalDiscount]);

    const calculateTotalPrice = (list) => {
        let totalPrice = 0;
        for (var i = 0; i < list?.length; i++) {
            const total = list[i].unitPrice * list[i].quantity;
            totalPrice += total;
        }
        return totalPrice;
    }

    const calculateTotalDiscount = (list) => {
        let totalDiscount = 0;
        for (var i = 0; i < list?.length; i++) {
            const total = list[i].unitPrice * list[i].quantity;
            let discountValue = 1;
            if (list[i].discount && list[i].discount > 0) {
                discountValue = 1 - (list[i].discount / 100);
            }
            const totalPrice = total * discountValue;
            const discount = total - totalPrice;
            totalDiscount += discount;
        }
        return totalDiscount;
    }

    useEffect(() => {
        if (formData?.products) {
            setFinalPrice(calculateTotalPrice(formData?.products));
            setFinalDiscount(calculateTotalDiscount(formData?.products))
            setTempOrder(null);
        }
    }, [formData])

    const currentDate = new Date().toLocaleDateString('en-GB');
    const [activeIndex, setActiveIndex] = useState(-1);
    const itemRefs = useRef([]);

    useEffect(() => {
        if (activeIndex >= 0 && itemRefs.current[activeIndex]) {
            itemRefs.current[activeIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [activeIndex]);

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setShowDropdown1(true);
            setActiveIndex((prevIndex) => Math.min(prevIndex + 1, customer.length - 1));
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (e.key === "Enter" && customer.length >= 0) {
            handleSelectCustomer(customer[activeIndex]);
        }
    };

    return (
        <div className="receipt-container">
            <div className="header">
                <h3 className='title-name' style={{ marginBottom: '0', marginLeft: '10px' }}>
                    <Link to="/sale" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Bán Hàng
                    </Link>
                    <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronRight} />
                    <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faChevronRight} />
                    Tạo Hóa Đơn
                </h3>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: '2.5', textAlign: 'start', padding: '0 10px', borderRadius: '10px' }}>
                    <div style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center', width: '20vw' }}>
                        <div className="search-container" style={{ position: 'relative', textAlign: 'start', flex: '1' }}>
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                            <input
                                name="customerName"
                                type="text"
                                value={customerName || selectedCustomer?.customerName}
                                placeholder="Nhập khách hàng..."
                                onChange={handleCustomerChange}
                                onKeyDown={handleKeyDown}
                                autocomplete="off"
                            />
                            {showDropdown1 && (
                                <div style={{ position: 'absolute', top: '100%', left: 0, height: 'auto', maxHeight: '100px', overflowY: 'auto', width: '100%', backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', zIndex: 1000 }}>
                                    {customer && customer?.length > 0 ? (
                                        customer.map((customer, index) => (
                                            <div key={index} ref={(el) => (itemRefs.current[index] = el)} className="onHover" onClick={() => handleSelectCustomer(customer)} style={{ padding: '10px', cursor: 'pointer', backgroundColor: activeIndex === index ? "#f0f0f0" : "white" }}>
                                                <span>{customer.customerName}<br />
                                                    <strong>SĐT:</strong>{customer.phoneNumber}
                                                </span>
                                            </div>
                                        ))
                                    ) : (
                                        <div style={{ padding: '10px', cursor: 'pointer' }}>
                                            Không tìm thấy khách hàng
                                        </div>
                                    )}
                                </div>
                            )}
                            {searching && (
                                <div style={{ position: 'absolute', top: '100%', left: 0, height: 'auto', maxHeight: '100px', overflowY: 'auto', padding: '20px 0', width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', zIndex: 1000 }}>
                                    <CircularProgress size={40} />
                                </div>
                            )}
                        </div>
                        <div className="add" style={{ marginRight: '20px' }}>
                            <button type="add" onClick={handleNavigation}>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </div>
                    </div>
                    <div className="import-list" style={{ height: '510px' }}>
                        <div style={{ height: '410px', overflow: 'auto' }}>
                            <table style={{ boxShadow: 'none' }}>
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>Mã - Tên sản phẩm</th>
                                        <th>Đơn vị</th>
                                        <th>Đơn giá</th>
                                        <th>Số lượng</th>
                                        <th>C.K (%)</th>
                                        <th>Tồn</th>
                                        <th>Thành tiền</th>
                                        <th>#</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: 'center' }}>
                                    {formData?.products && formData?.products?.length > 0 && (
                                        formData.products.map((product, index) => (
                                            <>
                                                {index === indexRow ? (
                                                    <tr key={index + 1} style={{ textAlign: 'center' }}>
                                                        <td>{index + 1}</td>
                                                        <td style={{ textAlign: 'left' }}>{product.productCode} - {product.productName}</td>
                                                        <td>
                                                            <Autocomplete
                                                                style={{ width: '80px', display: 'flex', justifyContent: 'center' }}
                                                                value={product.productUnitList.find(unit => unit.unitName === product.unitName) || null}
                                                                onChange={(event, newValue) => {
                                                                    handleFieldChange(index, 'unitName', newValue ? newValue.unitName : '')
                                                                    handleFieldChange(index, 'unitPrice', newValue ? newValue.unitPrice : 0)
                                                                    handleFieldChange(index, 'conversionFactor', newValue ? newValue.conversionFactor : 0)
                                                                    if (product.quantity > (Math.floor(product.remainingQuantity / newValue.conversionFactor))) {
                                                                        handleFieldChange(index, 'quantity', Math.floor(product.remainingQuantity / newValue.conversionFactor))
                                                                    }
                                                                }}
                                                                options={product.productUnitList.filter((item) => item.conversionFactor > 0)}
                                                                getOptionLabel={(option) => option.unitName}
                                                                renderInput={(params) => <TextField {...params} variant='standard' />}
                                                                disableClearable
                                                            />
                                                        </td>
                                                        <td>{product.unitPrice || 0}</td>
                                                        <td>
                                                            <TextField
                                                                style={{ width: '60px' }}
                                                                name="quantity"
                                                                type="number"
                                                                value={product.quantity}
                                                                onChange={(e) => {
                                                                    if (e.target.value <= 0) {
                                                                        handleFieldChange(index, 'quantity', 1)
                                                                    } else if (e.target.value > Math.floor(product?.remainingQuantity / product?.conversionFactor) || 0) {
                                                                        handleFieldChange(index, 'quantity', Math.floor(product?.remainingQuantity / product?.conversionFactor) || 0)
                                                                    } else {
                                                                        handleFieldChange(index, 'quantity', e.target.value)
                                                                    }
                                                                }}
                                                                inputProps={{
                                                                    onKeyPress: (e) => {
                                                                        if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                                            e.preventDefault();
                                                                        }
                                                                    }
                                                                }}
                                                                variant="standard"
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                style={{ width: '50px' }}
                                                                name="ck"
                                                                type="number"
                                                                value={product.discount || 0}
                                                                onChange={(e) => {
                                                                    if (e.target.value > 100) {
                                                                        handleFieldChange(index, 'discount', 100)
                                                                    } else {
                                                                        handleFieldChange(index, 'discount', e.target.value)
                                                                    }
                                                                }}
                                                                inputProps={{
                                                                    onKeyPress: (e) => {
                                                                        if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                                            e.preventDefault();
                                                                        }
                                                                    },
                                                                    min: 0,
                                                                    max: 100,
                                                                }}
                                                                variant="standard"
                                                            />
                                                        </td>
                                                        <td>{Math.floor(product?.remainingQuantity / product?.conversionFactor) || 0}</td>
                                                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.totalPrice)}</td>
                                                        <td>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <button className="edit-btn"
                                                                    onClick={() => setIndexRow(null)}
                                                                >
                                                                    <FontAwesomeIcon icon={faSave} />
                                                                </button>
                                                                <button className="delete-btn"
                                                                    onClick={() => handleDeleteProduct(index)}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr key={index + 1} style={{ textAlign: 'center' }}>
                                                        <td>{index + 1}</td>
                                                        <td style={{ textAlign: 'left' }}>{product.productCode} - {product.productName}</td>
                                                        <td>{product.unitName}</td>
                                                        <td>{product?.unitPrice ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.unitPrice) : 0}</td>
                                                        <td>{product?.quantity}</td>
                                                        <td>{product?.discount || 0}</td>
                                                        <td>{Math.floor(product?.remainingQuantity / product?.conversionFactor) || 0}</td>
                                                        <td>{product?.totalPrice ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.totalPrice) : 0}</td>
                                                        <td>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <button className="edit-btn"
                                                                    onClick={() => setIndexRow(index)}
                                                                >
                                                                    <FontAwesomeIcon icon={faPenToSquare} />
                                                                </button>
                                                                <button className="delete-btn"
                                                                    onClick={() => handleDeleteProduct(index)}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ display: 'flex', marginRight: '100px', marginLeft: '20px', alignItems: 'start' }}>
                            <div style={{ display: 'flex', flex: '1', justifyContent: 'flex-start', marginTop: '20px' }}>
                                <button
                                    className="button button-cancel"
                                    onClick={() => { navigate('/sale') }}
                                >
                                    Hủy hóa đơn
                                </button>
                                <button className="button button-pay" onClick={handleClick}>
                                    Thanh toán
                                </button>
                                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                                    <MenuItem onClick={async () => {
                                        setOpen(true)
                                        setPaymentMethod('chuyển khoản');
                                        if (tempOrder) {
                                            handlePayment(tempOrder);
                                        } else {
                                            handleSubmit('Chuyển khoản');
                                        }
                                        handleClose();
                                    }}>Chuyển khoản</MenuItem>
                                    <MenuItem onClick={() => {
                                        setOpen(true)
                                        setPaymentMethod('Tiền mặt');
                                        handleClose();
                                    }}>Tiền mặt</MenuItem>
                                </Menu>
                                <Drawer open={open1} onClose={toggleDrawer(false)} anchor='right'>
                                    <Box
                                        sx={{ width: 400, display: 'flex', height: '100%' }}
                                        role="presentation"
                                    >
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'hidden' }}>
                                            <div style={{ width: '100%' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 10px' }}>
                                                    <div>{today}</div>
                                                    <button
                                                        onClick={toggleDrawer(false)}
                                                        style={{ background: 'none', border: 'none', fontSize: '20px', cursor: 'pointer', color: 'red' }}>
                                                        ✖
                                                    </button>
                                                </div>
                                                <Divider />
                                                <div style={{ width: '100%', padding: '10px 0' }}>
                                                    <div style={{ display: 'flex', padding: '0 20px', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p>Số lượng sản phẩm:</p>
                                                        <p style={{ fontWeight: 'bold' }}>{formData?.products?.length} sản phẩm</p>
                                                    </div>
                                                    <div style={{ display: 'flex', padding: '0 20px', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p>Tổng tiền hàng:</p>
                                                        <p style={{ fontWeight: 'bold' }}>
                                                            {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(finalPrice ? finalPrice : 0)}
                                                        </p>
                                                    </div>
                                                    <div style={{ display: 'flex', padding: '0 20px', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p>Giảm giá:</p>
                                                        <p style={{ fontWeight: 'bold' }}>
                                                            {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(finalDiscount ? finalDiscount : 0)}
                                                        </p>
                                                    </div>
                                                    <div style={{ display: 'flex', padding: '0 20px', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p>Khách cần trả:</p>
                                                        <p style={{ fontWeight: 'bold' }}>
                                                            {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(payPrice ? payPrice : 0)}
                                                        </p>
                                                    </div>
                                                    <div id='payOS' style={{ background: 'white', marginTop: '20px', width: '100%', height: '350px' }}>
                                                    </div>
                                                </div>
                                            </div>
                                            {paymentMethod === 'Tiền mặt' && (
                                                <button onClick={() => handleSubmit("Tiền mặt")} className='pay-button'>
                                                    Thanh toán
                                                </button>
                                            )}
                                        </div>
                                    </Box>
                                </Drawer>
                                <Modal
                                    open={isOpenAdd}
                                    onClose={handleCloseAdd}
                                    aria-labelledby='modal-modal-title'
                                    aria-describedby='modal-modal-description'
                                >
                                    <Box>
                                        <AddCustomer onCancel={handleCloseAdd} setSelectedCustomer={handleSetSelectedCustomer} />
                                    </Box>
                                </Modal>
                            </div>
                            <div style={{ flex: '1' }}>
                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                    <div>
                                        <div className='importText' style={{ padding: '5px 0' }}>
                                            <span><strong>Tổng tiền hàng:</strong></span>
                                        </div>
                                        <div className='importText' style={{ padding: '5px 0' }}>
                                            <span><strong>Giảm giá:</strong></span>
                                        </div>
                                        <div className='importText' style={{ padding: '5px 0' }}>
                                            <span><strong>Tổng tiền phải trả:</strong></span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='importText' style={{ padding: '5px 0' }}>
                                            <span style={{ fontSize: '14px' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(finalPrice ? finalPrice : 0)}</span>
                                        </div>
                                        <div className='importText' style={{ padding: '5px 0' }}>
                                            <span style={{ fontSize: '14px' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(finalDiscount ? finalDiscount : 0)}</span>
                                        </div>
                                        <div className='importText' style={{ padding: '5px 0' }}>
                                            <span style={{ fontSize: '14px' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(payPrice ? payPrice : 0)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ flex: '1', backgroundColor: 'white', margin: '0 0 20px 5px', textAlign: 'start', padding: '15px 25px', borderRadius: '10px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)' }}>
                    <div style={{ display: 'flex', paddingBottom: '10px' }}>
                        <div className="search-container" style={{ textAlign: 'start', width: '250px' }}>
                            <input
                                name="productName"
                                type="text"
                                value={productName}
                                placeholder="Nhập tên sản phẩm..."
                                onChange={(e) => setProductName(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setCurrentPage(1);
                                        getProducts(1, productName);
                                    }
                                }}
                                autocomplete="off"
                            />
                            <FontAwesomeIcon icon={faSearch} className="search-icon" onClick={() => {
                                setCurrentPage(1);
                                getProducts(1, productName)
                            }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'grid', height: '430px', gridTemplateColumns: 'repeat(2, 1fr)', alignItems: 'start' }}>
                        {products.size > 0 ? products.listData.map((product, index) => (
                            <button className="receipt-button" onClick={() => addProductToReceipt(product)}>
                                <div className='importText'>
                                    <img
                                        style={{ width: '40px', height: '40px', marginRight: '10px' }}
                                        src={product.imageProduct || 'https://via.placeholder.com/40'}
                                        alt='N/A'
                                        className='product-image'
                                    />
                                </div>
                                <div className='receiptContent'>
                                    <span className='text-clamp'>{product.productName} - {product.manufacturer}</span>
                                    <div style={{ fontSize: '12px' }}>{product.prescriptionDrug ? 'Thuốc kê đơn' : 'Thuốc không kê đơn'}</div>
                                    <div>
                                        <span>
                                            {product.productUnitList
                                                .filter((unit) => unit.retailPrice !== 0 && unit.conversionFactor !== 0)
                                                .sort((a, b) => b.conversionFactor - a.conversionFactor)[0] ? (
                                                <strong>
                                                    {new Intl.NumberFormat('vi-VN', {
                                                        style: 'decimal',
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    }).format(
                                                        product.productUnitList
                                                            .filter((unit) => unit.retailPrice !== 0 && unit.conversionFactor !== 0)
                                                            .sort((a, b) => b.conversionFactor - a.conversionFactor)[0].retailPrice
                                                    )}
                                                </strong>
                                            ) : (
                                                <strong>N/A</strong>
                                            )}
                                        </span>
                                    </div>
                                    <div className="tooltip">
                                        {product.productName} <br /> {product.manufacturer}
                                    </div>
                                </div>
                            </button>
                        )) : (
                            <div style={{ height: '50px', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                Sản phẩm không tồn tại
                            </div>
                        )}
                    </div>
                    {totalPage > 1 && (
                        <div className="pagination">
                            {currentPage === 1 ? (
                                <button disabled>&laquo;</button>
                            ) : (
                                <button onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</button>
                            )}
                            {startPage > 1 && (
                                <>
                                    <button onClick={() => setCurrentPage(1)}>1</button>
                                    {startPage > 2 && (
                                        <button disabled>...</button>
                                    )}
                                </>
                            )}
                            {visiblePages.map((page) => (
                                <button onClick={() => setCurrentPage(page)}
                                    key={page}
                                    className={currentPage === page ? 'active' : ''}
                                >
                                    {page}
                                </button>
                            ))}
                            {endPage < totalPage && (
                                <>
                                    {endPage < totalPage - 1 && (
                                        <button disabled>...</button>
                                    )}
                                    <button onClick={() => setCurrentPage(totalPage)}>{totalPage}</button>
                                </>
                            )}
                            {currentPage === totalPage ? (
                                <button disabled>&raquo;</button>
                            ) : (
                                <button onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</button>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
            {bill && (
                <div style={{ position: 'fixed', top: '0', width: '100%', height: '100vh', zIndex: '1000', display: 'flex', justifyContent: 'center', alignItems: 'start', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <iframe
                        src={`${bill}#zoom=100`}
                        title="PDF Preview"
                        style={{ width: "70%", height: "100%", border: "none" }}
                    ></iframe>
                    <button style={{ cursor: 'pointer' }} onClick={() => setBill(null)}>X</button>
                </div>
            )}
        </div>
    );
};

export default SaleReceiptLayout;