import { useEffect, useState } from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import Navbar2 from "../layouts/Navbar2";
import "react-toastify/dist/ReactToastify.css";

export default function DefaultTemplate({ children }) {
    const [navbarVisible, setNavbarVisible] = useState(false);

    useEffect(() => {
        const updateNavbarVisibility = () => {
            const shouldShowNavbar = window.innerWidth >= 1100;
            setNavbarVisible(shouldShowNavbar);
        };

        updateNavbarVisibility();
        window.addEventListener('resize', updateNavbarVisibility);
    }, []);

    return (
        <div>
            {navbarVisible ? (
                <Navbar />
            ) : (
                <Navbar2 />
            )}
            <div>{children}</div>
            <Footer />
        </div>
    );
}