import React, { useCallback, useEffect, useState } from 'react';
import axiosConfig from '../../api/axiosConfig';
import { Button, TextField, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const PopupAddProduct = ({ onPopupClose }) => {
  const [formData, setFormData] = useState({
    productDTORequest: {
      productName: '',
      activeIngredient: '',
      dosageConcentration: '',
      packingMethod: '',
      manufacturer: '',
      countryOfOrigin: '',
      indication: '',
      contraindication: '',
      sideEffect: '',
      dosageForms: '',
      description: '',
      categoryId: '',
      registrationNumber: '',
      prescriptionDrug: false,
      numberWarning: 0,
      productUnitListDTO: [
        {
          unitId: 1,
          conversionFactor: '1',
          displayPrice: 0,
          displayImportPrice: 0,
          importPrice: 0,
          retailPrice: 0,
        },
        {
          unitId: 2,
          conversionFactor: 0,
          displayImportPrice: 0,
          displayPrice: 0,
          importPrice: 0,
          retailPrice: 0,
        },
        {
          unitId: 3,
          conversionFactor: 0,
          displayImportPrice: 0,
          displayPrice: 0,
          importPrice: 0,
          retailPrice: 0,
        },
      ],
    },
    file: null,
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [unitOptions, setUnitOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const getData = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const [response1, response2] = await Promise.all([
        axiosConfig.get("api/unit/get-all-unit-list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        axiosConfig.get("api/category/get-all-category-list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ]);
      return { response1, response2 };
    } catch (error) {
      throw new Error('Lỗi khi lấy dữ liệu: ' + error.message);
    }
  }

  const fetchData = useCallback(async () => {
    try {
      const { response1, response2 } = await getData();
      setUnitOptions(response1.data);
      setCategories(response2.data);
      console.log(response1.data);
    } catch (error) {
      console.error("Error fetching levels:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      productDTORequest: {
        ...formData.productDTORequest,
        [name]: value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDownloading(true);
    const token = sessionStorage.getItem('token');
    if (!token) {
      toast.error("Thiếu mã thông báo xác thực.");
      return;
    }
    if (formData.file && formData.file.size > 2 * 1024 * 1024) {
      toast.error("Kích thước file phải bé hơn 2MB");
      return;
    }
    try {
      const submissionData = new FormData();
      submissionData.append(
        'productDTORequest',
        new Blob([JSON.stringify(formData.productDTORequest)], { type: 'application/json' })
      );
      if (formData.file) {
        submissionData.append('file', formData.file);
      }
      const response = await axiosConfig.post('/api/product/create-product', submissionData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Tạo thành công");
      onPopupClose(response.data);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Không thể tạo sản phẩm, vui lòng thử lại.';
      toast.error(errorMessage);
    } finally {
      setDownloading(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        file: file,
      }));
      setImagePreview(URL.createObjectURL(file));
    } else {
      console.log('Không có file');
    }
  };

  const formatCurrency = (value) => {
    return value.replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatCurrency2 = (value) => {
    return Number(value.replace(/,/g, ''));
  };

  const handlePriceChange = (index, e) => {
    const { name, value } = e.target;
    const formattedValue = formatCurrency(value);
    const formattedValue2 = formatCurrency2(value);
    const updatedUnits = [...formData.productDTORequest.productUnitListDTO];
    updatedUnits[index][name] = formattedValue;
    if (name === 'displayPrice') {
      updatedUnits[index]['retailPrice'] = formattedValue2;
    } else if (name === 'displayImportPrice') {
      updatedUnits[index]['importPrice'] = formattedValue2;
    }

    setFormData({
      ...formData,
      productDTORequest: {
        ...formData.productDTORequest,
        productUnitListDTO: updatedUnits,
      },
    });
  };

  const handleSmallestUnitChange = (e) => {
    const updatedFormData = { ...formData };
    updatedFormData.productDTORequest.productUnitListDTO[0].unitId = e.target.value;
    setFormData(updatedFormData);
  };

  const handleUnit1Change = (e) => {
    const updatedFormData = { ...formData };
    updatedFormData.productDTORequest.productUnitListDTO[1].unitId = e.target.value;
    setFormData(updatedFormData);
  };

  const handleUnit2Change = (e) => {
    const updatedFormData = { ...formData };
    updatedFormData.productDTORequest.productUnitListDTO[2].unitId = e.target.value;
    setFormData(updatedFormData);
  };

  const handleUnitChange = (index, e) => {
    const { name, value } = e.target;
    const formattedValue = formatCurrency(value);
    const formattedValue2 = formatCurrency2(value);
    const updatedUnits = [...formData.productDTORequest.productUnitListDTO];
    updatedUnits[index][name] = formattedValue;
    const autogeneratePrice = formData.productDTORequest.productUnitListDTO[0].retailPrice * formattedValue2;
    updatedUnits[index]['displayPrice'] = formatCurrency(autogeneratePrice.toString());
    updatedUnits[index]['retailPrice'] = autogeneratePrice;
    setFormData({
      ...formData,
      productDTORequest: {
        ...formData.productDTORequest,
        productUnitListDTO: updatedUnits,
      },
    });
  };
  return (
    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      textAlign: 'start',
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{ width: '65%', maxHeight: '80vh', overflowY: 'auto', backgroundColor: 'white', textAlign: 'start', padding: '15px 25px', borderRadius: '10px' }}>
        <form onSubmit={handleSubmit} >
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}></div>
            <div style={{ flex: 1 }}>
              <h2 style={{ textAlign: 'center', margin: '10px 0' }}>Thêm Sản Phẩm</h2>
            </div>
            <div style={{ flex: 1, textAlign: 'end' }}>
              <FontAwesomeIcon onClick={() => onPopupClose(null)} style={{ fontSize: '30px', cursor: 'pointer' }} icon={faXmark} />
            </div>
          </div>
          <div className='product-form'>
            <div className='form-column'>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Tên sản phẩm</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='productName'
                    value={formData.productDTORequest.productName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Nhóm sản phẩm</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <select style={{ width: '97.5%' }}
                    name='categoryId'
                    value={formData.productDTORequest.categoryId}
                    onChange={handleInputChange}
                  >
                    <option value=''>Chọn nhóm sản phẩm</option>
                    {categories && categories.map((category) => (
                      <option value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Số đăng ký</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='registrationNumber'
                    value={formData.productDTORequest.registrationNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Thành phần hoạt tính</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='activeIngredient'
                    value={formData.productDTORequest.activeIngredient}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Dạng bào chế</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='dosageForms'
                    value={formData.productDTORequest.dosageForms}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Nồng độ - Hàm lượng</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='dosageConcentration'
                    value={formData.productDTORequest.dosageConcentration}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Chỉ định</strong></span>
                </div>
                <div style={{ flex: '3' }}>
                  <TextField
                    name="indication"
                    type="text"
                    value={formData.productDTORequest.indication}
                    onChange={handleInputChange}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={4}
                    InputProps={{ style: { padding: '5px', margin: '5px 0', width: '97.5%' } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "inherit",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span style={{ whiteSpace: 'nowrap', transform: 'translateY(20px)' }}>
                    <strong>Chống chỉ định</strong>
                  </span>
                </div>
                <div style={{ flex: '3' }}>
                  <TextField
                    name="contraindication"
                    type="text"
                    value={formData.productDTORequest.contraindication}
                    onChange={handleInputChange}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={4}
                    InputProps={{ style: { padding: '5px', margin: '5px 0', width: '97.5%' } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "inherit",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Số lượng cảnh báo</strong></span>
                </div>
                <div style={{ flex: '3' }}>
                  <TextField
                    name="numberWarning"
                    type="number"
                    value={formData.productDTORequest.numberWarning}
                    onChange={handleInputChange}
                    inputProps={{
                      onKeyPress: (e) => {
                        if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                          e.preventDefault();
                        }
                      },
                      min: 0,
                    }}
                    variant="outlined"
                    sx={{
                      width: '100px',
                      "& .MuiInputBase-input": {
                        fontSize: '14px',
                        padding: '6px',
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "inherit",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='form-column' style={{ marginRight: '20px' }}>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Thêm ảnh sản phẩm</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={handleImageUpload}
                  />
                </div>
              </div>
              {imagePreview && (
                <div style={{ display: 'flex' }}>
                  <div className='fieldText' style={{ flex: '2' }}>
                    <span><strong>Xem trước ảnh</strong></span>
                  </div>
                  <div className='fieldInput' style={{ flex: '3' }}>
                    <img
                      src={imagePreview}
                      alt='Preview'
                      style={{ width: '150px', height: '150px' }}
                    />
                  </div>
                </div>
              )}
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Quy cách đóng gói</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='packingMethod'
                    value={formData.productDTORequest.packingMethod}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Thuốc kê theo đơn</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <select style={{ width: '97.5%' }}
                    name='prescriptionDrug'
                    value={formData.productDTORequest.prescriptionDrug}
                    onChange={handleInputChange}
                  >
                    <option value={true}>Có</option>
                    <option value={false}>Không</option>
                  </select>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Hãng sản xuất</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='manufacturer'
                    value={formData.productDTORequest.manufacturer}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Nước sản xuất</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='countryOfOrigin'
                    value={formData.productDTORequest.countryOfOrigin}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Mô tả</strong></span>
                </div>
                <div style={{ flex: '3' }}>
                  <TextField
                    name="description"
                    type="text"
                    value={formData.productDTORequest.description}
                    onChange={handleInputChange}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={4}
                    InputProps={{ style: { padding: '5px', margin: '5px 0', width: '97.5%' } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "inherit",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span style={{ whiteSpace: 'nowrap', transform: 'translateY(20px)' }}>
                    <strong>Tác dụng phụ</strong>
                  </span>
                </div>
                <div style={{ flex: '3' }}>
                  <TextField
                    name="sideEffect"
                    type="text"
                    value={formData.productDTORequest.sideEffect}
                    onChange={handleInputChange}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={4}
                    InputProps={{ style: { padding: '5px', margin: '5px 0', width: '97.5%' } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "inherit",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ flex: '1.3', display: 'flex', justifyContent: 'start' }}>
                <div className='fieldText' style={{ flex: '1.7' }}>
                  <span><strong>Đơn vị nhỏ nhất</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '1' }}>
                  <select style={{ width: '80px' }}
                    name='unitId'
                    value={formData.productDTORequest.productUnitListDTO[0]?.unitId}
                    onChange={(e) => handleSmallestUnitChange(e)}
                  >
                    {unitOptions && unitOptions
                      .filter(unit =>
                        unit.id !== formData.productDTORequest.productUnitListDTO[1]?.unitId &&
                        unit.id !== formData.productDTORequest.productUnitListDTO[2]?.unitId
                      ).map((unit, index) => (
                        <option key={index} value={unit.id}>{unit.unitName}</option>
                      ))}
                  </select>
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Hệ quy đổi</strong></span>
                </div>
                <div className='fieldInput'>
                  <input style={{ width: '50px' }}
                    type='number'
                    name='conversionFactor'
                    value={1}
                    readOnly
                  />
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Giá bán</strong></span>
                </div>
                <div className='fieldInput'>
                  <input style={{ width: '60px' }}
                    type='text'
                    name='displayPrice'
                    value={formData.productDTORequest.productUnitListDTO[0]?.displayPrice}
                    onChange={(e) => handlePriceChange(0, e)}
                  />
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Giá nhập</strong></span>
                </div>
                <div className='fieldInput'>
                  <input style={{ width: '60px' }}
                    type='text'
                    name='displayImportPrice'
                    value={formData.productDTORequest.productUnitListDTO[0]?.displayImportPrice}
                    onChange={(e) => handlePriceChange(0, e)}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ flex: '1.3', display: 'flex', justifyContent: 'start' }}>
                <div className='fieldText' style={{ flex: '1.7' }}>
                  <span><strong>Đơn vị thứ hai</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '1' }}>
                  <select style={{ width: '80px' }}
                    name='unitId'
                    value={formData.productDTORequest.productUnitListDTO[1]?.unitId}
                    onChange={(e) => handleUnit1Change(e)}
                  >
                    {unitOptions && unitOptions
                      .filter(unit =>
                        unit.id !== formData.productDTORequest.productUnitListDTO[0]?.unitId &&
                        unit.id !== formData.productDTORequest.productUnitListDTO[2]?.unitId
                      ).map((unit, index) => (
                        <option key={index} value={unit.id}>{unit.unitName}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Hệ quy đổi</strong></span>
                </div>
                <div className='fieldInput'>
                  <input style={{ width: '50px' }}
                    type='number'
                    name='conversionFactor'
                    value={formData.productDTORequest.productUnitListDTO[1]?.conversionFactor}
                    onChange={(e) => handleUnitChange(1, e)}
                  />
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Giá bán</strong></span>
                </div>
                <div className='fieldInput'>
                  <input style={{ width: '60px' }}
                    type='text'
                    name='displayPrice'
                    value={formData.productDTORequest.productUnitListDTO[1]?.displayPrice}
                    onChange={(e) => handlePriceChange(1, e)}
                  />
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Giá nhập</strong></span>
                </div>
                <div className='fieldInput'>
                  <input style={{ width: '60px' }}
                    type='text'
                    name='displayImportPrice'
                    value={formData.productDTORequest.productUnitListDTO[1]?.displayImportPrice}
                    onChange={(e) => handlePriceChange(1, e)}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ flex: '1.3', display: 'flex', justifyContent: 'start' }}>
                <div className='fieldText' style={{ flex: '1.7' }}>
                  <span><strong>Đơn vị thứ ba</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '1' }}>
                  <select style={{ width: '80px' }}
                    name='unitId'
                    value={formData.productDTORequest.productUnitListDTO[2]?.unitId}
                    onChange={(e) => handleUnit2Change(e)}
                  >
                    {unitOptions && unitOptions
                      .filter(unit =>
                        unit.id !== formData.productDTORequest.productUnitListDTO[0]?.unitId &&
                        unit.id !== formData.productDTORequest.productUnitListDTO[1]?.unitId
                      ).map((unit, index) => (
                        <option key={index} value={unit.id}>{unit.unitName}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Hệ quy đổi</strong></span>
                </div>
                <div className='fieldInput'>
                  <input style={{ width: '50px' }}
                    type='number'
                    name='conversionFactor'
                    value={formData.productDTORequest.productUnitListDTO[2]?.conversionFactor}
                    onChange={(e) => handleUnitChange(2, e)}
                  />
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Giá bán</strong></span>
                </div>
                <div className='fieldInput'>
                  <input style={{ width: '60px' }}
                    type='text'
                    name='displayPrice'
                    value={formData.productDTORequest.productUnitListDTO[2]?.displayPrice}
                    onChange={(e) => handlePriceChange(2, e)}
                  />
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Giá nhập</strong></span>
                </div>
                <div className='fieldInput'>
                  <input style={{ width: '60px' }}
                    type='text'
                    name='displayImportPrice'
                    value={formData.productDTORequest.productUnitListDTO[2]?.displayImportPrice}
                    onChange={(e) => handlePriceChange(2, e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div style={{ margin: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained" className='btn' onClick={handleSubmit}>
            Thêm
          </Button>
          <Button variant="contained" className='btn' onClick={() => onPopupClose(null)}>
            Hủy
          </Button>
        </div>
      </div>
      {downloading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 99999,
          }}
        >
          <CircularProgress size="5rem" />
        </div>
      )}
    </div>
  );
};

export default PopupAddProduct;