import React, { useEffect, useState } from "react";
import "flatpickr/dist/themes/material_blue.css";
import '../styles/Report.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar, faCashRegister, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress, Card, CardContent, Typography, Grid, Button, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import api from '../api/axiosConfig';
import { FiDownload } from 'react-icons/fi';

const ReportIncome = ({ dateRange }) => {
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [incomeReport, setIncomeReport] = useState(null);
    const [incomeReportList, setIncomeReportList] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [category, setCategory] = useState('');
    const [receiptType, setReceiptType] = useState('');
    const [downloading, setDownloading] = useState(false);

    const convertToLocalDate = (date) => {
        if (date) {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);

            return newDate.toISOString().split('T')[0];
        }
        return null;
    };

    const getIncomeReportList = async (page) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/reports/financial-transactions?pageNumber=${page - 1}&pageSize=10`;
            if (dateRange && dateRange[0] && dateRange[1]) {
                url += `&startDate=${convertToLocalDate(dateRange[0])}&endDate=${convertToLocalDate(dateRange[1])}`;
            }
            if (receiptType && receiptType !== '') {
                url += `&receiptType=${receiptType}`;
            }
            if (paymentMethod && paymentMethod !== '') {
                url += `&paymentMethod=${paymentMethod}`;
            }
            if (category && category !== '') {
                url += `&category=${category}`;
            }
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setIncomeReportList(response.data);
            setTotalPage(response.data.totalPages);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const getIncomeReport = async () => {
        const token = sessionStorage.getItem('token');
        let url = '/api/reports/financial'
        try {
            if (dateRange && dateRange[0] && dateRange[1]) {
                url += `?startDate=${convertToLocalDate(dateRange[0])}&endDate=${convertToLocalDate(dateRange[1])}`;
            }
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setIncomeReport(response.data);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const getExportExcel = async () => {
        if (!dateRange) {
            toast.error('Vui lòng chọn thời gian');
            return;
        }
        setDownloading(true);
        const token = sessionStorage.getItem('token');
        try {
            if (dateRange && dateRange[0] && dateRange[1]) {
                let url = `/api/reports/export-excel-financial-report?fromDate=${convertToLocalDate(dateRange[0])}&toDate=${convertToLocalDate(dateRange[1])}`;
                const response = await api.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                });
                const blobUrl = window.URL.createObjectURL(response.data);
                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = 'Bao_cao_thu_chi.xlsx';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            console.error(error);
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        } finally {
            setDownloading(false);
        }
    };

    useEffect(() => {
        getIncomeReportList(currentPage);
        getIncomeReport();
    }, [currentPage, dateRange, paymentMethod, receiptType, category])

    useEffect(() => {
        setCategory('');
    }, [receiptType])

    const getVisiblePages = () => {
        const maxButtons = 3;
        const half = Math.floor(maxButtons / 2);
        let startPage, endPage;

        if (totalPage <= maxButtons) {
            startPage = 1;
            endPage = totalPage;
        } else {
            if (currentPage <= half) {
                startPage = 1;
                endPage = maxButtons;
            } else if (currentPage + half >= totalPage) {
                startPage = totalPage - maxButtons + 1;
                endPage = totalPage;
            } else {
                startPage = currentPage - half;
                endPage = currentPage + half;
            }
        }
        return { startPage, endPage };
    }

    const renderCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
    };

    const { startPage, endPage } = getVisiblePages();
    const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    const data = [
        { icon: faCashRegister, value: `${renderCurrency(incomeReport?.totalIncome || 0)}`, label: "Tổng thu", color: "#a17cf8" },
        { icon: faMoneyBillTransfer, value: `${renderCurrency(incomeReport?.totalExpense || 0)}`, label: "Tổng chi", color: "#56ccf2" },
        { icon: faHandHoldingDollar, value: `${renderCurrency(incomeReport?.profit || 0)}`, label: "Lợi nhuận", color: "#f2994a" },
    ];

    const renderDate = (value) => {
        const date = new Date(Math.floor(value) * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div className="report-container">
            <div className="btnSearch" style={{ marginBottom: '0' }}>
                <select className="custom-select" name="orderType" onChange={(e) => setReceiptType(e.target.value)} style={{ width: '120px' }}>
                    <option value="" selected>Tất cả</option>
                    <option value="Phiếu thu">Thu</option>
                    <option value="Phiếu chi">Chi</option>
                </select>
                {receiptType && (
                    receiptType === 'Phiếu thu' ? (
                        <select className="custom-select" name="category" value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="" selected>Danh mục</option>
                            <option value="Bán hàng">Bán hàng</option>
                            <option value="Trả lại nhà cung cấp">Trả lại nhà cung cấp</option>
                        </select>
                    ) : (
                        <select className="custom-select" name="category" value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="" selected>Danh mục</option>
                            <option value="Nhập hàng">Nhập hàng</option>
                            <option value="Khách hàng trả lại">Khách hàng trả lại</option>
                        </select>
                    )
                )}
                {(!receiptType || (category === 'Bán hàng' || category === 'Nhập hàng')) && (
                    <select className="custom-select" name="paymentMethod" onChange={(e) => setPaymentMethod(e.target.value)}>
                        <option value="" selected>Phương thức thanh toán</option>
                        <option value="CASH">Tiền mặt</option>
                        <option value="TRANSFER">Chuyển khoản</option>
                    </select>
                )}
                <Button className="export-btn" style={{ backgroundColor: "#09446d", color: '#fff' }} onClick={getExportExcel}>
                    Xuất file
                    <FiDownload />
                </Button>
            </div>
            <Grid container columnSpacing={6} style={{ padding: '20px 0' }}>
                {data.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card style={{ borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                            <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '16px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ backgroundColor: `${item.color}33`, borderRadius: '50%', padding: '12px', marginRight: '16px', width: '30px', height: '30px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        <FontAwesomeIcon icon={item.icon} style={{ color: item.color, fontSize: '24px' }} />
                                    </div>
                                    <div>
                                        <Typography variant="h5" style={{ fontWeight: 600 }}>
                                            {item.value}
                                        </Typography>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {item.label}
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <div style={{ overflowX: 'auto' }}>
                <table className="list">
                    <thead>
                        <tr>
                            <th>Mã phiếu</th>
                            <th>Loại phiếu</th>
                            <th>Ngày</th>
                            <th>Danh mục</th>
                            <th>Phương thức thanh toán</th>
                            <th>Tổng tiền</th>
                        </tr>
                    </thead>
                    <tbody>
                        {incomeReportList?.content ? (
                            incomeReportList?.content.length > 0 ? (
                                incomeReportList.content.map((income, index) => (
                                    <tr key={index}>
                                        <td>{income?.invoiceNumber}</td>
                                        <td>{income?.receiptType}</td>
                                        <td>{renderDate(income?.creationDate)}</td>
                                        <td>{income?.category}</td>
                                        <td>
                                            {income?.paymentMethod === 'CASH' && "Tiền mặt"}
                                            {income?.paymentMethod === 'TRANSFER' && "Chuyển khoản"}
                                            {income?.paymentMethod !== 'CASH' && income?.paymentMethod !== 'TRANSFER' && "N/A"}
                                        </td>
                                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(income?.totalAmount)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6} style={{ padding: '20px' }}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                            )
                        ) : (
                            <tr>
                                <td colSpan={6} style={{ padding: '20px' }}>
                                    <LinearProgress color="inherit" />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {totalPage > 1 && (
                <div className="pagination">
                    {currentPage === 1 ? (
                        <button disabled>&laquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</button>
                    )}
                    {startPage > 1 && (
                        <>
                            <button onClick={() => setCurrentPage(1)}>1</button>
                            {startPage > 2 && (
                                <button disabled>...</button>
                            )}
                        </>
                    )}
                    {visiblePages.map((page) => (
                        <button onClick={() => setCurrentPage(page)}
                            key={page}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}
                    {endPage < totalPage && (
                        <>
                            {endPage < totalPage - 1 && (
                                <button disabled>...</button>
                            )}
                            <button onClick={() => setCurrentPage(totalPage)}>{totalPage}</button>
                        </>
                    )}
                    {currentPage === totalPage ? (
                        <button disabled>&raquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</button>
                    )}
                </div>
            )}
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
        </div>
    );
};

export default ReportIncome;
