import React, { useState } from 'react';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from 'date-fns';
import { Button } from '@mui/material';
import UserEdit from './UserEdit';

const PopupUserDetail = ({ onPopupClose, user }) => {

    const [activeTab, setActiveTab] = useState('personal');
    const [showEditPopup, setShowEditPopup] = useState(false);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const formattedCreateDate = user && user.createDate
        ? format(new Date(user.createDate * 1000), 'dd/MM/yyyy')
        : '../../....';

    const formattedModifyDate = user && user.lastModifiedDate
        ? format(new Date(user.lastModifiedDate * 1000), 'dd/MM/yyyy')
        : '../../....';

    const handleEditClick = () => {
        setShowEditPopup(true);
    };

    const handleEditCancel = () => {
        setShowEditPopup(false);
        onPopupClose();
    };

    if (showEditPopup) {
        return <UserEdit user={user} onPopupClose={handleEditCancel} />;
    }

    return (
        <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            textAlign: 'start',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div className="information-container">
                <h2>Chi Tiết Nhân Viên {user.fullName}</h2>
                <div className="tabs">
                    <button
                        className={`tab ${activeTab === 'personal' ? 'active' : ''}`}
                        onClick={() => handleTabChange('personal')}
                    >
                        Thông tin nhân viên
                    </button>
                    <button
                        className={`tab ${activeTab === 'dashboard' ? 'active' : ''}`}
                        onClick={() => handleTabChange('dashboard')}
                    >
                        Thông tin đăng nhập
                    </button>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'start', marginTop: '60px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {user && user.avatar ? (
                                <>
                                    <div style={{
                                        display: "inline-block", width: "150px", height: "150px", backgroundImage: `url(${user.avatar})`,
                                        backgroundSize: 'cover', backgroundPosition: 'center', border: '2px solid #709dc1', borderRadius: '50%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                                    }}>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{ width: "150px", height: "150px", border: 'solid 2px #709dc1', borderRadius: '50%', backgroundColor: "transparent", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <FontAwesomeIcon icon={faUser} size="7x" style={{ color: "#709dc1" }} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="tabs-container" style={{ flex: '2.5' }}>
                        <div>
                            {activeTab === 'personal' && (
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <div style={{ flex: '1' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '2' }}>
                                                <span><strong>Tên nhân viên:</strong></span>
                                            </div>
                                            <div className='fieldContent' style={{ flex: '3' }}>
                                                <span>{user.fullName}</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '2' }}>
                                                <span><strong>Giới tính:</strong></span>
                                            </div>
                                            <div className='fieldContent' style={{ flex: '3' }}>
                                                <span>{user.gender === 'MALE' ? 'Nam' : 'Nữ'}</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '2' }}>
                                                <span><strong>Ngày sinh:</strong></span>
                                            </div>
                                            <div className='fieldContent' style={{ flex: '3' }}>
                                                <span>
                                                    {user.dob && format(new Date(user.dob * 1000), 'dd/MM/yyyy')}
                                                </span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '2' }}>
                                                <span><strong>Số điện thoại:</strong></span>
                                            </div>
                                            <div className='fieldContent' style={{ flex: '3' }}>
                                                <span>{user.phone}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ flex: '1' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '2' }}>
                                                <span><strong>CCCD:</strong></span>
                                            </div>
                                            <div className='fieldContent' style={{ flex: '3' }}>
                                                <span>{user.cic}</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '2' }}>
                                                <span><strong>Email:</strong></span>
                                            </div>
                                            <div className='fieldContent' style={{ flex: '3' }}>
                                                <span>{user.email}</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '2' }}>
                                                <span><strong>Địa chỉ:</strong></span>
                                            </div>
                                            <div className='fieldContent' style={{ flex: '3' }}>
                                                <span>{user.address}</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '2' }}>
                                                <span><strong>Ghi chú:</strong></span>
                                            </div>
                                            <div className='fieldContent' style={{ flex: '3' }}>
                                                <span>{user.note || 'N/A'}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )}
                            {activeTab === 'dashboard' && (
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <div style={{ flex: '1.5' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><strong>Tên đăng nhập:</strong></span>
                                            </div>
                                            <div className='fieldContent' style={{ flex: '2' }}>
                                                <span>{user.username || 'N/A'}</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><strong>Vai trò:</strong></span>
                                            </div>
                                            <div className='fieldContent' style={{ flex: '2' }}>
                                                {user?.rolesDto && user.rolesDto.length > 0 ? (
                                                    user.rolesDto.map((r, index) => (
                                                        <span key={index}>
                                                            {index >= 1 && ', '}
                                                            {r.name === 'ROLE_PRODUCT_OWNER' ? 'Chủ cửa hàng' :
                                                                r.name === 'ROLE_STOCK' ? 'Nhân viên kho' :
                                                                    r.name === 'ROLE_SALE' ? 'Nhân viên bán hàng' : ''}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span>N/A</span>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><strong>Trạng thái:</strong></span>
                                            </div>
                                            <div className='fieldContent' style={{ flex: '2' }}>
                                                <span>{user.status === 'ACTIVE' ? 'Hoạt động' : 'Ngưng hoạt động'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ flex: '1' }}>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <p><strong>Tạo ngày {formattedCreateDate}, chỉnh sửa lần cuối ngày {formattedModifyDate} bởi {user && user.lastModifiedBy ? (<>{user.lastModifiedBy}</>) : (<>....</>)}</strong></p>
                        </div>
                        <div style={{ margin: '20px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button variant="contained" className='btn' onClick={handleEditClick}>
                                Sửa
                            </Button>
                            <Button variant="contained" className='btn' onClick={onPopupClose}>
                                Quay lại
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopupUserDetail; 