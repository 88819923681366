import React, { useEffect, useState, useCallback } from 'react';
import axiosConfig from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextField, CircularProgress } from '@mui/material';

const PopupEditProduct = ({ onPopupClose, product }) => {
  const [productInfo, setProductInfo] = useState(product);
  const [unitOptions, setUnitOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [formData, setFormData] = useState({
    productDTORequest: {
      ...product,
      productUnitList: product.productUnitList.map((unit) => ({
        ...unit,
        unitId: unit.id,
      })),
    },
  });

  const [imagePreview, setImagePreview] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductInfo({ ...productInfo, [name]: value });
    setFormData({
      ...formData,
      productDTORequest: {
        ...formData.productDTORequest,
        [name]: value,
      },
    });
  };

  const getData = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const [response1, response2] = await Promise.all([
        axiosConfig.get("api/unit/get-all-unit-list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        axiosConfig.get("api/category/get-all-category-list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ]);
      console.log('popup');
      console.log(formData);
      console.log(response2);
      return { response1, response2 };
    } catch (error) {
      throw new Error('Lỗi khi lấy dữ liệu: ' + error.message);
    }
  }

  const fetchData = useCallback(async () => {
    try {
      const { response1, response2 } = await getData();
      setUnitOptions(response1.data);
      console.log("options:");
      console.log(response1.data);
      setCategories(response2.data);
    } catch (error) {
      console.error("Error fetching levels:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDownloading(true);
    const token = sessionStorage.getItem('token');
    if (!token) {
      toast.error("Thiếu mã thông báo xác thực.");
      return;
    }
    if (formData.file && formData.file.size > 2 * 1024 * 1024) {
      toast.error("Kích thước file phải bé hơn 2MB");
      return;
    }
    try {
      const updatedUnits = [...formData.productDTORequest.productUnitList];
      const updatedUnitsWithId = updatedUnits.map((unit) => ({
        ...unit,
        unitId: unit.unit.id,
      }));
      const modifiedFormData = {
        ...formData,
        productDTORequest: {
          ...formData.productDTORequest,
          productUnitListDTO: updatedUnitsWithId,
          unit: 153,
        },
      };
      delete modifiedFormData.productDTORequest.productUnitList;
      const submissionData = new FormData();
      submissionData.append(
        'productDTORequest',
        new Blob([JSON.stringify(modifiedFormData.productDTORequest)], { type: 'application/json' })
      );
      if (modifiedFormData.file) {
        submissionData.append('file', modifiedFormData.file);
      }
      console.log("Submit Data:");
      console.log(modifiedFormData);
      const { data } = await axiosConfig.put('/api/product/update-product', submissionData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(data);
      setTimeout(() => {
        onPopupClose();
      }, 1000);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Không thể cập nhật sản phẩm, vui lòng thử lại.';
      toast.error(errorMessage);
    } finally {
      setDownloading(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        file: file,
      }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleProductUnitChange = (e, index) => {
    const { name, value } = e.target;
    if (value.includes('-')) {
      return;
    }
    const updatedUnits = [...formData.productDTORequest.productUnitList];
    if (name === 'unitId') {
      updatedUnits[index] = {
        ...updatedUnits[index],
        unit: {
          id: value
        }
      };
    } else {
      updatedUnits[index] = {
        ...updatedUnits[index],
        [name]: value,
      };
    }
    const updatedUnitsWithId = updatedUnits.map((unit) => ({
      ...unit,
      unitId: unit.unit.id,
    }));
    console.log(updatedUnitsWithId);
    setFormData({
      ...formData,
      productDTORequest: {
        ...formData.productDTORequest,
        productUnitList: updatedUnitsWithId,
      },
    });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      textAlign: 'start',
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{ width: '65%', maxHeight: '80vh', overflowY: 'auto', backgroundColor: 'white', textAlign: 'start', padding: '15px 25px', borderRadius: '10px' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 3 }}>
            <h2 style={{ textAlign: 'center' }}>Chỉnh Sửa Sản Phẩm {product?.productName}</h2>
          </div>
          <div style={{ flex: 1, textAlign: 'end' }}>
            <FontAwesomeIcon onClick={onPopupClose} style={{ fontSize: '30px', cursor: 'pointer' }} icon={faXmark} />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='product-form'>
            <div className='form-column'>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Tên sản phẩm</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='productName'
                    value={formData.productDTORequest.productName || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Mã sản phẩm</strong></span>
                </div>
                <div className='fieldContent' style={{ flex: '3' }}>
                  <span>{productInfo?.productCode || ''}</span>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Nhóm sản phẩm</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <select style={{ width: '97.5%' }}
                    name='categoryId'
                    value={formData.productDTORequest.categoryId || ''}
                    onChange={handleInputChange}
                  >
                    {categories && categories.map((category) => (
                      <option value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Số đăng ký</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='registrationNumber'
                    value={formData.productDTORequest.registrationNumber || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Thành phần hoạt tính</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='activeIngredient'
                    value={formData.productDTORequest.activeIngredient || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Dạng bào chế</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='dosageForms'
                    value={formData.productDTORequest.dosageForms || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Nồng độ - Hàm lượng</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='dosageConcentration'
                    value={formData.productDTORequest.dosageConcentration || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Chỉ định</strong></span>
                </div>
                <div style={{ flex: '3' }}>
                  <TextField
                    name="indication"
                    type="text"
                    value={formData.productDTORequest.indication || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={4}
                    InputProps={{ style: { padding: '5px', margin: '5px 0', width: '97.5%' } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "inherit",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span style={{ whiteSpace: 'nowrap', transform: 'translateY(20px)' }}>
                    <strong>Chống chỉ định</strong>
                  </span>
                </div>
                <div style={{ flex: '3' }}>
                  <TextField
                    name="contraindication"
                    type="text"
                    value={formData.productDTORequest.contraindication || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={4}
                    InputProps={{ style: { padding: '5px', margin: '5px 0', width: '97.5%' } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "inherit",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Số lượng cảnh báo</strong></span>
                </div>
                <div style={{ flex: '3' }}>
                  <TextField
                    name="numberWarning"
                    type="number"
                    value={formData.productDTORequest.numberWarning}
                    onChange={handleInputChange}
                    inputProps={{
                      onKeyPress: (e) => {
                        if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                          e.preventDefault();
                        }
                      },
                      min: 0,
                    }}
                    variant="outlined"
                    sx={{
                      width: '100px',
                      "& .MuiInputBase-input": {
                        fontSize: '14px',
                        padding: '6px',
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "inherit",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='form-column' style={{ marginRight: '20px' }}>
              <div>
                <div style={{ display: 'flex' }}>
                  <div className='fieldText' style={{ flex: '2' }}>
                    <span><strong>Thêm ảnh sản phẩm</strong></span>
                  </div>
                  <div className='fieldInput' style={{ flex: '3' }}>
                    <input
                      type='file'
                      accept='image/*'
                      onChange={handleImageUpload}
                    />
                  </div>
                </div>
                {imagePreview ? (
                  <div style={{ display: 'flex' }}>
                    <div className='fieldText' style={{ flex: '2' }}>
                      <span><strong>Xem trước ảnh</strong></span>
                    </div>
                    <div style={{ flex: '3' }}>
                      <img
                        src={imagePreview}
                        alt='Preview'
                        style={{ width: '150px', height: '150px' }}
                      />
                    </div>
                  </div>
                ) : (
                  productInfo?.imageProduct && (
                    <div style={{ display: 'flex' }}>
                      <div className='fieldText' style={{ flex: '2' }}>
                        <span><strong>Xem trước ảnh</strong></span>
                      </div>
                      <div className='fieldInput' style={{ flex: '3' }}>
                        <img
                          src={productInfo.imageProduct}
                          alt='Product'
                          style={{ width: '150px', height: '150px' }}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Quy cách đóng gói</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='packingMethod'
                    value={formData.productDTORequest.packingMethod || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Thuốc kê theo đơn</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <select
                    style={{ width: '97.5%' }}
                    name='prescriptionDrug'
                    value={formData.productDTORequest.prescriptionDrug}
                    onChange={handleInputChange}
                  >
                    <option value={true}>Có</option>
                    <option value={false}>Không</option>
                  </select>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Hãng sản xuất</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='manufacturer'
                    value={formData.productDTORequest.manufacturer || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><span style={{ color: 'red' }}>*</span><strong>Nước sản xuất</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '3' }}>
                  <input
                    type='text'
                    name='countryOfOrigin'
                    value={formData.productDTORequest.countryOfOrigin || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span><strong>Mô tả</strong></span>
                </div>
                <div style={{ flex: '3' }}>
                  <TextField
                    name="description"
                    type="text"
                    value={formData.productDTORequest.description || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={4}
                    InputProps={{ style: { padding: '5px', margin: '5px 0', width: '97.5%' } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "inherit",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='fieldText' style={{ flex: '2' }}>
                  <span style={{ whiteSpace: 'nowrap', transform: 'translateY(20px)' }}>
                    <strong>Tác dụng phụ</strong>
                  </span>
                </div>
                <div style={{ flex: '3' }}>
                  <TextField
                    name="sideEffect"
                    type="text"
                    value={formData.productDTORequest.sideEffect || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={4}
                    InputProps={{ style: { padding: '5px', margin: '5px 0', width: '97.5%' } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "inherit",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {formData.productDTORequest.productUnitList?.map((productUnit, index) => (
            <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ flex: '1.3', display: 'flex', justifyContent: 'start' }}>
                <div className='fieldText' style={{ flex: '1.9' }}>
                  <span><strong>Đơn vị sản phẩm</strong></span>
                </div>
                <div className='fieldInput' style={{ flex: '1' }}>
                  <select style={{ marginLeft: '10px', width: '80px' }}
                    name='unitId'
                    value={productUnit.unit?.id}
                    onChange={(e) => handleProductUnitChange(e, index)}
                  >
                    {unitOptions && unitOptions.map((unit) => (
                      <option value={unit.id}>{unit.unitName}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Hệ quy đổi</strong></span>
                </div>
                <div className='fieldInput'>
                  <input style={{ marginLeft: '10px', width: '50px' }}
                    type='number'
                    name='conversionFactor'
                    min={0}
                    value={productUnit.conversionFactor}
                    onChange={(e) => handleProductUnitChange(e, index)}
                  />
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Giá bán</strong></span>
                </div>
                <div className='fieldInput' >
                  <input style={{ marginLeft: '10px', width: '60px' }}
                    type='number'
                    name='retailPrice'
                    min={0}
                    value={productUnit.retailPrice}
                    onChange={(e) => handleProductUnitChange(e, index)}
                  />
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                <div className='fieldText'>
                  <span><strong>Giá nhập</strong></span>
                </div>
                <div className='fieldInput' >
                  <input style={{ marginLeft: '10px', width: '60px' }}
                    type='number'
                    name='importPrice'
                    min={0}
                    value={productUnit.importPrice}
                    onChange={(e) => handleProductUnitChange(e, index)}
                  />
                </div>
              </div>
            </div>
          ))}
        </form>
        <div style={{ margin: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained" className='btn' onClick={handleSubmit}>
            Lưu
          </Button>
          <Button variant="contained" className='btn' onClick={onPopupClose}>
            Hủy
          </Button>
        </div>
      </div>
      {downloading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 99999,
          }}
        >
          <CircularProgress size="5rem" />
        </div>
      )}
    </div >
  );
};

export default PopupEditProduct;