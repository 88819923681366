import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBDgQvzj5u_M_2KZLlw_odxOjghQ4-UmbM",
    authDomain: "pharmacy-management-e6dd2.firebaseapp.com",
    databaseURL: "https://pharmacy-management-e6dd2-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "pharmacy-management-e6dd2",
    storageBucket: "pharmacy-management-e6dd2.firebasestorage.app",
    messagingSenderId: "815331737002",
    appId: "1:815331737002:web:850ce10eec9ec04bc9ffd3",
    measurementId: "G-8VQJDQFF3C"
};

const vapidKey = "BL7clC2iZRkaku_2CDAi9Po3jCOIcpJSQOP6k9f2u7ojPgzHbsQHX1tdH8ul5wTXz4EOOKFuO7RHIw-V4muD-y8";

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const requestFCMToken = async () => {
    return Notification.requestPermission()
        .then((permission) => {
            if (permission === 'granted') {
                return getToken(messaging, { vapidKey })
            } else {
                throw new Error("Notification not granted");
            }
        })
        .catch((err) => {
            console.error("Error getting FCM token: ", err);
            throw err;
        })
}
