import React from 'react';
import DefaultTemplate from '../../templates/DefaultTemplate';
import Background from '../../components/Background';

const Home = () => {
    return (
        <DefaultTemplate>
            <Background/>
        </DefaultTemplate>
    );
};

export default Home;