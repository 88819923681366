import React, { useEffect, useState } from 'react';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import axiosConfig from '../../api/axiosConfig';
import '../../styles/Import.css';

const ReturnOrderDetail = () => {
    const { returnOrderId } = useParams();
    const [item, setItem] = useState(null);
    const navigate = useNavigate();

    const getReturnOrder = async () => {
        const token = sessionStorage.getItem('token');
        try {
            const { data } = await axiosConfig.get(`/api/return-order/get-return-order-by-id?returnOrderId=${returnOrderId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(data);
            setItem(data);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    useEffect(() => {
        getReturnOrder();
    }, []);

    const handleEdit = () => {
        navigate(`/return-order/edit/${returnOrderId}`);
    };

    const formattedModifyDate = item && item.lastModifiedDate
        ? format(new Date(item.lastModifiedDate * 1000), 'dd/MM/yyyy')
        : '../../....';

    return (
        <DefaultTemplate>
            <div className="receipt-container">
                <div className="header">
                    <h3 className='title-name' style={{ marginBottom: '0', marginLeft: '10px' }}>
                        <Link to="/return-order" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Trả hàng
                        </Link>
                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronRight} />
                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faChevronRight} />
                        Chi tiết phiếu trả hàng {item?.invoiceNumber}
                    </h3>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="import-list" style={{ flex: '4', textAlign: 'start', margin: '0 10px', borderRadius: '10px', overflow: 'auto' }}>
                        <table style={{ boxShadow: 'none' }}>
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Mã - Tên sản phẩm</th>
                                    <th>Đơn vị</th>
                                    <th>Đơn giá</th>
                                    <th>Số lô</th>
                                    <th>Số lượng trả</th>
                                    <th>Chiết khấu (%)</th>
                                    <th>Thành tiền</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {item?.returnOrderItems ? item.returnOrderItems.map((item1, index) => (
                                    <tr key={index + 1} style={{ textAlign: 'center' }}>
                                        <td>{index + 1}</td>
                                        <td style={{ textAlign: 'left' }}>{item1.product?.productCode} - {item1.product?.productName}</td>
                                        <td>{item1.unit}</td>
                                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item1.unitPrice)}</td>
                                        <td>
                                            {item1?.batchResponseDtos?.map((importItem) => {
                                                return <p>{importItem.batchNumber}</p>
                                            })}
                                        </td>
                                        <td>
                                            {item1?.batchResponseDtos?.map((importItem) => {
                                                return <p>{importItem.returnedQuantity}</p>
                                            })}
                                        </td>
                                        <td>{item?.saleOrder?.saleOrderItems?.find(p => p.product.id === item1.product.id).discount || 0}</td>
                                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item1.totalAmount - (item1.totalAmount * (item?.saleOrder?.saleOrderItems?.find(p => p.product.id === item1.product.id).discount) / 100))}</td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan={8}>
                                            <LinearProgress color='inherit' />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ backgroundColor: 'white', marginRight: '5px', textAlign: 'start', padding: '15px 25px', borderRadius: '10px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)', flex: '1' }}>
                        <h4 className='title-name' style={{ color: '#022742', margin: '5px 0' }}>Trả hàng hóa đơn {item?.saleOrder?.invoiceNumber}</h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Ngày tạo phiếu:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{item?.returnDate ? new Date(item.returnDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '../../....'}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Người tạo phiếu:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{item?.createdBy}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Khách hàng:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{item?.customer?.customerName}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng tiền hóa đơn gốc:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.saleOrder.totalAmount || 0)}
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng tiền hàng trả:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(
                                    item?.returnOrderItems?.reduce((total, currentItem) => {
                                        return total + (currentItem.quantity * currentItem.unitPrice);
                                    }, 0) || 0
                                )}
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Giảm giá:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(
                                    (item?.returnOrderItems?.reduce((total, currentItem) => {
                                        return total + (currentItem.quantity * currentItem.unitPrice);
                                    }, 0) || 0) - (item?.refundAmount || 0)
                                )}
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent' style={{ fontWeight: 'bold' }}><span>Tiền trả khách:</span></div>
                            <p style={{ color: '#09446d', fontWeight: 'bold', fontSize: '14px' }}>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.refundAmount || 0)}
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Lí do trả hàng:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{item?.returnReason || 'N/A'}</p>
                        </div>
                        <div style={{ display: 'flex', flex: '1', marginTop: '50px', justifyContent: 'center' }}>
                            <Button onClick={handleEdit} variant="contained" className='btn' >
                                Sửa
                            </Button>
                            <Button type='btn' variant="contained" className='btn' onClick={() => { navigate('/return-order') }}>
                                Quay lại
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <p><strong>Chỉnh sửa lần cuối ngày {formattedModifyDate} bởi {item && item.lastModifiedBy ? (<>{item.lastModifiedBy}</>) : (<>....</>)}</strong></p>
                </div>
            </div>
        </DefaultTemplate>
    );
};

export default ReturnOrderDetail;