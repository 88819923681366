import { React } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faTruckMedical } from "@fortawesome/free-solid-svg-icons";
import '../styles/Import.css';

function ReceiptFooter({ activeItem, setActiveItem }) {
  return (
    <div className="receipt-footer">
      {activeItem === true ? (
        <>
          <div
            className={`receipt-footer-item active`}
            onClick={() => setActiveItem(true)}
          >
            <FontAwesomeIcon icon={faClock} className="receipt-footer-icon" />
            <span style={{ paddingBottom: '3px' }}>Bán thường</span>
          </div>
          <div
            className={`receipt-footer-item`}
            onClick={() => setActiveItem(false)}
          >
            <FontAwesomeIcon icon={faTruckMedical} className="receipt-footer-icon" />
            <span style={{ paddingBottom: '3px' }}>Bán theo đơn của bác sĩ</span>
          </div>
        </>
      ) : (
        <>
          <div
            className={`receipt-footer-item`}
            onClick={() => setActiveItem(true)}
          >
            <FontAwesomeIcon icon={faClock} className="receipt-footer-icon" />
            <span style={{ paddingBottom: '3px' }}>Bán thường</span>
          </div>
          <div
            className={`receipt-footer-item active`}
            onClick={() => setActiveItem(false)}
          >
            <FontAwesomeIcon icon={faTruckMedical} className="receipt-footer-icon" />
            <span style={{ paddingBottom: '3px' }}>Bán theo đơn của bác sĩ</span>
          </div></>
      )}
    </div>
  );
}

export default ReceiptFooter;