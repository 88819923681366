import React, { useEffect, useState } from "react";
import "flatpickr/dist/themes/material_blue.css";
import '../styles/Report.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faClipboardCheck, faCreditCard, faFileInvoiceDollar, faMoneyBillTrendUp, faMoneyBillWave, faSearch } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress, Card, CardContent, Typography, Grid, Button, CircularProgress } from '@mui/material';
import api from '../api/axiosConfig';
import { toast } from 'react-toastify';
import { FiDownload } from 'react-icons/fi';

const ReportSale = ({ dateRange }) => {
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [saleReportList, setSaleReportList] = useState([]);
    const [productReportList, setProductReportList] = useState([]);
    const [saleReport, setSaleReport] = useState(null);
    const [dropdownSelected, setDropdownSelected] = useState("invoice");
    const [productName, setProductName] = useState('');
    const [voucherType, setVoucherType] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [downloading, setDownloading] = useState(false);

    const convertToLocalDate = (date) => {
        if (date) {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);

            return newDate.toISOString().split('T')[0];
        }
        return null;
    };

    const getSaleReportList = async (page) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/reports/sales-list?pageNumber=${page - 1}&pageSize=10`;
            if (dateRange && dateRange[0] && dateRange[1]) {
                url += `&startDate=${convertToLocalDate(dateRange[0])}&endDate=${convertToLocalDate(dateRange[1])}`;
            }
            if (paymentMethod && paymentMethod !== '') {
                url += `&paymentMethod=${paymentMethod}`;
            }
            if (voucherType && voucherType !== '') {
                url += `&voucherType=${voucherType}`;
            }
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSaleReportList(response.data);
            setTotalPage(response.data.totalPages);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const getProductReportList = async (page) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/reports/sales-product?pageNumber=${page - 1}&pageSize=10`;
            if (dateRange && dateRange[0] && dateRange[1]) {
                url += `&startDate=${convertToLocalDate(dateRange[0])}&endDate=${convertToLocalDate(dateRange[1])}`;
            }
            if (productName && productName !== '') {
                url += `&productName=${productName}`;
            }
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setProductReportList(response.data);
            setTotalPage(response.data.totalPages);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const getSaleReport = async () => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/reports/sales`;
            if (dateRange && dateRange[0] && dateRange[1]) {
                url += `?startDate=${convertToLocalDate(dateRange[0])}&endDate=${convertToLocalDate(dateRange[1])}`;
            }
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSaleReport(response.data);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const getExportExcel = async () => {
        if (!dateRange) {
            toast.error('Vui lòng chọn thời gian');
            return;
        }
        setDownloading(true);
        const token = sessionStorage.getItem('token');
        try {
            if (dateRange && dateRange[0] && dateRange[1]) {
                let url = `/api/reports/export-excel-sales-report?fromDate=${convertToLocalDate(dateRange[0])}&toDate=${convertToLocalDate(dateRange[1])}`;
                const response = await api.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                });
                const blobUrl = window.URL.createObjectURL(response.data);
                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = 'Bao_cao_ban_hang.xlsx';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            console.error(error);
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        } finally {
            setDownloading(false);
        }
    };

    useEffect(() => {
        if (dropdownSelected === 'invoice') {
            getSaleReportList(currentPage);
        } else {
            getProductReportList(currentPage);
        }
        getSaleReport();
    }, [currentPage, dateRange, dropdownSelected, productName, voucherType, paymentMethod])

    const getVisiblePages = () => {
        const maxButtons = 3;
        const half = Math.floor(maxButtons / 2);
        let startPage, endPage;
        if (totalPage <= maxButtons) {
            startPage = 1;
            endPage = totalPage;
        } else {
            if (currentPage <= half) {
                startPage = 1;
                endPage = maxButtons;
            } else if (currentPage + half >= totalPage) {
                startPage = totalPage - maxButtons + 1;
                endPage = totalPage;
            } else {
                startPage = currentPage - half;
                endPage = currentPage + half;
            }
        }
        return { startPage, endPage };
    }
    const { startPage, endPage } = getVisiblePages();
    const renderDate = (value) => {
        const date = new Date(Math.floor(value) * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const renderCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
    };

    const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

    const data =
        dropdownSelected === "invoice"
            ? [
                { icon: faFileInvoiceDollar, value: `${saleReport?.totalInvoices || saleReportList?.totalElements || 0}`, label: "Tổng hóa đơn", color: "#a17cf8" },
                { icon: faMoneyBillTrendUp, value: `${renderCurrency(saleReport?.totalRevenue || 0)}`, label: "Doanh thu hóa đơn", color: "#56ccf2" },
                { icon: faMoneyBillWave, value: `${renderCurrency(saleReport?.cashRevenue || 0)}`, label: "Tiền mặt", color: "#2ecc71" },
                { icon: faCreditCard, value: `${renderCurrency(saleReport?.transferRevenue || 0)}`, label: "Chuyển khoản", color: "#e74c3c" },
            ]
            : dropdownSelected === "product"
                ? [
                    { icon: faClipboardCheck, value: `${saleReport?.totalQuantitySold || 0}`, label: "Sản phẩm đã thanh toán", color: "#f2994a" },
                    { icon: faCashRegister, value: `${renderCurrency(saleReport?.totalRevenue || 0)}`, label: "Doanh thu sản phẩm", color: "#6ee7b7" },
                ]
                : [];

    return (
        <div className="report-container">
            <div className="btnSearch" style={{ marginBottom: '0' }}>
                <select className="custom-select" name="customer" value={dropdownSelected} onChange={(e) => setDropdownSelected(e.target.value)} style={{ width: '140px' }}>
                    <option value="invoice">Hóa đơn</option>
                    <option value="product">Sản phẩm</option>
                </select>
                {dropdownSelected === 'invoice' ? (
                    <>
                        <select className="custom-select" name="voucherType" onChange={(e) => setVoucherType(e.target.value)}>
                            <option value="" selected>Loại hóa đơn</option>
                            <option value="Bán hàng">Bán hàng</option>
                            <option value="Khách hàng trả lại">Khách hàng trả lại</option>
                        </select>
                        {voucherType === 'Bán hàng' && (
                            <select className="custom-select" name="paymentMethod" onChange={(e) => setPaymentMethod(e.target.value)}>
                                <option value="" selected>Phương thức thanh toán</option>
                                <option value="CASH">Tiền mặt</option>
                                <option value="TRANSFER">Chuyển khoản</option>
                            </select>
                        )}
                    </>
                ) : (
                    <div className="search-input-container">
                        <input
                            name="name"
                            type="text"
                            onChange={(e) => setProductName(e.target.value)}
                            placeholder="Nhập tên sản phẩm..."
                            autocomplete="off"
                        />
                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                    </div>
                )}
                <Button className="export-btn" style={{ backgroundColor: "#09446d", color: '#fff' }} onClick={getExportExcel}>
                    Xuất file
                    <FiDownload />
                </Button>
            </div>
            <Grid container columnSpacing={3} style={{ padding: '20px 0' }}>
                {data.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card style={{ borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                            <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '16px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ backgroundColor: `${item.color}33`, borderRadius: '50%', padding: '12px', marginRight: '16px', width: '30px', height: '30px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        <FontAwesomeIcon icon={item.icon} style={{ color: item.color, fontSize: '24px' }} />
                                    </div>
                                    <div>
                                        <Typography variant="h5" style={{ fontWeight: 600 }}>
                                            {item.value}
                                        </Typography>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {item.label}
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {dropdownSelected === 'invoice' ? (
                <div style={{ overflowX: 'auto' }}>
                    <table className="list">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Mã hóa đơn</th>
                                <th>Loại hóa đơn</th>
                                <th>Ngày tạo</th>
                                <th>Khách hàng</th>
                                <th>Phương thức thanh toán</th>
                                <th>Tổng tiền</th>
                            </tr>
                        </thead>
                        <tbody>
                            {saleReportList?.content ?
                                (saleReportList?.content.length > 0 ? (
                                    saleReportList.content.map((sale, index) => (
                                        <tr key={index}>
                                            <td>{(index + 1) + ((currentPage - 1) * 10)}</td>
                                            <td>{sale?.invoiceNumber}</td>
                                            <td>{sale?.voucherType}</td>
                                            <td>{renderDate(sale?.creationDate)}</td>
                                            <td>{sale?.customerName}</td>
                                            <td>
                                                {sale?.paymentMethod === 'CASH' && "Tiền mặt"}
                                                {sale?.paymentMethod === 'TRANSFER' && "Chuyển khoản"}
                                                {sale?.paymentMethod !== 'CASH' && sale?.paymentMethod !== 'TRANSFER' && "N/A"}
                                            </td>
                                            <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(sale?.totalAmount)}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={7} style={{ padding: '20px' }}>
                                            Không có dữ liệu
                                        </td>
                                    </tr>
                                )
                                ) : (
                                    <tr>
                                        <td colSpan={7} style={{ padding: '20px' }}>
                                            <LinearProgress color="inherit" />
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div style={{ overflowX: 'auto' }}>
                    <table className="list">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Mã sản phẩm</th>
                                <th>Tên sản phẩm</th>
                                <th>Đơn vị</th>
                                <th>Số lượng giao dịch</th>
                                <th>Số lượng bán</th>
                                <th>Tổng tiền</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productReportList?.content ?
                                (
                                    productReportList?.content.length > 0 ? (
                                        productReportList.content.map((product, index) => (
                                            <tr key={index}>
                                                <td>{(index + 1) + ((currentPage - 1) * 10)}</td>
                                                <td>{product?.productCode}</td>
                                                <td>{product?.productName}</td>
                                                <td>{product?.unit}</td>
                                                <td>{product?.transactionCount}</td>
                                                <td>{product?.quantitySold}</td>
                                                <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product?.totalAmount)}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={7} style={{ padding: '20px' }}>
                                                Không có dữ liệu
                                            </td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={7} style={{ padding: '20px' }}>
                                            <LinearProgress color="inherit" />
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            )}

            {totalPage > 1 && (
                <div className="pagination">
                    {currentPage === 1 ? (
                        <button disabled>&laquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</button>
                    )}
                    {startPage > 1 && (
                        <>
                            <button onClick={() => setCurrentPage(1)}>1</button>
                            {startPage > 2 && (
                                <button disabled>...</button>
                            )}
                        </>
                    )}
                    {visiblePages.map((page) => (
                        <button onClick={() => setCurrentPage(page)}
                            key={page}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}
                    {endPage < totalPage && (
                        <>
                            {endPage < totalPage - 1 && (
                                <button disabled>...</button>
                            )}
                            <button onClick={() => setCurrentPage(totalPage)}>{totalPage}</button>
                        </>
                    )}
                    {currentPage === totalPage ? (
                        <button disabled>&raquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</button>
                    )}
                </div>
            )}
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
        </div>
    );
};

export default ReportSale;
