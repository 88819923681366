import React, { useEffect, useState, useRef } from 'react';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPenToSquare, faSave, faSearch, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Autocomplete, Button, TextField, Divider, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import axiosConfig from '../../api/axiosConfig';
import '../../styles/Import.css';
import api from '../../api/axiosConfig';
import Select from 'react-select';

const ExportReceiptEdit = () => {
    const navigate = useNavigate();
    const { exportId } = useParams();
    const [item, setItem] = useState({});
    const [products, setProducts] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [productName, setProductName] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [minQuantity, setMinQuantity] = useState(0);
    const [batchNumber, setBatchNumber] = useState('');
    const [remainingQuantity, setRemainingQuantity] = useState(null);
    const [conversionFactor, setConversionFactor] = useState(0);
    const [options, setOptions] = useState(null);
    const [unitPrice, setUnitPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [indexRow, setIndexRow] = useState(null);
    const [searching, setSearching] = useState(false);
    const [downloading, setDownloading] = useState(false);
    let abortController = null;

    const getProducts = async (productName, signal) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `api/export-slip/get-import-item-by-product-name?productName=${productName}`;
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                signal,
            });
            const data = response.data;
            setProducts(data);
        } catch (error) {
            if (error.status === 404) {
                toast.error(error.response.data.message);
            } else if (error.status >= 400 && error.status < 500) {
                toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
            }
        } finally {
            setSearching(false);
        }
    };

    useEffect(() => {
        getExportReceipt();
    }, []);

    const getSuppliers = async () => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/supplier/get-all-supplier`;
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data = response.data;
            const holder = data.map(item => ({
                value: item.id,
                label: item.supplierName,
            }));
            setOptions(holder);
        } catch (error) {
            if (error.status === 404) {
                toast.error(error.response.data.message);
            } else if (error.status >= 400 && error.status < 500) {
                toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
            }
        }
    }

    useEffect(() => {
        const total = unitPrice * quantity;
        const discountValue = 1 - (discount / 100);
        setTotalPrice(total * discountValue);
    }, [quantity, unitPrice, discount])

    useEffect(() => {
        getSuppliers();
    }, [])

    const getExportReceipt = async () => {
        const token = sessionStorage.getItem('token');
        try {
            const { data } = await axiosConfig.get(`/api/export-slip/get-export-slip/${exportId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setItem(data);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const onSearchChange = (e) => {
        setActiveIndex(-1);
        const value = e.target.value;
        setProductName(value);
        setSelectedProduct(null);
        setShowDropdown(false);
        if (abortController) {
            abortController.abort();
        }
        if (value) {
            setSearching(true);
            abortController = new AbortController();
            getProducts(value, abortController.signal);
            setShowDropdown(true);
        } else {
            setSearching(false);
            setShowDropdown(false);
            setSelectedUnit(null);
            setUnitPrice(0);
            setBatchNumber('');
            setRemainingQuantity(0);
            setMinQuantity(0);
            setQuantity(0);
        }
    };

    const handleNoteChange = (e) => {
        setItem(prevItem => ({
            ...prevItem,
            note: e.target.value
        }));
    };

    useEffect(() => {
        if (selectedProduct) {
            const holder = selectedProduct.importItems.find(item => item.id.toString() === batchNumber.id.toString())
            setRemainingQuantity(holder?.remainingQuantity);
        }
    }, [batchNumber])

    const addProductToReceipt = () => {
        const holder = selectedProduct.importItems.find(item => item.id.toString() === batchNumber.id.toString())
        setItem((prevItem) => ({
            ...prevItem,
            totalAmount: prevItem.totalAmount + unitPrice,
            exportSlipItems: [
                ...(prevItem.exportSlipItems || []),
                {
                    product: {
                        id: selectedProduct.productUnits[0].productId,
                        productCode: selectedProduct.productCode,
                        productName: selectedProduct.productName,
                        productUnitList: selectedProduct.productUnits.map((pUnit) => ({
                            unitName: pUnit.unitName,
                            conversionFactor: pUnit.conversionFactor,
                            unitPrice: pUnit.importPrice,
                        })),
                    },
                    importItem: {
                        id: holder?.id,
                        batchNumber: holder?.batchNumber,
                        remainingQuantity: holder?.remainingQuantity,
                        invoiceNumber: holder?.invoiceNumber,
                        conversionFactor: holder?.conversionFactor,
                        importDate: holder?.importDate,
                    },
                    importItems: selectedProduct.importItems,
                    quantity: quantity || 1,
                    unitPrice: unitPrice,
                    unit: selectedUnit.unitName,
                    discount: discount,
                    batchNumber: batchNumber.id,
                    expiryDate: selectedProduct?.expiryDate,
                    totalAmount: unitPrice,
                    importItemId: selectedProduct?.id,
                    conversionFactor: conversionFactor,
                }
            ]
        }));
        setIndexRow(item?.exportSlipItems?.length || 0);
        setProductName('');
        setBatchNumber('');
        setRemainingQuantity(0);
        setDiscount(0);
        setSelectedProduct(null);
        setSelectedUnit(null);
        setUnitPrice(0);
        setConversionFactor(0);
        setTotalPrice(0);
        setQuantity(0);
    }

    useEffect(() => {
        if (selectedProduct) {
            addProductToReceipt();
        }
    }, [selectedProduct])

    const handleSelectItem = (data) => {
        const firstUnitWithConversionFactor = data.productUnits?.find(unit => unit.conversionFactor > 0);
        const firstItemWithRemaining = data.importItems?.find(item => item.remainingQuantity > 0);
        setSelectedProduct(data);
        setBatchNumber(firstItemWithRemaining);
        setDiscount(data?.discount || 0);
        setRemainingQuantity(data.totalQuantity);
        setProductName(data.productCode + ' - ' + data.productName);
        setSelectedUnit(firstUnitWithConversionFactor?.unit);
        setConversionFactor(firstUnitWithConversionFactor?.conversionFactor);
        setUnitPrice(firstUnitWithConversionFactor?.importPrice);
        setMinQuantity(1);
        setQuantity(1);
        setShowDropdown(false);
    };

    const handleDeleteProduct = (indexToDelete) => {
        setItem((prevFormData) => ({
            ...prevFormData,
            totalAmount: prevFormData.totalAmount - prevFormData.exportSlipItems[indexToDelete].totalAmount,
            exportSlipItems: prevFormData.exportSlipItems.filter((_, index) => index !== indexToDelete)
        }));
    };

    const handleSupplierChange = (selectedOption) => {
        setItem(prevItem => ({
            ...prevItem,
            supplier: {
                supplierName: selectedOption?.label,
                id: selectedOption?.value,
            }
        }));
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '100%',
            minWidth: '300px',
            padding: '0',
            border: '1px solid #ccc',
            borderRadius: '2px',
            fontSize: '14px',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#f3f3f3',
            color: '#333',
            borderRadius: '3px',
            padding: '3px',
            margin: '2px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#333',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#333',
            cursor: 'pointer',
        }),
    };

    const handleFieldChange = (index, fieldName, fieldValue) => {
        setItem((prevFormData) => {
            const updatedImportItems = [...prevFormData.exportSlipItems];
            let tempAmount = updatedImportItems[index].totalAmount;
            let tempAmountDiscount = updatedImportItems[index].totalAmount;
            const tempUnitPrice = updatedImportItems[index].unitPrice;
            const tempQuantity = updatedImportItems[index].quantity;
            const tempDiscount = updatedImportItems[index].discount;
            if (fieldName === 'unitPrice') {
                tempAmount = calculateTotalAmount(fieldValue, tempQuantity, tempDiscount)
                tempAmountDiscount = calculateTotalAmount(fieldValue, tempQuantity, 0)
            } else if (fieldName === 'quantity') {
                tempAmount = calculateTotalAmount(tempUnitPrice, fieldValue, tempDiscount)
                tempAmountDiscount = calculateTotalAmount(tempUnitPrice, fieldValue, 0)
            } else if (fieldName === 'discount') {
                tempAmount = calculateTotalAmount(tempUnitPrice, tempQuantity, fieldValue)
                tempAmountDiscount = calculateTotalAmount(tempUnitPrice, tempQuantity, 0)
            }
            if (fieldName === 'conversionFactor' || fieldName === 'batchNumber' || fieldName === 'importDate' || fieldName === 'remainingQuantity' || fieldName === 'invoiceNumber') {
                updatedImportItems[index] = {
                    ...updatedImportItems[index],
                    importItem: {
                        ...updatedImportItems[index].importItem,
                        [fieldName]: fieldValue,
                    },
                };
            } else if (fieldName === 'importId') {
                updatedImportItems[index] = {
                    ...updatedImportItems[index],
                    importItem: {
                        ...updatedImportItems[index].importItem,
                        id: fieldValue,
                    },
                };
            } else {
                updatedImportItems[index] = {
                    ...updatedImportItems[index],
                    [fieldName]: fieldValue,
                    totalAmount: tempAmount,
                    totalAmountDiscount: tempAmountDiscount,
                };
            }

            if (fieldName !== 'conversionFactor') {
                updatedImportItems[index].totalAmount = tempAmount;
            }

            const grandTotal = updatedImportItems.reduce((sum, item) => sum + item.totalAmount, 0);
            const grandTotalDiscount = updatedImportItems.reduce((sum, item) => sum + item.totalAmountDiscount, 0);

            return {
                ...prevFormData,
                exportSlipItems: updatedImportItems,
                totalAmount: grandTotal,
                totalAmountDiscount: grandTotalDiscount
            };
        });
    };

    const calculateTotalAmount = (unitPrice, quantity, discount) => {
        const total = unitPrice * quantity;
        const discountValue = 1 - (discount / 100);
        const totalPrice = total * discountValue;
        return totalPrice;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDownloading(true);
        if (!item.exportSlipItems || item.exportSlipItems.length === 0) {
            toast.error('Danh sách sản phẩm xuất không được trống');
            setDownloading(false);
            return;
        }
        const u = sessionStorage.getItem('id');
        const submitData = {
            id: 0,
            typeDelivery: 'RETURN_TO_SUPPLIER',
            discount: 0,
            totalAmount: item.totalAmount,
            userId: u,
            note: item.note,
            exportSlipItems: item.exportSlipItems?.map((product) => ({
                conversionFactor: product.product.productUnitList?.find(item => item.unitName === product.unit)?.conversionFactor ?? product.conversionFactor,
                quantity: product.quantity,
                unitPrice: product.unitPrice,
                unit: product.unit,
                discount: product.discount,
                exportSlipId: 0,
                batchNumber: product.importItem.batchNumber,
                tax: product.vat,
                totalAmount: product.totalAmount,
                productId: product.product.id,
                importItemId: product.importItem.id,
                invoiceNumber: product.importItem.invoiceNumber,
            })),
            productCount: 0,
            exportDate: "2024-10-19T11:39:53.673Z",
            supplierId: item?.supplier.id,
        };
        console.log("submit data:");
        console.log(submitData);
        const token = sessionStorage.getItem('token');
        try {
            const { data } = await api.put(`api/export-slip/update-export-slip/${exportId}`, submitData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setTimeout(() => {
                toast.success(data);
            }, 500);
            navigate(`/export/return-supplier/detail/${item.id}`);
        } catch (err) {
            console.log(err);
            if (err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error('Không thể cập nhật phiếu trả nhà cung cấp, vui lòng thử lại.');
            }
        } finally {
            setDownloading(false);
        }
    };

    const today = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    const [activeIndex, setActiveIndex] = useState(-1);
    const itemRefs = useRef([]);

    useEffect(() => {
        if (activeIndex >= 0 && itemRefs.current[activeIndex]) {
            itemRefs.current[activeIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [activeIndex]);

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setShowDropdown(true);
            setActiveIndex((prevIndex) => Math.min(prevIndex + 1, products.length - 1));
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (e.key === "Enter" && activeIndex >= 0) {
            handleSelectItem(products[activeIndex]);
        }
    };

    const renderDate = (value) => {
        const date = new Date(Math.floor(value) * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <DefaultTemplate>
            <div className="receipt-container">
                <div className="header">
                    <h3 className='title-name' style={{ marginBottom: '0', marginLeft: '10px' }}>
                        <Link to="/export" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Phiếu Xuất
                        </Link>
                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronRight} />
                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faChevronRight} />
                        Chỉnh sửa phiếu trả nhà cung cấp {item?.invoiceNumber}
                    </h3>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '4', textAlign: 'start', margin: '0 10px', borderRadius: '10px' }}>
                        <div className="search-container" style={{ position: 'relative', textAlign: 'start', marginBottom: '10px', width: '25vw' }}>
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                            <input
                                style={{ width: '300px' }}
                                name="productName"
                                type="text"
                                value={productName}
                                placeholder="Nhập tên sản phẩm..."
                                onChange={onSearchChange}
                                onKeyDown={handleKeyDown}
                                autocomplete="off"
                            />
                            {showDropdown && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '100%',
                                        left: 0,
                                        height: 'auto',
                                        maxHeight: '100px',
                                        overflowY: 'auto',
                                        width: '100%',
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                                        zIndex: 1000,
                                    }}
                                >
                                    {products.length > 0 ? (
                                        products
                                            .filter(product => product.totalQuantity > 0)
                                            .map((product, index) => (
                                                <div key={index} className="onHover" onClick={() => handleSelectItem(product)} style={{ padding: '10px', cursor: 'pointer', backgroundColor: activeIndex === index ? "#f0f0f0" : "white" }}>
                                                    <span>
                                                        {product.productName}<br />
                                                        <strong>Mã:</strong>{product.productCode}
                                                        <strong> - Tồn:</strong>{product.totalQuantity} ({product.productUnits?.find((unit) => unit.conversionFactor === 1).unitName})
                                                        <strong> - SĐK:</strong>{product.registrationNumber}
                                                        <strong> - Hãng SX:</strong>{product.manufacturer}
                                                    </span>
                                                </div>
                                            ))
                                    ) : (
                                        <div style={{ padding: '10px', cursor: 'pointer' }}>
                                            Không tìm thấy sản phẩm
                                        </div>
                                    )}
                                </div>
                            )}
                            {searching && (
                                <div style={{ position: 'absolute', top: '100%', left: 0, height: 'auto', maxHeight: '100px', overflowY: 'auto', padding: '20px 0', width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', zIndex: 1000 }}>
                                    <CircularProgress size={40} />
                                </div>
                            )}
                        </div>
                        <div className="import-list" style={{ height: '500px', overflow: 'auto' }}>
                            <table style={{ boxShadow: 'none' }}>
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>Mã - Tên sản phẩm</th>
                                        <th>Đơn vị</th>
                                        <th>Đơn giá</th>
                                        <th>Số lượng</th>
                                        <th>C.K (%)</th>
                                        <th>Số lô</th>
                                        <th>Mã phiếu nhập</th>
                                        <th>Ngày nhập</th>
                                        <th>Tồn</th>
                                        <th>Thành tiền</th>
                                        <th>#</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: 'center' }}>
                                    {item?.exportSlipItems ? item.exportSlipItems.map((item, index) => (
                                        <>
                                            {index === indexRow ? (
                                                <tr style={{ textAlign: 'center' }} key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textAlign: 'left' }}>{item.product?.productCode} - {item.product?.productName}</td>
                                                    <td>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Autocomplete
                                                                style={{ width: '80px' }}
                                                                value={item.product.productUnitList.find(unit => unit.unitName === item.unit) || null}
                                                                onChange={(event, newValue) => {
                                                                    handleFieldChange(index, 'unit', newValue ? newValue.unitName : '')
                                                                    handleFieldChange(index, 'conversionFactor', newValue ? newValue.conversionFactor : '')
                                                                    handleFieldChange(index, 'unitPrice', newValue?.importPrice ? newValue.importPrice : newValue.unitPrice)
                                                                }}
                                                                options={item.product.productUnitList.filter((item) => item.conversionFactor > 0)}
                                                                getOptionLabel={(option) => option.unitName}
                                                                renderInput={(params) => <TextField {...params} variant='standard' />}
                                                                disableClearable
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.unitPrice)}</td>
                                                    <td>
                                                        <TextField
                                                            style={{ width: '60px' }}
                                                            name="quantity"
                                                            type="number"
                                                            value={item.quantity}
                                                            onChange={(e) => {
                                                                if (e.target.value <= 0) {
                                                                    handleFieldChange(index, 'quantity', 1)
                                                                } else if (e.target.value > Math.floor(item.importItem?.remainingQuantity / item.importItem?.conversionFactor) || 0) {
                                                                    handleFieldChange(index, 'quantity', Math.floor(item.importItem?.remainingQuantity / item.importItem?.conversionFactor) || 0)
                                                                } else {
                                                                    handleFieldChange(index, 'quantity', e.target.value)
                                                                }
                                                            }}
                                                            inputProps={{
                                                                onKeyPress: (e) => {
                                                                    if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                                        e.preventDefault();
                                                                    }
                                                                }
                                                            }}
                                                            variant="standard"
                                                        />
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            style={{ width: '60px' }}
                                                            name="ck"
                                                            type="number"
                                                            value={item.discount}
                                                            onChange={(e) => {
                                                                if (e.target.value > 100) {
                                                                    handleFieldChange(index, 'discount', 100)
                                                                } else {
                                                                    handleFieldChange(index, 'discount', e.target.value)
                                                                }
                                                            }}
                                                            inputProps={{
                                                                onKeyPress: (e) => {
                                                                    if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                                        e.preventDefault();
                                                                    }
                                                                },
                                                                min: 0,
                                                                max: 100,
                                                            }}
                                                            variant="standard"
                                                        />
                                                    </td>
                                                    <td>
                                                        <Autocomplete
                                                            style={{ width: '100px' }}
                                                            value={item.importItems?.find(data => data.invoiceNumber === item?.importItem?.invoiceNumber) || null}
                                                            onChange={(event, newValue) => {
                                                                handleFieldChange(index, 'batchNumber', newValue ? newValue.batchNumber : '');
                                                                handleFieldChange(index, 'importDate', newValue ? newValue.importDate : '');
                                                                handleFieldChange(index, 'remainingQuantity', newValue ? newValue.remainingQuantity : '');
                                                                handleFieldChange(index, 'invoiceNumber', newValue ? newValue.invoiceNumber : '');
                                                                handleFieldChange(index, 'importId', newValue ? newValue.id : '');
                                                            }}
                                                            options={item?.importItems?.filter((it) => it.remainingQuantity > 0)}
                                                            getOptionLabel={(option) => option.batchNumber}
                                                            renderInput={(params) => <TextField {...params} variant='standard' />}
                                                            disableClearable
                                                        />
                                                    </td>
                                                    <td>{item.importItem?.invoiceNumber}</td>
                                                    <td>{renderDate(item.importItem?.importDate)}</td>
                                                    <td>{Math.floor(item.importItem?.remainingQuantity / item.importItem?.conversionFactor) || 0}</td>
                                                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.totalAmount)}</td>
                                                    <td style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                        <button className="edit-btn"
                                                            onClick={() => setIndexRow(null)}
                                                        >
                                                            <FontAwesomeIcon icon={faSave} />
                                                        </button>
                                                        <button className="delete-btn"
                                                            onClick={() => handleDeleteProduct(index)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr style={{ textAlign: 'center' }} key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textAlign: 'left' }}>{item.product?.productCode} - {item.product?.productName}</td>
                                                    <td>{item.unit}</td>
                                                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.unitPrice)}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.discount}</td>
                                                    <td>{item.importItem?.batchNumber}</td>
                                                    <td>{item.importItem?.invoiceNumber}</td>
                                                    <td>{renderDate(item.importItem?.importDate)}</td>
                                                    <td>{Math.floor(item.importItem?.remainingQuantity / item.importItem?.conversionFactor) || 0}</td>
                                                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.totalAmount)}</td>
                                                    <td style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                        <button className="edit-btn"
                                                            onClick={() => setIndexRow(index)}
                                                        >
                                                            <FontAwesomeIcon icon={faPenToSquare} />
                                                        </button>
                                                        <button className="delete-btn"
                                                            onClick={() => handleDeleteProduct(index)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )) : (
                                        <tr>
                                            <td colSpan={12}>
                                                <LinearProgress color='inherit' />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ backgroundColor: 'white', marginRight: '5px', textAlign: 'start', padding: '15px 25px', borderRadius: '10px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)', flex: '1' }}>
                        <div style={{ alignItems: 'center', textAlign: 'right', fontSize: '13px' }}>{today}</div>
                        <Divider />
                        <h4 className='title-name' style={{ color: '#022742', marginBottom: '5px' }}>Thông tin phiếu trả nhà cung cấp</h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Ngày tạo phiếu:</span></div>
                            <div className='importContent'>{item?.exportDate ? new Date(item.exportDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '../../....'}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Người tạo phiếu:</span></div>
                            <div className='importContent'>{item?.createBy}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Trạng thái phiếu:</span></div>
                            <div className='importContent'>{item?.status === 'PENDING' ? 'Chờ xác nhận' : item?.status === 'CONFIRMED' ? 'Đã xác nhận' : 'Từ chối'}</div>
                        </div>
                        <div style={{ alignItems: 'center' }}>
                            <div className='fieldContent'><span>Nhà cung cấp:</span></div>
                            <div className="search-container">
                                <Select
                                    value={options?.find(option => option.value === item?.supplier?.id)}
                                    onChange={handleSupplierChange}
                                    options={options}
                                    required
                                    styles={customStyles}
                                    placeholder="Chọn nhà cung cấp"
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Số lượng sản phẩm:</span></div>
                            <div className='importContent'>{item?.exportSlipItems?.length} sản phẩm</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng tiền hàng:</span></div>
                            <div className='importContent'>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.totalAmountDiscount ? item?.totalAmountDiscount : item?.exportSlipItems?.reduce((sum, item) => sum + (item.unitPrice * item.quantity), 0) || 0)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng chiết khấu:</span></div>
                            <div className='importContent'>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.totalAmountDiscount ? (item?.totalAmountDiscount - item?.totalAmount) : (item?.exportSlipItems?.reduce((sum, item) => sum + (item.unitPrice * item.quantity), 0) - item?.totalAmount) || 0)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent' style={{ fontWeight: 'bold' }}><span>Nhà cung cấp cần trả:</span></div>
                            <div className='importContent'>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.totalAmount || 0)}
                            </div>
                        </div>
                        <TextField
                            name="note"
                            type="text"
                            label="Lí do trả nhà cung cấp"
                            value={item?.note}
                            onChange={handleNoteChange}
                            variant="standard"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                autoComplete: "off",
                            }}
                        />
                        <div style={{ display: 'flex', flex: '1', marginTop: '20px', justifyContent: 'center' }}>
                            <Button onClick={handleSubmit} variant="contained" className='btn' >
                                Lưu
                            </Button>
                            <Button type='btn' variant="contained" className='btn' onClick={() => { navigate(-1) }}>
                                Quay lại
                            </Button>
                        </div>
                    </div>
                </div>
                {downloading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100vh',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            zIndex: 99999,
                        }}
                    >
                        <CircularProgress size="5rem" />
                    </div>
                )}
            </div>
        </DefaultTemplate>
    );
};

export default ExportReceiptEdit;
