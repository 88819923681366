const addressData = {
  'Hà Nội': {
    districts: {
      'Ba Đình': [
        'Phúc Xá',
        'Trúc Bạch',
        'Quán Thánh',
        'Nguyễn Trung Trực',
        'Điện Biên',
        'Liễu Giai',
        'Ngọc Hà',
        'Cống Vị',
        'Vĩnh Phúc',
      ],
      'Hoàn Kiếm': [
        'Phúc Tân',
        'Chương Dương',
        'Hàng Bạc',
        'Hàng Bồ',
        'Hàng Bông',
        'Hàng Buồm',
        'Hàng Đào',
        'Hàng Gai',
        'Hàng Mã',
        'Hàng Trống',
      ],
      'Đống Đa': [
        'Cát Linh',
        'Hàng Bột',
        'Khâm Thiên',
        'Láng Hạ',
        'Láng Thượng',
        'Nam Đồng',
        'Ngã Tư Sở',
        'Phương Mai',
        'Quang Trung',
        'Thịnh Quang',
      ],
      'Thanh Xuân': [
        'Thanh Xuân Bắc',
        'Thanh Xuân Nam',
        'Khương Đình',
        'Khương Mai',
        'Khương Trung',
        'Nhân Chính',
        'Phương Liệt',
        'Thượng Đình',
      ],
      'Cầu Giấy': [
        'Nghĩa Tân',
        'Dịch Vọng',
        'Quan Hoa',
        'Mai Dịch',
        'Yên Hòa',
        'Nghĩa Đô',
        'Trung Hòa',
      ],
      'Hai Bà Trưng': [
        'Bạch Đằng',
        'Bách Khoa',
        'Bạch Mai',
        'Đống Mác',
        'Đồng Nhân',
        'Đồng Tâm',
        'Lê Đại Hành',
        'Minh Khai',
        'Ngô Thì Nhậm',
        'Phố Huế',
      ],
      'Tây Hồ': [
        'Bưởi',
        'Nhật Tân',
        'Quảng An',
        'Thụy Khuê',
        'Tứ Liên',
        'Xuân La',
        'Yên Phụ',
      ],
      'Hoàng Mai': [
        'Đại Kim',
        'Định Công',
        'Giáp Bát',
        'Hoàng Liệt',
        'Hoàng Văn Thụ',
        'Lĩnh Nam',
        'Mai Động',
        'Tân Mai',
        'Thanh Trì',
        'Thịnh Liệt',
        'Trần Phú',
      ],
      'Long Biên': [
        'Bồ Đề',
        'Cự Khối',
        'Đức Giang',
        'Giang Biên',
        'Gia Thụy',
        'Long Biên',
        'Ngọc Lâm',
        'Ngọc Thụy',
        'Phúc Đồng',
        'Phúc Lợi',
        'Sài Đồng',
        'Thạch Bàn',
        'Thượng Thanh',
        'Việt Hưng',
      ],
      'Hà Đông': [
        'Biên Giang',
        'Đồng Mai',
        'Dương Nội',
        'Hà Cầu',
        'Kiến Hưng',
        'La Khê',
        'Mộ Lao',
        'Nguyễn Trãi',
        'Phú La',
        'Phú Lãm',
        'Phú Lương',
        'Quang Trung',
        'Vạn Phúc',
        'Yên Nghĩa',
        'Yết Kiêu',
      ],
      'Nam Từ Liêm': [
        'Cầu Diễn',
        'Đại Mỗ',
        'Mễ Trì',
        'Mỹ Đình 1',
        'Mỹ Đình 2',
        'Phú Đô',
        'Phương Canh',
        'Tây Mỗ',
        'Xuân Phương',
      ],
      'Bắc Từ Liêm': [
        'Cổ Nhuế 1',
        'Cổ Nhuế 2',
        'Đông Ngạc',
        'Đức Thắng',
        'Liên Mạc',
        'Minh Khai',
        'Phú Diễn',
        'Phúc Diễn',
        'Tây Tựu',
        'Thượng Cát',
        'Thụy Phương',
        'Xuân Đỉnh',
        'Xuân Tảo',
      ],
      'Thanh Trì': [
        'Cổ Bi',
        'Đông Dư',
        'Dương Xá',
        'Gia Lâm',
        'Kim Sơn',
        'Kiêu Kỵ',
        'Lệ Chi',
        'Phú Thị',
        'Trâu Quỳ',
        'Yên Viên',
      ],
      'Gia Lâm': [
        'Đặng Xá',
        'Đa Tốn',
        'Dương Quang',
        'Dương Xá',
        'Kiêu Kỵ',
        'Kim Sơn',
        'Lệ Chi',
        'Ninh Hiệp',
        'Phú Thị',
        'Trâu Quỳ',
        'Yên Viên',
      ],
      'Đông Anh': [
        'Cổ Loa',
        'Đông Anh',
        'Đông Hội',
        'Dục Tú',
        'Hải Bối',
        'Kim Chung',
        'Kim Nỗ',
        'Mai Lâm',
        'Nguyên Khê',
        'Thụy Lâm',
        'Tiên Dương',
        'Uy Nỗ',
        'Vân Hà',
        'Vân Nội',
        'Việt Hùng',
        'Vĩnh Ngọc',
        'Xuân Canh',
        'Xuân Nộn',
      ],
      'Sóc Sơn': [
        'Bắc Phú',
        'Bắc Sơn',
        'Đức Hòa',
        'Đông Xuân',
        'Hiền Ninh',
        'Hồng Kỳ',
        'Kim Lũ',
        'Mai Đình',
        'Minh Phú',
        'Minh Trí',
        'Nam Sơn',
        'Phù Linh',
        'Phú Cường',
        'Phù Lỗ',
        'Quang Tiến',
        'Tân Dân',
        'Tân Hưng',
        'Thanh Xuân',
        'Tiên Dược',
        'Trung Giã',
        'Việt Long',
        'Xuân Giang',
        'Xuân Thu',
      ],
      'Sơn Tây': [
        'Lê Lợi',
        'Quang Trung',
        'Ngô Quyền',
        'Phú Thịnh',
        'Sơn Lộc',
        'Trung Hưng',
        'Trung Sơn Trầm',
        'Viên Sơn',
        'Xuân Khanh',
      ],
    },
  },
  'Hải Phòng': {
    districts: {
      'Hồng Bàng': [
        'Quán Toan',
        'Hùng Vương',
        'Thượng Lý',
        'Sở Dầu',
        'Hạ Lý',
        'Minh Khai',
        'Trại Chuối',
        'Quang Trung',
        'Hoàng Văn Thụ',
      ],
      'Ngô Quyền': [
        'Máy Chai',
        'Máy Tơ',
        'Lạc Viên',
        'Cầu Tre',
        'Gia Viên',
        'Đổng Quốc Bình',
        'Lê Lợi',
        'Lạch Tray',
        'Cầu Đất',
      ],
      'Lê Chân': [
        'Vĩnh Niệm',
        'Dư Hàng Kênh',
        'Kênh Dương',
        'Đông Hải',
        'Niệm Nghĩa',
        'Trại Cau',
        'An Biên',
        'An Dương',
        'Hàng Kênh',
      ],
      'Kiến An': [
        'Trần Thành Ngọ',
        'Ngọc Sơn',
        'Nam Sơn',
        'Bắc Sơn',
        'Đồng Hòa',
        'Quán Trữ',
        'Tràng Minh',
        'Lãm Hà',
      ],
      'Đồ Sơn': [
        'Ngọc Hải',
        'Ngọc Xuyên',
        'Bàng La',
        'Minh Đức',
        'Hợp Đức',
        'Vạn Hương',
      ],
      'Dương Kinh': [
        'Anh Dũng',
        'Hòa Nghĩa',
        'Đa Phúc',
        'Tân Thành',
        'Hưng Đạo',
        'Hải Thành',
      ],
      'An Dương': [
        'An Hưng',
        'An Hòa',
        'An Đồng',
        'Hồng Phong',
        'Nam Sơn',
        'Lê Lợi',
        'Đặng Cương',
        'Đại Bản',
        'Đồng Thái',
        'Hồng Thái',
        'Tân Tiến',
      ],
      'An Lão': [
        'An Lão',
        'An Thắng',
        'An Thái',
        'Bát Trang',
        'Chiến Thắng',
        'Đoàn Xá',
        'Mỹ Đức',
        'Quang Trung',
        'Quốc Tuấn',
        'Tân Dân',
        'Thái Sơn',
      ],
      'Thủy Nguyên': [
        'Núi Đèo',
        'Minh Đức',
        'Thủy Triều',
        'Kênh Giang',
        'Lập Lễ',
        'Phục Lễ',
        'Tân Dương',
        'Thiên Hương',
        'Lưu Kiếm',
        'Lưu Kỳ',
        'Gia Đức',
        'Hòa Bình',
      ],
      'Tiên Lãng': [
        'Tiên Lãng',
        'Bạch Đằng',
        'Cấp Tiên',
        'Đại Thắng',
        'Đoàn Lập',
        'Khởi Nghĩa',
        'Kiến Thiết',
        'Nam Hưng',
        'Tiên Cường',
        'Tiên Hưng',
        'Toàn Thắng',
      ],
      'Vĩnh Bảo': [
        'Vĩnh Bảo',
        'Tam Cường',
        'Nhân Hòa',
        'Tân Hưng',
        'Dũng Tiến',
        'Giang Biên',
        'Thắng Thủy',
        'Trung Lập',
        'Vĩnh An',
        'Vĩnh Long',
        'Việt Tiến',
        'Vĩnh Phong',
      ],
      'Cát Hải': [
        'Cát Bà',
        'Cát Hải',
        'Gia Luận',
        'Hiền Hào',
        'Phù Long',
        'Trân Châu',
        'Văn Phong',
      ],
      'Bạch Long Vĩ': ['Bạch Long Vĩ'],
    },
  },
  'Thanh Hóa': {
    districts: {
      'TP Thanh Hóa': [
        'Điện Biên',
        'Đông Hải',
        'Đông Hương',
        'Đông Sơn',
        'Đông Thọ',
        'Hàm Rồng',
        'Lam Sơn',
        'Ngọc Trạo',
        'Quảng Hưng',
        'Quảng Thành',
        'Quảng Thắng',
        'Tân Sơn',
        'Trường Thi',
      ],
      'Bỉm Sơn': [
        'Ba Đình',
        'Bắc Sơn',
        'Đông Sơn',
        'Ngọc Trạo',
        'Phú Sơn',
        'Quang Trung',
      ],
      'Sầm Sơn': [
        'Bắc Sơn',
        'Quảng Cư',
        'Quảng Tiến',
        'Trường Sơn',
        'Trung Sơn',
      ],
      'Hậu Lộc': [
        'Hậu Lộc',
        'Cầu Lộc',
        'Châu Lộc',
        'Đa Lộc',
        'Đồng Lộc',
        'Hòa Lộc',
        'Hưng Lộc',
        'Hải Lộc',
        'Mỹ Lộc',
        'Minh Lộc',
        'Phú Lộc',
        'Quang Lộc',
        'Triệu Lộc',
        'Tuy Lộc',
      ],
      'Hoằng Hóa': [
        'Bút Sơn',
        'Hoằng Cát',
        'Hoằng Đạo',
        'Hoằng Đồng',
        'Hoằng Hà',
        'Hoằng Hợp',
        'Hoằng Lưu',
        'Hoằng Phụ',
        'Hoằng Phong',
        'Hoằng Thành',
        'Hoằng Thịnh',
        'Hoằng Tiến',
        'Hoằng Trinh',
        'Hoằng Xuân',
      ],
      'Nga Sơn': [
        'Nga Bạch',
        'Nga Điền',
        'Nga Hải',
        'Nga Liên',
        'Nga Lĩnh',
        'Nga Thạch',
        'Nga Thành',
        'Nga Thắng',
        'Nga Tiến',
        'Nga Văn',
        'Nga Yên',
      ],
      'Như Xuân': [
        'Yên Cát',
        'Bãi Trành',
        'Cát Vân',
        'Cát Tân',
        'Hóa Quỳ',
        'Thanh Lâm',
        'Thanh Phong',
        'Thanh Xuân',
        'Xuân Quỳ',
        'Yên Lễ',
      ],
      'Quan Hóa': [
        'Hồi Xuân',
        'Nam Động',
        'Nam Xuân',
        'Phú Thanh',
        'Phú Xuân',
        'Thanh Xuân',
        'Thành Sơn',
        'Xuân Phú',
      ],
      'Quan Sơn': [
        'Sơn Lư',
        'Sơn Thủy',
        'Sơn Hà',
        'Sơn Điện',
        'Mường Mìn',
      ],
      'Thọ Xuân': [
        'Thọ Xuân',
        'Bắc Lương',
        'Nam Giang',
        'Thọ Hải',
        'Thọ Lâm',
        'Thọ Xương',
        'Xuân Lai',
        'Xuân Tân',
      ],
      'Tĩnh Gia': [
        'Tĩnh Gia',
        'Hải Bình',
        'Hải Thanh',
        'Hải Thượng',
        'Hải Yến',
        'Thanh Sơn',
      ],
      'Triệu Sơn': [
        'Triệu Sơn',
        'An Nông',
        'Dân Lực',
        'Dân Quyền',
        'Thọ Cường',
        'Thọ Tiến',
        'Thọ Tân',
      ],
      'Yên Định': [
        'Quán Lào',
        'Định Bình',
        'Định Công',
        'Định Long',
        'Định Tường',
        'Định Hưng',
        'Yên Ninh',
      ],
      'Như Thanh': [
        'Bến Sung',
        'Cán Khê',
        'Hải Vân',
        'Xuân Du',
        'Xuân Khang',
      ],
    },
  },
  'Quảng Ninh': {
    districts: {
      'Hạ Long': [
        'Hùng Thắng',
        'Bãi Cháy',
        'Hải Sơn',
        'Giếng Đồn',
        'Hoành Bồ',
        'Trần Hưng Đạo',
        'Cẩm Trung',
        'Cẩm Thành',
      ],
      'Móng Cái': [
        'Móng Cái',
        'Trà Cổ',
        'Ka Long',
        'Đông Xá',
        'Vạn Ninh',
        'Phường Bình Ngọc',
        'Vạn Thọ',
        'Quốc Tuấn',
      ],
      'Cẩm Phả': [
        'Cẩm Phả',
        'Cẩm Đông',
        'Cẩm Sơn',
        'Cẩm Hải',
        'Cẩm Tây',
        'Cẩm Thịnh',
        'Cẩm Trung',
      ],
      'Uông Bí': [
        'Uông Bí',
        'Trưng Vương',
        'Quang Trung',
        'Nam Khê',
        'Phương Đông',
        'Thành Công',
        'Kỳ Thượng',
        'Hòa Bình',
      ],
      'Đông Triều': [
        'Đông Triều',
        'An Sinh',
        'Ngọc Lâm',
        'Yên Thọ',
        'Thủy An',
        'Bình Khê',
        'Hưng Đạo',
      ],
      'Ba Chẽ': [
        'Ba Chẽ',
        'Thanh Sơn',
        'Đông Ngũ',
        'Lương Mông',
        'Tân Lập',
        'Đông Hưng',
      ],
      'Vân Đồn': [
        'Cái Bầu',
        'Vân Đồn',
        'Đoàn Kết',
        'Hạ Long',
        'Đại Dực',
        'Văn Hạ',
      ],
      'Tiên Yên': [
        'Tiên Yên',
        'Yên Than',
        'Phúc Thắng',
        'Châu Hòa',
        'Tiên Lãng',
        'Đông Hải',
        'Vân Tiên',
      ],
      'Quảng Yên': [
        'Quảng Yên',
        'Tiền An',
        'Tân An',
        'Sông Khoai',
        'Yên Hưng',
        'Bắc Sơn',
      ],
      'Đầm Hà': ['Đầm Hà', 'Đầm Hà', 'Quảng Lợi', 'Đồng Rui', 'Tân Bình'],
      'Hải Hà': ['Hải Hà', 'Quảng Điền', 'Đồng Tâm', 'Quảng Phong'],
      'Bình Liêu': ['Bình Liêu', 'Vô Ngại', 'Húc Động', 'Lục Hồn', 'Đồng Tâm'],
      'Hoành Bồ': [
        'Hoành Bồ',
        'Văn Hòa',
        'Thống Nhất',
        'Bãi Cháy',
        'Thống Nhất',
      ],
    },
  },
  'Bắc Ninh': {
    districts: {
      'Bắc Ninh': [
        'Đại Phúc',
        'Kinh Bắc',
        'Ninh Xá',
        'Tiền An',
        'Võ Cường',
        'Hạp Lĩnh',
        'Khúc Xuyên',
        'Phong Khê',
      ],
      'Từ Sơn': [
        'Châu Khê',
        'Đình Bảng',
        'Phù Chẩn',
        'Tương Giang',
        'Tam Sơn',
        'Phù Lưu',
        'Hương Mạc',
      ],
      'Yên Phong': [
        'Yên Trung',
        'Yên Phong',
        'Đông Thọ',
        'Đông Tiến',
        'Trung Nghĩa',
        'Phong Khê',
        'Tam Giang',
        'Thụy Hòa',
      ],
      'Gia Bình': [
        'Nhân Thắng',
        'Đại Bái',
        'Cổ Bi',
        'Bình Dương',
        'Lai Hạ',
        'Vạn Ninh',
        'Quế Tân',
      ],
      'Quế Võ': [
        'Phượng Mao',
        'Cao Đức',
        'Việt Hùng',
        'Yên Phụ',
        'Phù Lãng',
        'Bồng Lai',
        'Đào Viên',
      ],
      'Thuận Thành': [
        'Nguyệt Đức',
        'Mão Điền',
        'An Bình',
        'Hà Mãn',
        'Gia Đông',
        'Đại Đồng Thành',
        'Trí Quả',
        'Song Hồ',
      ],
      'Lương Tài': [
        'Phú Lương',
        'Mỹ Hương',
        'Quảng Phú',
        'Trung Kênh',
        'Tân Lãng',
        'Lương Tài',
        'Bình Định',
      ],
      'Tiên Du': [
        'Hoàn Sơn',
        'Liên Bão',
        'Phật Tích',
        'Tri Phương',
        'Việt Đoàn',
        'Phú Lâm',
        'Cảnh Hưng',
        'Đại Đồng',
      ],
    },
  },
  'Hà Giang': {
    districts: {
      'Thành phố Hà Giang': [
        'Minh Khai',
        'Ngọc Hà',
        'Nguyễn Trãi',
        'Quang Trung',
        'Trần Phú',
        'Yên Biên',
      ],
      'Đồng Văn': [
        'Đồng Văn',
        'Lũng Cú',
        'Lũng Táo',
        'Lũng Phìn',
        'Ma Lé',
        'Phố Cáo',
        'Phố Là',
        'Sà Phìn',
        'Sính Lủng',
        'Tả Lủng',
        'Thài Phìn Tủng',
        'Vần Chải',
      ],
      'Mèo Vạc': [
        'Mèo Vạc',
        'Cán Chu Phìn',
        'Giàng Chu Phìn',
        'Khâu Vai',
        'Lũng Chinh',
        'Nậm Ban',
        'Nậm Trà',
        'Pả Vi',
        'Pải Lủng',
        'Sơn Vĩ',
        'Sủng Máng',
        'Sủng Trà',
        'Tả Lủng',
      ],
      'Yên Minh': [
        'Yên Minh',
        'Bạch Đích',
        'Đông Minh',
        'Du Già',
        'Du Tiến',
        'Hữu Vinh',
        'Lao Và Chải',
        'Lũng Hồ',
        'Lũng Thầu',
        'Mậu Duệ',
        'Mậu Long',
        'Na Khê',
        'Ngam La',
        'Sủng Thài',
      ],
      'Quản Bạ': [
        'Tam Sơn',
        'Bát Đại Sơn',
        'Cán Tỷ',
        'Cao Mã Pờ',
        'Đông Hà',
        'Lùng Tám',
        'Nghĩa Thuận',
        'Quản Bạ',
        'Quyết Tiến',
        'Tả Ván',
        'Thái An',
      ],
      'Vị Xuyên': [
        'Vị Xuyên',
        'Bạch Ngọc',
        'Cao Bồ',
        'Đạo Đức',
        'Kim Linh',
        'Lao Chải',
        'Linh Hồ',
        'Minh Tân',
        'Ngọc Linh',
        'Phú Linh',
        'Phương Tiến',
        'Phương Độ',
        'Phương Thiện',
        'Quảng Ngần',
        'Thanh Đức',
        'Thuận Hòa',
        'Thượng Sơn',
        'Tùng Bá',
        'Việt Lâm',
        'Yên Định',
      ],
      'Bắc Mê': [
        'Yên Phú',
        'Đường Âm',
        'Đường Hồng',
        'Giáp Trung',
        'Lạc Nông',
        'Minh Ngọc',
        'Minh Sơn',
        'Phú Nam',
        'Yên Cường',
        'Yên Phong',
      ],
      'Hoàng Su Phì': [
        'Vinh Quang',
        'Bản Díu',
        'Bản Luốc',
        'Bản Máy',
        'Bản Nhùng',
        'Chiến Phố',
        'Đản Ván',
        'Hồ Thầu',
        'Nam Sơn',
        'Nậm Dịch',
        'Nậm Khòa',
        'Nậm Ty',
        'Pố Lồ',
        'Sán Xả Hồ',
        'Tả Sử Choóng',
        'Tân Tiến',
        'Thàng Tín',
        'Thèn Chu Phìn',
      ],
      'Xín Mần': [
        'Cốc Pài',
        'Chế Là',
        'Chí Cà',
        'Khuôn Lùng',
        'Nà Chì',
        'Nàn Ma',
        'Nàn Xỉn',
        'Ngán Chiên',
        'Pà Vầy Sủ',
        'Quảng Nguyên',
        'Tả Nhìu',
        'Thèn Phàng',
        'Thu Tà',
        'Trung Thịnh',
        'Xín Mần',
      ],
      'Bắc Quang': [
        'Việt Quang',
        'Bằng Hành',
        'Đồng Tâm',
        'Đông Thành',
        'Đông Viên',
        'Đồng Yên',
        'Hùng An',
        'Hữu Sản',
        'Kim Ngọc',
        'Liên Hiệp',
        'Quang Minh',
        'Tân Lập',
        'Tân Quang',
        'Thượng Bình',
        'Tiên Kiều',
        'Vĩnh Hảo',
        'Vĩnh Phúc',
        'Vô Điếm',
      ],
      'Quang Bình': [
        'Yên Bình',
        'Bản Rịa',
        'Bằng Lang',
        'Tân Bắc',
        'Tân Nam',
        'Tân Trịnh',
        'Tiên Nguyên',
        'Tiên Yên',
        'Vĩ Thượng',
        'Xuân Giang',
        'Xuân Minh',
      ],
    },
  },
  'Thái Nguyên': {
    districts: {
      'Thành phố Thái Nguyên': [
        'Cam Giá',
        'Đồng Quang',
        'Gia Sàng',
        'Hoàng Văn Thụ',
        'Phan Đình Phùng',
        'Phú Xá',
        'Quang Trung',
        'Tân Lập',
        'Tân Long',
        'Tân Thành',
        'Thịnh Đán',
        'Trưng Vương',
        'Túc Duyên',
        'Hương Sơn',
        'Linh Sơn',
        'Quyết Thắng',
        'Phúc Hà',
        'Phúc Xuân',
        'Phúc Trìu',
        'Thịnh Đức',
      ],
      'Thành phố Sông Công': [
        'Bách Quang',
        'Cải Đan',
        'Lương Châu',
        'Mỏ Chè',
        'Phố Cò',
        'Thắng Lợi',
        'Tân Quang',
        'Vĩnh Sơn',
        'Bình Sơn',
        'Bá Xuyên',
      ],
      'Thị xã Phổ Yên': [
        'Ba Hàng',
        'Bãi Bông',
        'Bãi Bông',
        'Đắc Sơn',
        'Đồng Tiến',
        'Hồng Tiến',
        'Nam Tiến',
        'Phúc Thuận',
        'Tân Hương',
        'Tân Phú',
        'Thuận Thành',
        'Tiên Phong',
        'Trung Thành',
      ],
      'Đại Từ': [
        'Bản Ngoại',
        'Bình Thuận',
        'Cát Nê',
        'Cù Vân',
        'Đại Từ',
        'Hà Thượng',
        'Hoàng Nông',
        'Khôi Kỳ',
        'Ký Phú',
        'La Bằng',
        'Lục Ba',
        'Minh Tiến',
        'Mỹ Yên',
        'Na Mao',
        'Phú Cường',
        'Phú Lạc',
        'Phú Thịnh',
        'Phục Linh',
        'Quân Chu',
        'Tân Linh',
        'Tân Thái',
        'Vạn Thọ',
        'Văn Yên',
        'Yên Lãng',
        'Yên Ninh',
      ],
      'Định Hóa': [
        'Chợ Chu',
        'Bảo Cường',
        'Bảo Linh',
        'Bộc Nhiêu',
        'Bình Thành',
        'Bình Yên',
        'Định Biên',
        'Điềm Mặc',
        'Đồng Thịnh',
        'Kim Phượng',
        'Lam Vỹ',
        'Lương Thịnh',
        'Lương Thành',
        'Lương Sơn',
        'Phúc Chu',
        'Phú Đình',
        'Phú Tiến',
        'Phượng Tiến',
        'Quy Kỳ',
        'Sơn Phú',
        'Tân Dương',
        'Tân Thịnh',
        'Thanh Định',
        'Trung Hội',
        'Trung Lương',
      ],
      'Đồng Hỷ': [
        'Chùa Hang',
        'Cây Thị',
        'Hóa Thượng',
        'Hóa Trung',
        'Hóa Thượng',
        'Khe Mo',
        'Linh Sơn',
        'Minh Lập',
        'Nam Hòa',
        'Quang Sơn',
        'Tân Long',
        'Tân Lợi',
        'Tân Thái',
        'Thượng Nung',
        'Vô Tranh',
      ],
      'Phú Bình': [
        'Hương Sơn',
        'Đào Xá',
        'Định Hóa',
        'Đồng Liên',
        'Đồng Tiến',
        'Hòa Bình',
        'Kha Sơn',
        'Lương Phú',
        'Nga My',
        'Nhã Lộng',
        'Tân Đức',
        'Tân Hòa',
        'Tân Kim',
        'Tân Thành',
        'Thanh Ninh',
        'Xuân Phương',
      ],
      'Phú Lương': [
        'Đồng Bẩm',
        'Cổ Lũng',
        'Hợp Thành',
        'Na Mao',
        'Phủ Lý',
        'Quân Chu',
        'Sơn Cẩm',
        'Tân Thành',
        'Thượng Đình',
        'Tràng Xá',
        'Yên Ninh',
      ],
      'Võ Nhai': [
        'Đình Cả',
        'Bình Long',
        'Cúc Đường',
        'La Hiên',
        'Lâu Thượng',
        'Liên Minh',
        'Nghinh Tường',
        'Phú Thượng',
        'Phú Xuyên',
        'Tràng Xá',
        'Thượng Nung',
      ],
    },
  },
  'Nam Định': {
    districts: {
      'Nam Định': ['Nam Định', 'Lộc Hạ', 'Hải Hưng'],
      'Mỹ Lộc': ['Mỹ Lộc', 'Mỹ Trung', 'Mỹ Hưng'],
      'Nam Trực': ['Nam Trực', 'Nam Giang', 'Nam Định'],
      'Trực Ninh': ['Trực Ninh', 'Trực Đạo', 'Trực Mỹ'],
      'Vụ Bản': ['Vụ Bản', 'Vụ Bản Thượng', 'Vụ Bản Hạ'],
      'Ý Yên': ['Ý Yên', 'Yên Tiến', 'Yên Bình'],
    },
  },
  'Ninh Bình': {
    districts: {
      'Ninh Bình': ['Ninh Bình', 'Ninh Phúc', 'Ninh Hải'],
      'Tam Điệp': ['Tam Điệp', 'Yên Thịnh', 'Quang Sơn'],
      'Yên Khánh': ['Yên Khánh', 'Yên Thượng', 'Yên Tiến'],
      'Gia Viễn': ['Gia Viễn', 'Gia Sinh', 'Gia Hưng'],
      'Hoa Lư': ['Hoa Lư', 'Ninh Hải', 'Trường Yên'],
    },
  },
  'Hòa Bình': {
    districts: {
      'Hòa Bình': ['Hòa Bình', 'Phương Lâm', 'Tân Hoà'],
      'Cao Phong': ['Cao Phong', 'Đông Phong', 'Tân Phong'],
      'Lạc Sơn': ['Lạc Sơn', 'Vũ Bình', 'Vũ Hòa'],
      'Lương Sơn': ['Lương Sơn', 'Cư Yên', 'Lâm Sơn'],
      'Mai Châu': ['Mai Châu', 'Thung Nai', 'Tân Sơn'],
      'Kim Bôi': ['Kim Bôi', 'Kim Sơn', 'Kim Thượng'],
    },
  },
  'Thái Bình': {
    districts: {
      'Thái Bình': ['Thái Bình', 'Trần Lãm', 'Lê Chân'],
      'Hưng Hà': ['Hưng Hà', 'Đức Lập', 'Bình Minh'],
      'Quỳnh Phụ': ['Quỳnh Phụ', 'Quỳnh Hưng', 'Quỳnh Nguyên'],
      'Tiền Hải': ['Tiền Hải', 'Tiền Hải', 'Tiền Hưng'],
      'Vũ Thư': ['Vũ Thư', 'Vũ Thượng', 'Vũ Tiến'],
    },
  },
  'Hưng Yên': {
    districts: {
      'Hưng Yên': ['Hưng Yên', 'Hưng Đạo', 'Quang Trung'],
      'Mỹ Hào': ['Mỹ Hào', 'Bần Yên Nhân', 'Nhân Hòa'],
      'Khoái Châu': ['Khoái Châu', 'Nhuế Dương', 'Nhuế Bắc'],
      'Yên Mỹ': ['Yên Mỹ', 'Yên Lập', 'Yên Thọ'],
      'Tiên Lữ': ['Tiên Lữ', 'Tiên Hưng', 'Tiên Tiến'],
    },
  },
  'Ninh Thuận': {
    districts: {
      'Ninh Thuận': ['Ninh Thuận', 'Phước Dinh', 'Vĩnh Hải'],
      'Bác Ái': ['Bác Ái', 'Phước Chiến', 'Cát Tiến'],
      'Ninh Sơn': ['Ninh Sơn', 'Hữu Định', 'Đinh An'],
      'Thuận Bắc': ['Thuận Bắc', 'Bình Sơn', 'Quảng Phước'],
    },
  },
  'Vĩnh Phúc': {
    districts: {
      'Vĩnh Yên': ['Đống Đa', 'Liên Bảo', 'Ngô Quyền'],
      'Phúc Yên': ['Trưng Nhị', 'Hùng Vương', 'Phúc Thắng'],
      'Lập Thạch': ['Đình Chu', 'Triệu Đề', 'Tử Du'],
      'Sông Lô': ['Đồng Quế', 'Nhân Đạo', 'Yên Thạch'],
      'Tam Đảo': ['Hợp Châu', 'Đại Đình', 'Tam Quan'],
    },
  },
  'Điện Biên': {
    districts: {
      'Thành phố Điện Biên Phủ': ['Mường Thanh', 'Tân Thanh', 'Thanh Trường'],
      'Mường Ảng': ['Mường Ảng', 'Ẳng Tở', 'Ẳng Nưa'],
      'Điện Biên Đông': ['Keo Lôm', 'Luân Giới', 'Na Son'],
      'Tủa Chùa': ['Sín Chải', 'Mường Báng', 'Tả Phìn'],
      'Mường Nhé': ['Chung Chải', 'Huổi Lếch', 'Nậm Kè'],
    },
  },
  'Lào Cai': {
    districts: {
      'Thành phố Lào Cai': ['Bắc Cường', 'Duyên Hải', 'Kim Tân'],
      'Bát Xát': ['A Lù', 'A Mú Sung', 'Bản Qua'],
      'Bắc Hà': ['Bắc Hà', 'Bản Liền', 'Bảo Nhai'],
      'Mường Khương': ['Mường Khương', 'Lũng Pô', 'Bản Xen'],
      'Sa Pa': ['Sa Pả', 'Trung Chải', 'Cầu Mây'],
    },
  },
  'Yên Bái': {
    districts: {
      'Thành phố Yên Bái': ['Hồng Hà', 'Minh Tân', 'Nguyễn Phúc'],
      'Văn Yên': ['Yên Bình', 'Yên Hợp', 'An Bình'],
      'Trạm Tấu': ['Bản Công', 'Tú Lệ', 'Hát Lừu'],
      'Văn Chấn': ['Nậm Mười', 'Sơn Thịnh', 'Trạm Tấu'],
      'Mù Cang Chải': ['La Pán Tẩn', 'Nậm Khắt', 'Dế Su Phình'],
    },
  },
  'Tuyên Quang': {
    districts: {
      'Thành phố Tuyên Quang': ['An Tường', 'Hưng Thành', 'Mỹ Lâm'],
      'Chiêm Hóa': ['Trung Hà', 'Yên Nguyên', 'Phúc Sơn'],
      'Hàm Yên': ['Hàm Yên', 'Yên Lâm', 'Tân Yên'],
      'Na Hang': ['Na Hang', 'Yên Hoa', 'Thanh Tương'],
      'Sơn Dương': ['Sơn Nam', 'Cấp Tiến', 'Tân Thanh'],
    },
  },
};

export default addressData;
