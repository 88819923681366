import React, { useState } from 'react';
import EditCustomer from './EditCustomer';

const DetailCustomer = ({ customer, onCancel }) => {
  const [showEditPopup, setShowEditPopup] = useState(false);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '500px',
      marginTop: '120px',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    formColumn: {
      width: '100%',
    },
    fieldContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    fieldText: {
      flex: 1,
      marginLeft: '20px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
    },
    button: {
      backgroundColor: '#072f4d',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      padding: '10px 20px',
      cursor: 'pointer',
      margin: '0 10px',
    },
    label: {
      fontWeight: 'bold',
      color: '#072f4d',
    },
  };

  const handleEditClick = () => {
    setShowEditPopup(true);
  };

  const handleEditCancel = () => {
    setShowEditPopup(false);
    onCancel();
  };

  if (showEditPopup) {
    return <EditCustomer customer={customer} onCancel={handleEditCancel} />;
  }

  return (
    <form>
      <div style={styles.container}>
        <h2 style={{ textAlign: 'center' }}>Chi tiết khách hàng</h2>
        <div style={styles.formColumn}>
          <div style={styles.fieldContainer}>
            <div style={styles.fieldText}>
              <p style={styles.label}>Tên khách hàng</p>
            </div>
            <div style={styles.fieldText}>
              <p>{customer.customerName}</p>
            </div>
          </div>
          <div style={styles.fieldContainer}>
            <div style={styles.fieldText}>
              <p style={styles.label}>Địa chỉ</p>
            </div>
            <div style={styles.fieldText}>
              <p>{customer.address || 'N/A'}</p>
            </div>
          </div>
          <div style={styles.fieldContainer}>
            <div style={styles.fieldText}>
              <p style={styles.label}>Số điện thoại</p>
            </div>
            <div style={styles.fieldText}>
              <p>{customer.phoneNumber}</p>
            </div>
          </div>
          <div style={styles.fieldContainer}>
            <div style={styles.fieldText}>
              <p style={styles.label}>Năm sinh</p>
            </div>
            <div style={styles.fieldText}>
              <p>{customer.yob || 'N/A'}</p>
            </div>
          </div>
          <div style={styles.fieldContainer}>
            <div style={styles.fieldText}>
              <p style={styles.label}>Giới tính</p>
            </div>
            <div style={styles.fieldText}>
              <p>{customer.gender === 'MALE' ? 'Nam' : 'Nữ' || 'N/A'}</p>
            </div>
          </div>
        </div>
        <div style={styles.buttons}>
          <button type='button' style={styles.button} onClick={handleEditClick}>
            Cập nhật
          </button>
          <button type='button' style={styles.button} onClick={onCancel}>
            Hủy
          </button>
        </div>
      </div>
    </form>
  );
};

export default DetailCustomer;
