import React, { useEffect, useState } from 'react';
import api from "../../api/axiosConfig";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from 'react-toastify';
import { Button, CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import Select from 'react-select';

const PopupEditUser = ({ onPopupClose, user }) => {

    const [activeTab, setActiveTab] = useState('personal');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [gender, setGender] = useState(user ? user.gender : '');
    const [fullName, setFullName] = useState(user ? user.fullName : '');
    const [phone, setPhone] = useState(user ? user.phone : '');
    const [cic, setCic] = useState(user ? user.cic : '');
    const [email, setEmail] = useState(user ? user.email : '');
    const [address, setAddress] = useState(user ? user.address : '');
    const [note, setNote] = useState(user ? user.note : '');
    const [username, setUsername] = useState(user ? user.username : '');
    const [avatar, setAvatar] = useState(user ? user.avatar : '');
    const [newAvatar, setNewAvatar] = useState('');
    const [downloading, setDownloading] = useState(false);
    const [role, setRole] = useState(
        user
            ? user.rolesDto.map(role => ({
                value: role.name,
                label: role.name === 'ROLE_PRODUCT_OWNER' ? 'Chủ cửa hàng' :
                    role.name === 'ROLE_STOCK' ? 'Nhân viên kho' :
                        role.name === 'ROLE_SALE' ? 'Nhân viên bán hàng' :
                            role.name
            }))
            : []
    );
    const [status, setStatus] = useState(user ? user.status : '');

    useEffect(() => {
        let objectUrl;
        if (avatar && avatar instanceof Blob) {
            objectUrl = URL.createObjectURL(avatar);
            setNewAvatar(objectUrl);
        }

        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
            }
        };
    }, [avatar]);

    useEffect(() => {
        console.log(user);
        if (user) {
            if (user.dob) {
                const formattedDate = format(new Date(user.dob * 1000), 'yyyy-MM-dd');
                setDateOfBirth(formattedDate);
            }
            setFullName(user.fullName);
            setGender(user.gender);
            setEmail(user.email);
            setPhone(user.phone);
            setAddress(user.address);
            setCic(user.cic);
            setNote(user.note);
            setUsername(user.username);
            setRole(
                user.rolesDto.map(role => ({
                    value: role.name,
                    label: role.name === 'ROLE_PRODUCT_OWNER' ? 'Chủ cửa hàng' :
                        role.name === 'ROLE_STOCK' ? 'Nhân viên kho' :
                            role.name === 'ROLE_SALE' ? 'Nhân viên bán hàng' :
                                role.name
                }))
            );
            setStatus(user.status);
        }
    }, [user]);

    const handleDateChange = (event) => {
        setDateOfBirth(event.target.value);
    };

    const handleRoleChange = (selectedOptions) => {
        setRole(selectedOptions || []);
    };

    const handleEdit = async (e) => {
        setDownloading(true);
        const formattedDob = dateOfBirth ? new Date(dateOfBirth).toISOString() : '';
        const u = sessionStorage.getItem('username');
        const rolesDto = role.map(roleValue => ({ name: roleValue.value }));
        const userData = {
            id: user ? user.id : null,
            fullName: fullName,
            gender: gender,
            dob: formattedDob,
            phone: phone,
            cic: cic,
            email: email,
            address: address,
            note: note,
            username: username,
            status: status,
            rolesDto: rolesDto,
            lastModifiedBy: u ? u : "anonymousUser"
        };
        const token = sessionStorage.getItem('token');
        e.preventDefault();
        if (userData.id === null) {
            toast.error("Thiếu ID người dùng.");
            return;
        }
        try {
            e.preventDefault();
            const formData = new FormData();
            formData.append('userDto', new Blob([JSON.stringify(userData)], { type: 'application/json' }));
            const maxFileSize = 2 * 1024 * 1024;
            if (avatar && avatar !== '') {
                if (avatar.size > maxFileSize) {
                    toast.error("Kích thước file phải bé hơn 2MB");
                    return;
                }
                formData.append('file', avatar);
            }
            const { data } = await api.put(`api/user/update-user`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.success(data);
            setTimeout(() => {
                onPopupClose();
            }, 1000);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error('Không thể cập nhật người dùng, vui lòng thử lại.');
            }
        } finally {
            setDownloading(false);
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '97%',
            padding: '0',
            border: '1px solid #ccc',
            borderRadius: '2px',
            fontSize: '14px',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#f3f3f3',
            color: '#333',
            borderRadius: '3px',
            padding: '3px',
            margin: '2px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#333',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#333',
            cursor: 'pointer',
        }),
    };

    const options = [
        { value: 'ROLE_PRODUCT_OWNER', label: 'Chủ cửa hàng' },
        { value: 'ROLE_SALE', label: 'Nhân viên bán hàng' },
        { value: 'ROLE_STOCK', label: 'Nhân viên kho' }
    ];

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setAvatar(file);
    };

    return (
        <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            textAlign: 'start',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div className="information-container">
                <h2>Chỉnh Sửa Thông Tin Nhân Viên {user.fullName}</h2>
                <div className="tabs">
                    <button
                        type='button'
                        className={`tab ${activeTab === 'personal' ? 'active' : ''}`}
                        onClick={() => handleTabChange('personal')}
                    >
                        Thông tin nhân viên
                    </button>
                    <button
                        type='button'
                        className={`tab ${activeTab === 'dashboard' ? 'active' : ''}`}
                        onClick={() => handleTabChange('dashboard')}
                    >
                        Thông tin đăng nhập
                    </button>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'start', marginTop: '60px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            {user && (newAvatar || user.avatar) ? (
                                <div style={{
                                    display: "inline-block", width: "150px", height: "150px", backgroundImage: `url(${newAvatar || user.avatar})`,
                                    backgroundSize: 'cover', backgroundPosition: 'center', border: 'solid 2px #709dc1', borderRadius: '50%'
                                }}></div>
                            ) : (
                                <div style={{ width: "150px", height: "150px", border: 'solid 2px #709dc1', borderRadius: '50%', backgroundColor: "transparent", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <FontAwesomeIcon icon={faUser} size="7x" style={{ color: "#709dc1" }} />
                                </div>
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="fileInput"
                                onChange={(e) => handleImageChange(e)}
                            />
                            <div style={{ margin: '20px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button variant="contained" className='btn' onClick={() => document.getElementById('fileInput').click()}>
                                    {user && (newAvatar || user.avatar) ? 'Thay ảnh' : 'Chọn ảnh'}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="tabs-container" style={{ flex: '3' }}>
                        <div >
                            {activeTab === 'personal' && (
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <div style={{ flex: '1' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><span style={{ color: 'red' }}>*</span><strong>Tên nhân viên</strong></span>
                                            </div>
                                            <div className='fieldInput' style={{ flex: '1' }}>
                                                <input type='text' value={fullName} onChange={(e) => setFullName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><span style={{ color: 'red' }}>*</span><strong>Giới tính</strong></span>
                                            </div>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <select style={{ width: '104%' }} value={gender} onChange={(e) => setGender(e.target.value)}>
                                                    <option value="" disabled>Chọn giới tính</option>
                                                    <option value="MALE">Nam</option>
                                                    <option value="FEMALE">Nữ</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><span style={{ color: 'red' }}>*</span><strong>Ngày sinh</strong></span>
                                            </div>
                                            <div className='fieldInput' style={{ flex: '1' }}>
                                                <input type='date' value={dateOfBirth} onChange={handleDateChange} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><span style={{ color: 'red' }}>*</span><strong>Số điện thoại</strong></span>
                                            </div>
                                            <div className='fieldInput' style={{ flex: '1' }}>
                                                <input type='number' value={phone} onChange={(e) => setPhone(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ flex: '1', marginRight: '30px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><span style={{ color: 'red' }}>*</span><strong>CCCD</strong></span>
                                            </div>
                                            <div className='fieldInput' style={{ flex: '1' }}>
                                                <input type='number' value={cic} onChange={(e) => setCic(e.target.value)} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><span style={{ color: 'red' }}>*</span><strong>Email</strong></span>
                                            </div>
                                            <div className='fieldInput' style={{ flex: '1' }}>
                                                <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><span style={{ color: 'red' }}>*</span><strong>Địa chỉ</strong></span>
                                            </div>
                                            <div className='fieldInput' style={{ flex: '1' }}>
                                                <input type='text' value={address} onChange={(e) => setAddress(e.target.value)} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><strong>Ghi chú</strong></span>
                                            </div>
                                            <div className='fieldInput' style={{ flex: '1' }}>
                                                <input type='text' value={note} onChange={(e) => setNote(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )}
                            {activeTab === 'dashboard' && (
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <div style={{ flex: '2' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><span style={{ color: 'red' }}>*</span><strong>Tên đăng nhập</strong></span>
                                            </div>
                                            <div className='fieldInput' style={{ flex: '1.8' }}>
                                                <input type='text' value={username} onChange={(e) => setUsername(e.target.value)} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><span style={{ color: 'red' }}>*</span><strong>Vai trò</strong></span>
                                            </div>
                                            <div className='fieldInput' style={{ flex: '1.8' }}>
                                                <Select
                                                    value={role}
                                                    onChange={handleRoleChange}
                                                    options={options}
                                                    isMulti
                                                    required
                                                    styles={customStyles}
                                                    placeholder="Chọn vai trò"
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fieldText' style={{ flex: '1' }}>
                                                <span><span style={{ color: 'red' }}>*</span><strong>Trạng thái</strong></span>
                                            </div>
                                            <div className='fieldInput' style={{ flex: '1.8' }}>
                                                <select style={{ width: '97%' }} value={status} onChange={(e) => setStatus(e.target.value)}>
                                                    <option value="ACTIVE">Hoạt động</option>
                                                    <option value="INACTIVE">Ngưng hoạt động</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ flex: '1' }}>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div style={{ margin: '20px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button variant="contained" className='btn' onClick={handleEdit}>
                                Lưu
                            </Button>
                            <Button variant="contained" className='btn' onClick={onPopupClose}>
                                Hủy
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
        </div>
    );
};

export default PopupEditUser;