import React, { useEffect, useState } from 'react';
import '../../styles/Import.css';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button, LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate, useParams, Link } from 'react-router-dom';
import axiosConfig from '../../api/axiosConfig';

const SalePrescriptionDetail = () => {
    const { saleId } = useParams();
    const [item, setItem] = useState(null);
    const navigate = useNavigate();

    const getSaleReceipt = async () => {
        const token = sessionStorage.getItem('token');
        try {
            const { data } = await axiosConfig.get(`/api/sale/get-sale-order?saleOrderId=${saleId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(data);
            setItem(data);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    useEffect(() => { getSaleReceipt() }, []);

    const handleEdit = () => {
        navigate(`/sale/prescription/edit/${saleId}`);
    }

    return (
        <DefaultTemplate>
            <div className="receipt-container">
                <div className="header">
                    <h3 className='title-name' style={{ marginBottom: '0', marginLeft: '10px' }}>
                        <Link to="/sale" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Bán Hàng
                        </Link>
                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronRight} />
                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faChevronRight} />
                        Chi tiết hóa đơn {item?.invoiceNumber}
                    </h3>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="import-list" style={{ flex: '4', textAlign: 'start', margin: '0 10px', borderRadius: '10px', overflow: 'auto' }}>
                        <table style={{ boxShadow: 'none' }}>
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Mã - Tên sản phẩm</th>
                                    <th>Đơn vị</th>
                                    <th>Đơn giá</th>
                                    <th>Số lượng</th>
                                    <th>Chiết khấu (%)</th>
                                    <th>Thành tiền</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {item?.saleOrderItems ? item.saleOrderItems.map((item, index) => (
                                    <tr key={index + 1} style={{ textAlign: 'center' }}>
                                        <td><div style={{ marginBottom: '15px' }}>{index + 1}</div></td>
                                        <td style={{ textAlign: 'left' }}>
                                            <div>{item.product?.productCode} - {item.product?.productName}</div>
                                            <div>Liều lượng: {item.dosage || 'N/A'}</div>
                                        </td>
                                        <td><div style={{ marginBottom: '15px' }}>{item.unit}</div></td>
                                        <td><div style={{ marginBottom: '15px' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.unitPrice)}</div></td>
                                        <td><div style={{ marginBottom: '15px' }}>{item.quantity}</div></td>
                                        <td><div style={{ marginBottom: '15px' }}>{item.discount}</div></td>
                                        <td><div style={{ marginBottom: '15px' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.totalAmount)}</div></td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan={8}>
                                            <LinearProgress color='inherit' />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ backgroundColor: 'white', marginRight: '5px', textAlign: 'start', padding: '15px 25px', borderRadius: '10px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)', flex: '1' }}>
                        <h4 className='title-name' style={{ color: '#022742', margin: '5px 0' }}>Thông tin hóa đơn</h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Ngày tạo hóa đơn:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{item?.saleDate ? new Date(item.saleDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '../../....'}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Người tạo hóa đơn:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{item?.createdBy}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tên khách hàng:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{item?.customer.customerName}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>SĐT khách hàng:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{item?.customer.phoneNumber}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tên bác sĩ:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{item?.doctor.fullName}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Chẩn đoán:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{item?.diagnosis || 'N/A'}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng tiền hàng:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.totalAmount + item?.discount)}
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Giảm giá:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.discount)}
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent' style={{ fontWeight: 'bold' }}><span>Tổng tiền phải trả:</span></div>
                            <p style={{ color: '#09446d', fontWeight: 'bold', fontSize: '14px' }}>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.totalAmount)}
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Phương thức thanh toán:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{item?.paymentMethod === 'TRANSFER' ? 'Chuyển khoản' : 'Tiền mặt'}</p>
                        </div>
                        <div style={{ display: 'flex', flex: '1', marginTop: '20px', justifyContent: 'center' }}>
                            {item?.paymentStatus !== 'PAID' &&
                                <Button onClick={handleEdit} variant="contained" className='btn' >
                                    Sửa
                                </Button>
                            }
                            <Button type='btn' variant="contained" className='btn' onClick={() => { navigate('/sale') }}>
                                Quay lại
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultTemplate>
    );
};

export default SalePrescriptionDetail;