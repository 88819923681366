import React, { useEffect, useState } from 'react';
import '../styles/Navbar.css';
import logo from '../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccountCircle } from '@mui/icons-material';
import { faBoxOpen, faDolly, faChartLine, faUsers, faBell, faUser, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Badge, Chip } from '@mui/material';
import api from '../api/axiosConfig';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const [isProductsOpen, setProductsOpen] = useState(false);
  const [isImportExportOpen, setImportExportOpen] = useState(false);
  const [isReportOpen, setReportOpen] = useState(false);
  const [isManagementOpen, setManagementOpen] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const id = sessionStorage.getItem('id');
  const fullName = sessionStorage.getItem('fullName');
  const roles = sessionStorage.getItem('roles');
  const avatar = sessionStorage.getItem('avatar');
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [notificationType, setNotificationType] = useState('unread');
  const navigate = useNavigate();
  const notificationTypes = [
    { type: 'unread', label: 'Chưa đọc' },
    { type: 'all', label: 'Tất cả' },
    { type: 'stockInOut', label: 'Nhập xuất' },
    { type: 'outOfStock', label: 'Hết hàng' },
    { type: 'expired', label: 'Hết hạn' },
  ];

  const handleProductEnter = () => {
    setProductsOpen(true);
  };

  const handleProductLeave = () => {
    setProductsOpen(false);
  };

  const handleImportExportEnter = () => {
    setImportExportOpen(true);
  };

  const handleImportExportLeave = () => {
    setImportExportOpen(false);
  };

  const handleReportEnter = () => {
    setReportOpen(true);
  };

  const handleReportLeave = () => {
    setReportOpen(false);
  };

  const handleManagementEnter = () => {
    setManagementOpen(true);
  };

  const handleManagementLeave = () => {
    setManagementOpen(false);
  };

  const toggleSetting = () => {
    setSettingsOpen(!isSettingsOpen);
  };

  const toggleNotification = () => {
    setNotificationOpen(!isNotificationOpen);
  };

  const handleRedirect = async (redirect, notificationId) => {
    const token = sessionStorage.getItem('token');
    try {
      let url = `/api/notifications/${notificationId}/read`;
      await api.put(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(redirect);
      navigate(redirect);
      getNotifications();
      getUnreadNotifications();
    } catch (error) {
      if (error.status === 404) {
        setNotifications([]);
      } else if (error !== 404 && error.status >= 400 && error.status < 500) {
        toast.error("Lỗi khi tải dữ liệu. Vui lòng thử lại sau!");
      }
    }
  }

  const getNotifications = async (type) => {
    const token = sessionStorage.getItem('token');
    if (id) {
      try {
        let url = `/api/notifications/notification/user?userId=${id}`;
        if (type === 'stockInOut') {
          url += `&notificationType=STOCK_IN_OUT`;
        } else if (type === 'expired') {
          url += `&notificationType=EXPIRED`;
        } else if (type === 'outOfStock') {
          url += `&notificationType=OUT_OF_STOCK`;
        }
        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        setNotifications(data);
      } catch (error) {
        if (error.status === 404) {
          setNotifications([]);
        } else if (error !== 404 && error.status >= 400 && error.status < 500) {
          toast.error("Lỗi khi tải dữ liệu. Vui lòng thử lại sau!");
        }
      }
    }
  }

  const getUnreadNotifications = async () => {
    const token = sessionStorage.getItem('token');
    if (id) {
      try {
        let url = `/api/notifications/notification/user?userId=${id}&isRead=false`;
        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        setUnreadNotifications(data);
      } catch (error) {
        if (error.status === 404) {
          setUnreadNotifications([]);
        } else if (error !== 404 && error.status >= 400 && error.status < 500) {
          toast.error("Lỗi khi tải dữ liệu. Vui lòng thử lại sau!");
        }
      }
    }
  }

  useEffect(() => {
    const handleNotification = (event) => {
      if (event.data.type === 'NOTIFICATION_RECEIVED') {
        getNotifications();
        getUnreadNotifications();
      }
    };
    getUnreadNotifications();
    getNotifications();
    navigator.serviceWorker.addEventListener('message', handleNotification);
    return () => {
      navigator.serviceWorker.removeEventListener('message', handleNotification);
    };
  }, []);

  useEffect(() => {
    getNotifications(notificationType);
  }, [notificationType])

  const timeDifferenceFromNow = (timestamp) => {
    const now = new Date();
    const targetDate = new Date(parseFloat(timestamp) * 1000);
    const diffInSeconds = Math.floor((now - targetDate) / 1000);
    const secondsInMinute = 60;
    const secondsInHour = 60 * secondsInMinute;
    const secondsInDay = 24 * secondsInHour;
    const secondsInMonth = 30 * secondsInDay;
    const secondsInYear = 12 * secondsInMonth;
    if (diffInSeconds < secondsInMinute) {
      return `${diffInSeconds} giây`;
    } else if (diffInSeconds < secondsInHour) {
      const minutes = Math.floor(diffInSeconds / secondsInMinute);
      return `${minutes} phút`;
    } else if (diffInSeconds < secondsInDay) {
      const hours = Math.floor(diffInSeconds / secondsInHour);
      return `${hours} giờ`;
    } else if (diffInSeconds < secondsInMonth) {
      const days = Math.floor(diffInSeconds / secondsInDay);
      return `${days} ngày`;
    } else if (diffInSeconds < secondsInYear) {
      const months = Math.floor(diffInSeconds / secondsInMonth);
      return `${months} tháng`;
    } else {
      const years = Math.floor(diffInSeconds / secondsInYear);
      return `${years} năm`;
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <a href="/" className="navbar-logo">
          <img src={logo} alt="Pharmacy Logo" />
        </a>
        {id && (
          <ul className={`navbar-menu`}>
            <li className="menu-item">
              <button onMouseEnter={handleProductEnter} onMouseLeave={handleProductLeave} className="dropdown-button">
                <FontAwesomeIcon icon={faBoxOpen} />
                <p style={{ margin: '0 10px' }}>Sản Phẩm</p>
                <FontAwesomeIcon icon={isProductsOpen ? faCaretUp : faCaretDown} />
              </button>
              {isProductsOpen && (
                <div onMouseEnter={handleProductEnter} onMouseLeave={handleProductLeave} className="dropdown-content">
                  <a href="/products">Danh sách sản phẩm</a>
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                    <a href="/type">Nhóm sản phẩm</a>)}
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                    <a href="/unit">Đơn vị sản phẩm</a>)}
                </div>
              )}
            </li>
            <li className="menu-item">
              <button onMouseEnter={handleImportExportEnter} onMouseLeave={handleImportExportLeave} className="dropdown-button">
                <FontAwesomeIcon icon={faDolly} />
                <p style={{ margin: '0 10px' }}>Nhập Xuất</p>
                <FontAwesomeIcon icon={isImportExportOpen ? faCaretUp : faCaretDown} />
              </button>
              {isImportExportOpen && (
                <div onMouseEnter={handleImportExportEnter} onMouseLeave={handleImportExportLeave} className="dropdown-content">
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                    <a href="/import/receipt/create">Nhập Kho</a>)}
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                    <a href="/export/create/receipt">Xuất Kho</a>)}
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                    <a href="/sale/create/receipt">Bán Hàng</a>)}
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                    <a href="/return-order/create">Trả Hàng</a>)}
                </div>
              )}
            </li>
            <li className="menu-item">
              <button onMouseEnter={handleReportEnter} onMouseLeave={handleReportLeave} className="dropdown-button">
                <FontAwesomeIcon icon={faChartLine} />
                <p style={{ margin: '0 10px' }}> Báo Cáo </p>
                <FontAwesomeIcon icon={isReportOpen ? faCaretUp : faCaretDown} />
              </button>
              {isReportOpen && (
                <div onMouseEnter={handleReportEnter} onMouseLeave={handleReportLeave} className="dropdown-content">
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                    <a href='/report/sale'>Bán Hàng</a>)}
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                    <a href='/report/customer'>Khách Hàng</a>)}
                  {sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') && (
                    <a href='/report/supplier'>Nhà Cung Cấp</a>)}
                  {sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') && (
                    <a href='/report/income'>Thu Chi</a>)}
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                    <a href='/report/inventory'>Kho Hàng</a>)}
                </div>
              )}
            </li>
            <li className="menu-item">
              <button onMouseEnter={handleManagementEnter} onMouseLeave={handleManagementLeave} className="dropdown-button">
                <FontAwesomeIcon icon={faUsers} />
                <p style={{ margin: '0 10px' }}> Mối Quan Hệ </p>
                <FontAwesomeIcon icon={isManagementOpen ? faCaretUp : faCaretDown} />
              </button>
              {isManagementOpen && (
                <div onMouseEnter={handleManagementEnter} onMouseLeave={handleManagementLeave} className="dropdown-content">
                  {sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') && (
                    <a href="/user">Nhân Viên</a>)}
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                    <a href="/customer">Khách Hàng</a>)}
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                    <a href="/supplier">Nhà Cung Cấp</a>)}
                  {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                    <a href="/doctor">Bác Sĩ</a>)}
                </div>
              )}
            </li>
          </ul>
        )}
        {id ? (
          <div className="navbar-icons">
            {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
              <div>
                <button onClick={toggleNotification} className='dropdown-button'>
                  <Badge color="error" badgeContent={unreadNotifications?.totalElements}>
                    <FontAwesomeIcon icon={faBell} style={{ paddingTop: '2px', paddingBottom: '2px' }} />
                  </Badge>
                </button>
                {isNotificationOpen && (
                  <div className="dropdown-content" style={{ right: '20px', width: '450px', maxHeight: '400px', overflowY: 'auto', top: '50px', color: 'black' }}>
                    <h1 style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'left', color: '#09446D', paddingLeft: '20px' }}>Thông báo</h1>
                    <div style={{ display: 'flex', padding: '0 5px' }}>
                      {notificationTypes.map(({ type, label }) => (
                        <Chip
                          key={type}
                          onClick={type !== notificationType ? () => setNotificationType(type) : undefined}
                          style={{ margin: '0 5px', cursor: 'pointer' }}
                          label={label}
                          variant={type === notificationType ? undefined : 'outlined'}
                        />
                      ))}
                    </div>
                    {notificationType === 'unread' ? (
                      unreadNotifications?.content?.length > 0 ? (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          {unreadNotifications.content?.map((data, index) => (
                            <button
                              key={index}
                              onClick={() => handleRedirect(data?.url, data?.id)}
                              className={`notification-item ${data.isRead ? 'read' : 'unread'}`}
                            >
                              <div className='notification-content'>
                                <h4 className='notification-title'>{data?.title}</h4>
                                <p className='notification-body'>{data?.message}</p>
                                <p className='notification-time'>{data?.createdAt ? timeDifferenceFromNow(data?.createdAt) : ''}</p>
                              </div>
                            </button>
                          ))}
                        </div>
                      ) : (
                        <div style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <p>Không có thông báo</p>
                        </div>
                      )
                    ) : (
                      notifications?.content?.length > 0 ? (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          {notifications.content?.map((data, index) => (
                            <button
                              key={index}
                              onClick={() => handleRedirect(data?.url, data?.id)}
                              className={`notification-item ${data.isRead ? 'read' : 'unread'}`}
                            >
                              <div className='notification-content'>
                                <h4 className='notification-title'>{data?.title}</h4>
                                <p className='notification-body'>{data?.message}</p>
                                <p className='notification-time'>{data?.createdAt ? timeDifferenceFromNow(data?.createdAt) : ''}</p>
                              </div>
                            </button>
                          ))}
                        </div>
                      ) : (
                        <div style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <p>Không có thông báo</p>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>)}
            <div>
              <button onClick={toggleSetting} className='dropdown-button'>
                <FontAwesomeIcon icon={faUser} style={{ paddingTop: '2px', paddingBottom: '2px' }} />
              </button>
              {isSettingsOpen && (
                <div className="dropdown-content" style={{ right: '20px', top: '50px' }}>
                  <div className="user-info">
                    <div className="user-avatar">
                      {avatar ? (
                        <>
                          <img src={avatar} alt="Avatar" className="avatar" />
                        </>
                      ) : (
                        <>
                          <div className="avatar" style={{ border: 'solid 2px #709dc1', backgroundColor: "transparent", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <FontAwesomeIcon icon={faUser} size="2x" style={{ color: "#709dc1" }} />
                          </div>
                        </>
                      )}
                      <span className="status-indicator"></span>
                    </div>
                    <div>
                      <strong>{fullName}</strong><br />
                      <strong>
                        {Array.isArray(roles)
                          ? roles
                            .map((role) => {
                              if (role === 'ROLE_PRODUCT_OWNER') return 'Chủ cửa hàng';
                              if (role === 'ROLE_CUSTOMER') return 'Khách hàng';
                              return 'Nhân viên';
                            })
                            .join(', ')
                          : roles === 'ROLE_PRODUCT_OWNER'
                            ? 'Chủ cửa hàng'
                            : roles === 'ROLE_CUSTOMER'
                              ? 'Khách hàng'
                              : 'Nhân viên'}
                      </strong>
                    </div>
                  </div>
                  <hr />
                  <a href="/change-password">Đổi mật khẩu</a>
                  <a href="#" onClick={() => {
                    sessionStorage.clear();
                    window.location.href = '/login';
                  }}>
                    Đăng xuất
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : (
          <button className="login-btn">
            <a href="/login">
              <span className="icon">
                <AccountCircle />
              </span>
              Đăng nhập
            </a>
          </button>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
