import React, { useEffect, useState } from 'react';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, LinearProgress, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import '../../styles/Import.css';
import api from '../../api/axiosConfig';

const ImportReceiptDetail = () => {
    const navigate = useNavigate();
    const { importId } = useParams();
    const [item, setItem] = useState(null);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [downloading, setDownloading] = useState(false);

    const getImportReceipt = async () => {
        const token = sessionStorage.getItem('token');
        try {
            const { data } = await api.get(`/api/import/get-import-receipt?importId=${importId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(data);
            setItem(data);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    useEffect(() => {
        getImportReceipt();
    }, []);

    const handleEdit = () => {
        navigate(`/import/receipt/edit/${importId}`);
    }

    const handleConfirm = async () => {
        setDownloading(true);
        try {
            const token = sessionStorage.getItem('token');
            const u = sessionStorage.getItem('id');
            const { data } = await api.post(`/api/import/${importId}/confirm`, {
                userId: Number(u),
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            getImportReceipt();
            setTimeout(() => {
                toast.success(data);
            }, 500);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        } finally {
            setDownloading(false);
        }
    };

    const handleReject = async () => {
        setDownloading(true);
        try {
            const token = sessionStorage.getItem('token');
            const u = sessionStorage.getItem('id');
            const { data } = await api.post(`/api/import/${importId}/reject`, {
                userId: Number(u),
                reason: rejectReason,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            getImportReceipt();
            setOpenRejectDialog(false);
            setTimeout(() => {
                toast.success(data);
            }, 500);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        } finally {
            setDownloading(false);
        }
    };

    const formattedModifyDate = item && item.lastModifiedDate
        ? format(new Date(item.lastModifiedDate * 1000), 'dd/MM/yyyy')
        : '../../....';

    return (
        <DefaultTemplate>
            <div className="receipt-container">
                <div className="header">
                    <h3 className='title-name' style={{ marginBottom: '0', marginLeft: '10px' }}>
                        <Link to="/import" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Phiếu Nhập
                        </Link>
                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronRight} />
                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faChevronRight} />
                        Chi tiết phiếu nhập {item?.invoiceNumber}
                    </h3>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '4', textAlign: 'start', margin: '0 10px', borderRadius: '10px' }}>
                        {item?.status === 'Chờ xác nhận' && sessionStorage.getItem('roles') === 'ROLE_PRODUCT_OWNER' && (
                            <div className='file-buttons' style={{ paddingBottom: '10px' }}>
                                <Button className="export-btn" style={{ backgroundColor: "#28a745", color: '#fff' }} onClick={() => handleConfirm()}>
                                    Xác nhận
                                </Button>
                                <Button className="reject-btn" style={{ backgroundColor: "#b10202", color: '#fff' }} onClick={() => setOpenRejectDialog(true)}>
                                    Từ chối
                                </Button>
                            </div>
                        )}
                        <div className="import-list" style={{ height: '500px', overflow: 'auto' }}>
                            <table style={{ boxShadow: 'none' }}>
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>Mã - Tên sản phẩm</th>
                                        <th>Đơn vị nhập</th>
                                        <th>Đơn giá</th>
                                        <th>Số lượng</th>
                                        <th>C.K (%)</th>
                                        <th>VAT (%)</th>
                                        <th>Số lô</th>
                                        <th>Hạn dùng</th>
                                        <th>Thành tiền</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: 'center' }}>
                                    {item?.importItems ? item.importItems.map((item, index) => (
                                        <tr style={{ textAlign: 'center' }} key={item.id}>
                                            <td>{index + 1}</td>
                                            <td style={{ textAlign: 'left' }}>{item.product.productCode} - {item.product.productName}</td>
                                            <td>{item.unit}</td>
                                            <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.unitPrice)}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.discount}</td>
                                            <td>{item.tax}</td>
                                            <td>{item.batchNumber}</td>
                                            <td>{format(new Date(item.expiryDate * 1000), 'dd/MM/yyyy')}</td>
                                            <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.totalAmount)}</td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan={10}>
                                                <LinearProgress color='inherit' />
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan={10} style={{ textAlign: 'center' }}>
                                            <h4 className='title-name' style={{ margin: '0 0 10px 50px' }}>Hình ảnh hóa đơn:</h4>
                                            <img
                                                src={item?.imageUrl}
                                                alt={`Uploaded ${item?.imageUrl}`}
                                                style={{ maxWidth: '100%', height: 'auto', border: '1px solid #ddd' }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ backgroundColor: 'white', marginRight: '5px', textAlign: 'start', padding: '15px 25px', borderRadius: '10px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)', flex: '1' }}>
                        <h4 className='title-name' style={{ color: '#022742', margin: '5px 0' }}>Thông tin phiếu nhập</h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Ngày tạo phiếu:</span></div>
                            <div className='importContent'>{item?.importDate ? new Date(item.createDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '../../....'}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Người tạo phiếu:</span></div>
                            <div className='importContent'>{item?.createBy}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Trạng thái phiếu:</span></div>
                            <div className='importContent'>{item?.status}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Nhà cung cấp:</span></div>
                            <div className='importContent'>{item?.supplierName}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Số lượng sản phẩm:</span></div>
                            <div className='importContent'>{item?.importItems?.length} sản phẩm</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng tiền hàng:</span></div>
                            <div className='importContent'>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.totalAmount + item?.discount - item?.tax)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng chiết khấu:</span></div>
                            <div className='importContent'>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.discount)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng thuế:</span></div>
                            <div className='importContent'>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.tax)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent' style={{ fontWeight: 'bold' }}><span>Tổng tiền phải trả:</span></div>
                            <div className='importContent'>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.totalAmount)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Phương thức thanh toán:</span></div>
                            <div className='importContent'>{item?.paymentMethod === 'TRANSFER' ? 'Chuyển khoản' : 'Tiền mặt'}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Ghi chú:</span></div>
                            <div className='importContent'>{item?.note || 'N/A'}</div>
                        </div>
                        <div style={{ display: 'flex', flex: '1', marginTop: '20px', justifyContent: 'center' }}>
                            {item?.status !== 'Đã xác nhận' && (
                                <Button onClick={handleEdit} variant="contained" className='btn' >
                                    Sửa
                                </Button>
                            )}
                            <Button type='btn' variant="contained" className='btn' onClick={() => { navigate('/import') }}>
                                Quay lại
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <p><strong>Chỉnh sửa lần cuối ngày {formattedModifyDate} bởi {item && item.lastModifiedBy ? (<>{item.lastModifiedBy}</>) : (<>....</>)}</strong></p>
                </div>
            </div>
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
            <Dialog open={openRejectDialog} onClose={() => setOpenRejectDialog(false)}>
                <DialogTitle style={{ textAlign: 'center', color: '#09446d', fontWeight: 'bold' }}>Lý do từ chối</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        fullWidth
                        multiline
                        rows={5}
                        label="Nhập lý do từ chối"
                        value={rejectReason}
                        onChange={(e) => setRejectReason(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        sx={{
                            backgroundColor: 'white',
                            width: '400px',
                            '& .MuiInputBase-input': { color: '#022742' },
                        }}
                        InputLabelProps={{
                            style: { color: '#022742' },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReject} color="primary">Lưu</Button>
                    <Button onClick={() => setOpenRejectDialog(false)} color="primary">Hủy</Button>
                </DialogActions>
            </Dialog>
        </DefaultTemplate>
    );
};

export default ImportReceiptDetail;