import React, { useState, useEffect } from 'react';
import axiosConfig from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import addressData from '../customer/addressData';
import { FormControl, InputLabel, MenuItem, Select, CircularProgress } from '@mui/material';

const EditSupplier = ({ supplier, onCancel }) => {
  const [formData, setFormData] = useState({
    id: '',
    supplierName: '',
    address: '',
    phoneNumber: '',
    email: '',
    tax: '',
    additionalAddress: '',
  });
  const [selectedXa, setSelectedXa] = useState('');
  const [selectedHuyen, setSelectedHuyen] = useState('');
  const [selectedTinh, setSelectedTinh] = useState('');
  const [districts, setDistricts] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (supplier) {
      const [additionalAddress, xa, huyen, tinh] = supplier.address.split(', ');

      setFormData({
        id: supplier.id || '',
        supplierName: supplier.supplierName || '',
        address: supplier.address || '',
        phoneNumber: supplier.phoneNumber || '',
        email: supplier.email || '',
        tax: supplier.tax || '',
        additionalAddress: additionalAddress || '',
      });

      setSelectedTinh(tinh || '');
      setSelectedHuyen(huyen || '');
      setSelectedXa(xa || '');

      if (tinh) {
        setDistricts(Object.keys(addressData[tinh].districts));
      }
      if (huyen) {
        setCommunes(addressData[tinh].districts[huyen]);
      }
    }
  }, [supplier]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index + 1]?.focus();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    if (name === 'tinh') {
      setSelectedTinh(value);
      setSelectedHuyen('');
      setSelectedXa('');
      setDistricts(Object.keys(addressData[value].districts));
      setCommunes([]);
    }

    if (name === 'huyen') {
      setSelectedHuyen(value);
      setSelectedXa('');
      setCommunes(addressData[selectedTinh].districts[value]);
    }

    if (name === 'xa') setSelectedXa(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDownloading(true);
    const token = sessionStorage.getItem('token');
    const combinedAddress = `${formData.additionalAddress}, ${selectedXa}, ${selectedHuyen}, ${selectedTinh}`;
    try {
      const { data } = await axiosConfig.put(
        '/api/supplier/update-supplier',
        {
          ...formData,
          address: combinedAddress,
          yob: parseInt(formData.yob),
          gender: formData.gender,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data);
      setTimeout(() => {
        onCancel();
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Không thể cập nhật nhà cung cấp, vui lòng thử lại.');
    } finally {
      setDownloading(false);
    }
  };

  const styles = {
    container: {
      width: '500px',
      marginTop: '50px',
      backgroundColor: '#ffffff',
      padding: '30px',
      borderRadius: '10px',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    fieldText: {
      fontWeight: 'bold',
      color: '#072f4d',
      marginBottom: '5px',
    },
    input: {
      width: '97%',
      padding: '6px',
      border: '2px solid #ccc',
      borderRadius: '6px',
      outline: 'none',
      fontSize: '14px',
    },
    selectContainer: {
      display: 'flex',
      gap: '10px',
      marginBottom: '10px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '25px',
    },
    button: {
      backgroundColor: '#072f4d',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 20px',
      cursor: 'pointer',
      margin: '0 10px',
    },
  };

  return (
    <form onSubmit={handleSubmit} style={styles.container}>
      <h2 style={{ textAlign: 'center' }}>Chỉnh sửa nhà cung cấp</h2>
      <div style={styles.fieldContainer}>
        <label style={styles.fieldText}>
          Tên nhà cung cấp <span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type='text'
          name='supplierName'
          value={formData.supplierName}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={styles.input}
        />
      </div>

      <div style={styles.fieldContainer}>
        <label style={styles.fieldText}>Địa chỉ chi tiết</label>
        <input
          type='text'
          name='additionalAddress'
          value={formData.additionalAddress}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={styles.input}
        />
      </div>

      <div style={styles.fieldContainer}>
        <label style={styles.fieldText}>Địa chỉ đã chọn:</label>
        <div>
          <span style={{ color: selectedTinh ? '#072f4d' : '#ccc' }}>
            {selectedTinh || 'Chọn tỉnh'}
          </span>
          ,{' '}
          <span style={{ color: selectedHuyen ? '#072f4d' : '#ccc' }}>
            {selectedHuyen || 'Chọn huyện'}
          </span>
          ,{' '}
          <span style={{ color: selectedXa ? '#072f4d' : '#ccc' }}>
            {selectedXa || 'Chọn xã'}
          </span>
        </div>
      </div>
      <div style={styles.fieldContainer}>
        <label style={styles.fieldText}>Chọn địa chỉ</label>
        <div style={styles.selectContainer}>
          <FormControl style={{ width: '97%' }} size="small">
            <InputLabel id="tinh-label">Chọn tỉnh</InputLabel>
            <Select
              name="tinh"
              value={selectedTinh}
              onChange={handleAddressChange}
              labelId="tinh-label"
              label="Chọn tỉnh"
              placeholder="Chọn tỉnh"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Chọn tỉnh</em>
              </MenuItem>
              {Object.keys(addressData).map((tinh) => (
                <MenuItem key={tinh} value={tinh}>
                  {tinh}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={{ width: '97%' }} size="small">
            <InputLabel id="huyen-label">Chọn huyện</InputLabel>
            <Select
              name="huyen"
              value={selectedHuyen}
              onChange={handleAddressChange}
              labelId="huyen-label"
              label="Chọn huyện"
              placeholder="Chọn huyện"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Chọn huyện</em>
              </MenuItem>
              {districts.map((district) => (
                <MenuItem key={district} value={district}>
                  {district}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={{ width: '97%' }} size="small">
            <InputLabel id="xa-label">Chọn xã</InputLabel>
            <Select
              name="xa"
              value={selectedXa}
              onChange={handleAddressChange}
              labelId="xa-label"
              label="Chọn xã"
              placeholder="Chọn xã"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Chọn xã</em>
              </MenuItem>
              {communes.map((commune) => (
                <MenuItem key={commune} value={commune}>
                  {commune}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div style={styles.fieldContainer}>
        <label style={styles.fieldText}>
          Số điện thoại <span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type='text'
          name='phoneNumber'
          value={formData.phoneNumber}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={styles.input}
        />
      </div>

      <div style={styles.fieldContainer}>
        <label style={styles.fieldText}>Email</label>
        <input
          type='text'
          name='email'
          value={formData.email}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={styles.input}
        />
      </div>

      <div style={styles.fieldContainer}>
        <label style={styles.fieldText}>
          Số thuế <span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type='text'
          name='tax'
          value={formData.tax}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={styles.input}
        />
      </div>

      <div style={styles.buttons}>
        <button type='submit' style={styles.button}>
          Cập nhật
        </button>
        <button type='button' onClick={onCancel} style={styles.button}>
          Hủy
        </button>
      </div>
      {downloading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 99999,
          }}
        >
          <CircularProgress size="5rem" />
        </div>
      )}
    </form>
  );
};

export default EditSupplier;
