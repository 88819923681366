import React, { useEffect, useState } from 'react';
import '../../styles/User.css';
import DefaultTemplate from '../../templates/DefaultTemplate';
import ExportListLayout from '../../layouts/ExportListLayout';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';

const ExportList = () => {
  const [exports, setExports] = useState([]);
  const [typeDelivery, setTypeDelivery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("PENDING");

  const getExports = async (page, typeDelivery, status, fromDate, toDate) => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
    console.log(typeDelivery);
    try {
      let url = `/api/export-slip/get-all-export-slip-paging?page=${page - 1}&size=10`;
      if (typeDelivery) url += `&exportType=${typeDelivery}`;
      if (status) url += `&status=${status}`;
      if (fromDate) url += `&fromDate=${fromDate}`;
      if (toDate) url += `&toDate=${toDate}`;
      console.log("URL:" + url);
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = response.data;
      console.log(data);
      setExports(data);
    } catch (error) {
      if (error.status === 404) {
        setExports([]);
      } else if (error.status >= 400 && error.status < 500) {
        toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [typeDelivery, status, fromDate, toDate]);

  useEffect(() => {
    getExports(currentPage, typeDelivery, status, fromDate, toDate);
  }, [currentPage, typeDelivery, status, fromDate, toDate]);

  return (
    <DefaultTemplate>
      {exports && (
        <ExportListLayout
          exports={exports}
          getExports={() => getExports(1, typeDelivery, status, fromDate, toDate)}
          setStatus={setStatus}
          setTypeDelivery={setTypeDelivery}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setFromDate={setFromDate}
          setToDate={setToDate}
          loading={loading}
          onSearch={() => {
            setCurrentPage(1);
            getExports(1, typeDelivery, status, fromDate, toDate);
          }}
        />
      )}
    </DefaultTemplate>
  );
};

export default ExportList;