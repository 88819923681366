import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DefaultTemplate from "../../templates/DefaultTemplate";
import UserListLayout from '../../layouts/UserListLayout';
import api from "../../api/axiosConfig";
import '../../styles/User.css';

const UserList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [users, setUsers] = useState(null);
    const [status, setStatus] = useState("");
    const [name, setName] = useState("");
    const [role, setRole] = useState("");
    const [loading, setLoading] = useState(true);
    const getUser = async (status, name, role, page) => {
        const token = sessionStorage.getItem('token');
        setLoading(true);
        try {
            let url = `/api/user/get-all-user-paging?page=${page - 1}`;
            if (status) url += `&status=${status}`;
            if (role) url += `&role=${role}`;
            if (name) url += `&fullName=${name}`;
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data = response.data;
            setUsers(data);
        } catch (error) {
            if (error.status === 404) {
                setUsers([]);
            } else if (error !== 404 && error.status >= 400 && error.status < 500) {
                toast.error("Lỗi khi tải dữ liệu. Vui lòng thử lại sau!");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [status, name, role]);

    useEffect(() => {
        getUser(status, name, role, currentPage);
    }, [currentPage, status, name, role]);

    return (
        <DefaultTemplate>
            <UserListLayout
                users={users}
                getUser={() => getUser(status, name, role, 1)}
                setStatus={setStatus}
                setRole={setRole}
                setName={setName}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                loading={loading}
                onSearch={() => {
                    setCurrentPage(1);
                    getUser(status, name, role, 1);
                }}
            />
        </DefaultTemplate>
    );
};

export default UserList;
