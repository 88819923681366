import React from 'react';
import '../../styles/User.css';
import DefaultTemplate from "../../templates/DefaultTemplate";
import TypeLayout from '../../layouts/TypeLayout';

const TypeScreen = () => {

    return (
        <DefaultTemplate>
            <TypeLayout/>
        </DefaultTemplate>
    );
};

export default TypeScreen;
