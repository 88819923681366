import React from 'react';
import '../../styles/User.css';
import DefaultTemplate from "../../templates/DefaultTemplate";
import UnitLayout from '../../layouts/UnitLayout';

const UnitScreen = () => {
    return (
        <DefaultTemplate>
            <UnitLayout/>
        </DefaultTemplate>
    );
};

export default UnitScreen;
