import React, { useEffect, useState } from 'react';
import '../../styles/User.css';
import DefaultTemplate from '../../templates/DefaultTemplate';
import ReturnOrderListLayout from '../../layouts/ReturnOrderListLayout';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';

const ReturnOrderList = () => {
  const [returnOrder, setReturnOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(true);

  const getReturnOrder = async (fromDate, toDate, invoiceNumber, page) => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
    try {
      let url = `/api/return-order/get-all-return-order-paging?page=${page - 1}&size=10`;
      if (invoiceNumber) url += `&invoiceNumber=${invoiceNumber}`;
      if (fromDate) url += `&fromDate=${fromDate}`;
      if (toDate) url += `&toDate=${toDate}`;
      console.log("URL:", url);
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = response.data;
      console.log(data);
      setReturnOrder(data);
    } catch (error) {
      if (error.status === 404) {
        setReturnOrder([]);
      } else if (error.status >= 400 && error.status < 500) {
        toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [fromDate, toDate, invoiceNumber]);

  useEffect(() => {
    getReturnOrder(fromDate, toDate, invoiceNumber, currentPage);
  }, [fromDate, toDate, invoiceNumber, currentPage]);

  return (
    <DefaultTemplate>
      {returnOrder && (
        <ReturnOrderListLayout
          returnOrder={returnOrder}
          getReturnOrder={() => getReturnOrder(fromDate, toDate, invoiceNumber, 1)}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setInvoiceNumber={setInvoiceNumber}
          setFromDate={setFromDate}
          setToDate={setToDate}
          loading={loading}
          onSearch={() => {
            setCurrentPage(1);
            getReturnOrder(fromDate, toDate, invoiceNumber, 1);
          }}
        />
      )}
    </DefaultTemplate>
  );
};

export default ReturnOrderList;
