import React, { useEffect, useState } from 'react';
import '../../styles/User.css';
import DefaultTemplate from '../../templates/DefaultTemplate';
import SupplierLayout from '../../layouts/SupplierLayout';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';

const SupplierList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [suppliers, setSuppliers] = useState(null);
  const [supplierName, setSupplierName] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);

  const getSupplier = async (status, supplierName, page) => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
    try {
      let url = `/api/supplier/get-all-supplier-by-paging?page=${page - 1}`;
      if (status) url += `&status=${status}`;
      if (supplierName) url += `&name=${supplierName}`;
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setSuppliers(data);
    } catch (error) {
      if (error.status === 404) {
        setSuppliers([]);
      } else if (error.status >= 400 && error.status < 500) {
        toast.error("Lỗi khi tải dữ liệu. Vui lòng thử lại sau!");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [status, supplierName]);

  useEffect(() => {
    getSupplier(status, supplierName, currentPage);
  }, [currentPage, status, supplierName]);

  return (
    <DefaultTemplate>
      <SupplierLayout
        suppliers={suppliers}
        setStatus={setStatus}
        setSupplierName={setSupplierName}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        getSupplier={() => getSupplier(status, supplierName, 1)}
        onSearch={() => {
          setCurrentPage(1);
          getSupplier(status, supplierName, 1);
        }}
      />
    </DefaultTemplate>
  );
};

export default SupplierList;