import React, { useState } from 'react';
import ReceiptTemplate from '../../templates/ReceiptTemplate';
import ReceiptFooter from '../../layouts/ReceiptFooter';
import SaleReceiptLayout from '../../layouts/SaleReceiptLayout';
import SalePrescriptionLayout from '../../layouts/SalePrescriptionLayout';
import { useParams } from 'react-router-dom';

const SaleReceiptCreate = () => {
    const { type } = useParams();
    const [activeItem, setActiveItem] = useState(type !== 'prescription');

    return (
        <ReceiptTemplate>
            {activeItem === true ? (
                <SaleReceiptLayout/>
            ) : (
                <SalePrescriptionLayout/>
            )}
            <ReceiptFooter activeItem={activeItem} setActiveItem={setActiveItem} />
        </ReceiptTemplate >
    );
};
    
export default SaleReceiptCreate;