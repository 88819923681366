import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Container, Box } from '@mui/material';
import { toast } from 'react-toastify';
import apiConfig from '../../api/axiosConfig';

const AddUnitForm = ({ unit, onCancel }) => {
  const [formData, setFormData] = useState({
    unitName: '',
    description: ''
  });

  useEffect(() => {
    if (unit) {
      setFormData({
        unitName: unit.unitName || '',
        description: unit.description || ''
      });
    }
  }, [unit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSave = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const { data } = await apiConfig.post('/api/unit/create-unit', formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (data) {
        setTimeout(
          () => {
            toast.success(data);
          }, 500
        )
      }
      onCancel();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Không thể tạo đơn vị sản phẩm, vui lòng thử lại');
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#fff',
          padding: '50px',
          borderRadius: '10px',
          boxShadow: '0 8px 19px rgba(0, 0, 0, 0.15)',
          marginBottom: '-40px',
          width: '500px',
          marginLeft: '10px',
          marginTop: '150px'
        }}
      >
        <Typography component="h1" variant="h5" style={{ marginBottom: '20px', color: '#09446d', fontWeight: 'bold' }}>
          {unit ? 'Sửa đơn vị sản phẩm' : 'Thêm đơn vị sản phẩm'}
        </Typography>

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="unitName"
          label="Đơn vị sản phẩm"
          name="unitName"
          value={formData.unitName}
          onChange={handleInputChange}
          autoComplete="unitName"
          autoFocus
          sx={{
            backgroundColor: 'white',
            '& .MuiInputBase-input': { color: '#022742' },
          }}
          InputLabelProps={{
            style: { color: '#022742' },
          }}
          inputProps={{
            autoComplete: "off",
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="description"
          label="Mô tả"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          multiline
          rows={4}
          sx={{
            backgroundColor: 'white',
            '& .MuiInputBase-input': { color: '#022742' },
          }}
          InputLabelProps={{
            style: { color: '#022742' },
          }}
          inputProps={{
            autoComplete: "off",
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              marginRight: '10px',
              backgroundColor: '#09446D',
              color: 'white',
              width: '10px',
              marginLeft: '180px',
              '&:hover': { backgroundColor: '#022742' },
            }}
            onClick={handleSave}
          >
            Lưu
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              backgroundColor: '#09446D',
              color: 'white',
              borderColor: '#09446D',
              marginRight: '180px',
              width: '10px',
              '&:hover': {
                backgroundColor: '#072f4d',
                borderColor: '#072f4d',
              },
            }}
            onClick={onCancel}
          >
            Hủy
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddUnitForm;
