import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import '../styles/Import.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faEye } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress, Button, Menu, MenuItem, Tab, Tabs, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FiDownload } from 'react-icons/fi';
import { toast } from 'react-toastify';
import api from '../api/axiosConfig';

const ExportListLayout = ({ exports, currentPage, setFromDate, loading, setToDate, setCurrentPage, setTypeDelivery, setStatus }) => {
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState(null);
    const [totalPage, setTotalPage] = useState(0);
    const [currentTab, setCurrentTab] = useState('PENDING');
    const [downloading, setDownloading] = useState(false);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        setStatus(newValue);
    };

    const pageSize = exports ? exports.size : 10;

    const calculateTotalPages = (total, size) => {
        if (size === 0) return 0;
        return Math.ceil(total / size);
    };

    useEffect(() => {
        if (exports) {
            setTotalPage(calculateTotalPages(exports.total, exports.size));
        }
    }, [exports]);

    const getVisiblePages = () => {
        const maxButtons = 3;
        const half = Math.floor(maxButtons / 2);
        let startPage, endPage;

        if (totalPage <= maxButtons) {
            startPage = 1;
            endPage = totalPage;
        } else {
            if (currentPage <= half) {
                startPage = 1;
                endPage = maxButtons;
            } else if (currentPage + half >= totalPage) {
                startPage = totalPage - maxButtons + 1;
                endPage = totalPage;
            } else {
                startPage = currentPage - half;
                endPage = currentPage + half;
            }
        }
        return { startPage, endPage };
    }

    const convertToLocalDate = (date) => {
        if (date) {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);

            return newDate.toISOString().split('T')[0];
        }
        return null;
    };

    const { startPage, endPage } = getVisiblePages();
    const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const getExportExcel = async () => {
        setDownloading(true);

        if (!dateRange) {
            toast.error('Vui lòng chọn thời gian');
            setDownloading(false);
            return;
        }
        const token = sessionStorage.getItem('token');
        try {
            if (dateRange && dateRange[0] && dateRange[1]) {
                let url = `/api/export-slip/export-excel-export-slips?fromDate=${convertToLocalDate(
                    dateRange[0]
                )}&toDate=${convertToLocalDate(dateRange[1])}`;
                const response = await api.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                });
                const blobUrl = window.URL.createObjectURL(response.data);
                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = 'Danh_sach_phieu_xuat.xlsx';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            console.error(error);
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        } finally {
            setDownloading(false);
        }
    };

    return (
        <div className="container">
            <div className="header">
                <h2 className='title-name'>Phiếu Xuất</h2>
                <div className="btnSearch" style={{ marginBottom: '10px', width: '100%' }}>
                    <div className="left-controls">
                        <div className="date-range-picker">
                            <Flatpickr
                                value={dateRange}
                                onChange={([startDate, endDate]) => {
                                    setDateRange([startDate, endDate])
                                    setFromDate(convertToLocalDate(startDate))
                                    setToDate(convertToLocalDate(endDate))
                                }}
                                options={{
                                    mode: "range",
                                    dateFormat: "d/m/Y",
                                    maxDate: "today",
                                    locale: {
                                        rangeSeparator: " ~ ",
                                    },
                                }}
                                placeholder="__/__/____ ~ __/__/____"
                            />
                            <span className="icon">&#x1F4C5;</span>
                        </div>
                        <select className="custom-select" name="typeDelivery" onChange={(e) => setTypeDelivery(e.target.value)}>
                            <option value="" selected>Loại phiếu</option>
                            <option value="DESTROY">Phiếu hủy</option>
                            <option value="RETURN_TO_SUPPLIER">Phiếu trả nhà cung cấp</option>
                        </select>
                        <div className="btnAdd">
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                <Button variant="contained" onClick={handleClick} style={{ fontSize: '14px', padding: '6px 14px' }}>
                                    Thêm
                                </Button>
                                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                                    <MenuItem onClick={() => { navigate("/export/create/receipt"); handleClose(); }}>Phiếu xuất hủy</MenuItem>
                                    <MenuItem onClick={() => { navigate("/export/create/return"); handleClose(); }}>Phiếu trả nhà cung cấp</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className="file-buttons">
                        <Button className="export-btn" style={{ backgroundColor: "#09446d", color: '#fff' }} onClick={getExportExcel}>
                            Xuất file
                            <FiDownload />
                        </Button>
                    </div>
                </div>
                <Tabs value={currentTab} onChange={handleTabChange}>
                    <Tab style={{ padding: '12px' }} label="Chờ xác nhận" value={'PENDING'} />
                    <Tab style={{ padding: '12px' }} label="Đã xác nhận" value={'CONFIRMED'} />
                    <Tab style={{ padding: '12px' }} label="Từ chối" value={'REJECT'} />
                    <Tab style={{ padding: '12px' }} label="Tất cả phiếu" value={''} />
                </Tabs>
            </div>
            <div style={{ overflowX: 'auto' }}>
                <table className="list">
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Mã phiếu</th>
                            <th>Ngày tạo phiếu</th>
                            <th>Người tạo phiếu</th>
                            <th>Loại phiếu</th>
                            <th>Số lượng sản phẩm</th>
                            <th>Tổng tiền</th>
                            <th>Trạng thái</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan={9} style={{ padding: '20px' }}>
                                    <LinearProgress color="inherit" />
                                </td>
                            </tr>
                        ) : (
                            exports.listData?.length > 0 ? exports.listData.map((item, index) => (
                                <tr key={item.id}>
                                    <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                    <td>{item.invoiceNumber}</td>
                                    <td>{item.exportDate ? new Date(item.exportDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : '../../....'}</td>
                                    <td>{item.fullName}</td>
                                    <td>{item.typeDelivery === 'DESTROY' ? 'Phiếu hủy' : 'Phiếu trả nhà cung cấp'}</td>
                                    <td style={{ width: '80px' }}>{item.productCount}</td>
                                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.totalAmount)}</td>
                                    <td>
                                        <span className={item.status === 'CONFIRMED' ? 'status-active' : item.status === 'PENDING' ? 'status-pending' : 'status-inactive'}>
                                            {item.status === 'PENDING' ? 'Chờ xác nhận' : item.status === 'CONFIRMED' ? 'Đã xác nhận' : 'Từ chối'}
                                        </span>
                                    </td>
                                    <td>
                                        <a href={`${item.typeDelivery === 'DESTROY' ? `/export/receipt/detail/${item.id}` : `/export/return-supplier/detail/${item.id}`} `} className="detail-btn">
                                            <FontAwesomeIcon icon={faEye} />
                                        </a>
                                        {item?.status != 'CONFIRMED' && (
                                            <a href={`${item.typeDelivery === 'DESTROY' ? `/export/receipt/edit/${item.id}` : `/export/return-supplier/edit/${item.id}`} `} className="edit-btn">
                                                <FontAwesomeIcon icon={faPenToSquare} />
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={9} style={{ padding: '20px' }}>
                                        Phiếu xuất không tồn tại
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
            {totalPage > 1 && (
                <div className="pagination">
                    {currentPage === 1 ? (
                        <button disabled>&laquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</button>
                    )}
                    {startPage > 1 && (
                        <>
                            <button onClick={() => setCurrentPage(1)}>1</button>
                            {startPage > 2 && (
                                <button disabled>...</button>
                            )}
                        </>
                    )}
                    {visiblePages.map((page) => (
                        <button onClick={() => setCurrentPage(page)}
                            key={page}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}
                    {endPage < totalPage && (
                        <>
                            {endPage < totalPage - 1 && (
                                <button disabled>...</button>
                            )}
                            <button onClick={() => setCurrentPage(totalPage)}>{totalPage}</button>
                        </>
                    )}
                    {currentPage === totalPage ? (
                        <button disabled>&raquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</button>
                    )}
                </div>
            )}
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
        </div>
    );
};

export default ExportListLayout;