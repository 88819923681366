import React, { useEffect, useState } from 'react';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, LinearProgress, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useNavigate, useParams, Link } from 'react-router-dom';
import api from '../../api/axiosConfig';
import '../../styles/Import.css';

const ReturnSupplierDetail = () => {
    const { exportId } = useParams();
    const [item, setItem] = useState(null);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [downloading, setDownloading] = useState(false);
    const navigate = useNavigate();

    const getExportReceipt = async () => {
        const token = sessionStorage.getItem('token');
        try {
            const { data } = await api.get(`/api/export-slip/get-export-slip/${exportId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(data);
            setItem(data);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    useEffect(() => {
        getExportReceipt();
    }, []);

    const handleEdit = () => {
        navigate(`/export/return-supplier/edit/${exportId}`);
    }

    const handleConfirm = async () => {
        setDownloading(true);
        try {
            const token = sessionStorage.getItem('token');
            const u = sessionStorage.getItem('id');
            const { data } = await api.post(`/api/export-slip/${exportId}/confirm`, {
                userId: Number(u),
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            getExportReceipt();
            setTimeout(() => {
                toast.success(data);
            }, 500);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        } finally {
            setDownloading(false);
        }
    };

    const handleReject = async () => {
        setDownloading(true);
        try {
            const token = sessionStorage.getItem('token');
            const u = sessionStorage.getItem('id');
            const { data } = await api.post(`/api/export-slip/${exportId}/reject`, {
                userId: Number(u),
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    reason: rejectReason,
                },
            });
            getExportReceipt();
            setOpenRejectDialog(false);
            setTimeout(() => {
                toast.success(data);
            }, 500);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        } finally {
            setDownloading(false);
        }
    };

    const renderDate = (value) => {
        const date = new Date(Math.floor(value) * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formattedModifyDate = item && item.lastModifiedDate
        ? format(new Date(item.lastModifiedDate * 1000), 'dd/MM/yyyy')
        : '../../....';

    return (
        <DefaultTemplate>
            <div className="receipt-container">
                <div className="header">
                    <h3 className='title-name' style={{ marginBottom: '0', marginLeft: '10px' }}>
                        <Link to="/export" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Phiếu Xuất
                        </Link>
                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronRight} />
                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faChevronRight} />
                        Chi tiết phiếu trả nhà cung cấp {item?.invoiceNumber}
                    </h3>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '4', textAlign: 'start', margin: '0 10px', borderRadius: '10px' }}>
                        {item?.status === 'PENDING' && sessionStorage.getItem('roles') === 'ROLE_PRODUCT_OWNER' && (
                            <div className='file-buttons' style={{ paddingBottom: '10px' }}>
                                <Button className="export-btn" style={{ backgroundColor: "#28a745", color: '#fff' }} onClick={() => handleConfirm()}>
                                    Xác nhận
                                </Button>
                                <Button className="reject-btn" style={{ backgroundColor: "#b10202", color: '#fff' }} onClick={() => setOpenRejectDialog(true)}>
                                    Từ chối
                                </Button>
                            </div>
                        )}
                        <div className="import-list" style={{ height: '450px', overflow: 'auto' }}>
                            <table style={{ boxShadow: 'none' }}>
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>Mã - Tên sản phẩm</th>
                                        <th>Đơn vị</th>
                                        <th>Đơn giá</th>
                                        <th>Số lượng</th>
                                        <th>C.K (%)</th>
                                        <th>Số lô</th>
                                        <th>Mã phiếu nhập</th>
                                        <th>Ngày nhập</th>
                                        <th>Tồn</th>
                                        <th>Thành tiền</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: 'center' }}>
                                    {item?.exportSlipItems ? item.exportSlipItems.map((item, index) => (
                                        <tr style={{ textAlign: 'center' }} key={item.id}>
                                            <td>{index + 1}</td>
                                            <td style={{ textAlign: 'left' }}>{item.product?.productCode} - {item.product?.productName}</td>
                                            <td>{item.unit}</td>
                                            <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.unitPrice)}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.discount}</td>
                                            <td>{item.importItem?.batchNumber}</td>
                                            <td>{item.importItem?.invoiceNumber}</td>
                                            <td>{renderDate(item.importItem?.importDate)}</td>
                                            <td>{Math.floor(item.importItem?.remainingQuantity / item.importItem?.conversionFactor) || 0}</td>
                                            <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.totalAmount)}</td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan={11}>
                                                <LinearProgress color='inherit' />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ backgroundColor: 'white', marginRight: '5px', textAlign: 'start', padding: '15px 25px', borderRadius: '10px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)', flex: '1' }}>
                        <h4 className='title-name' style={{ color: '#022742', margin: '5px 0' }}>Thông tin phiếu trả nhà cung cấp</h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Ngày tạo phiếu:</span></div>
                            <div className='importContent'>{item?.exportDate ? new Date(item.exportDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '../../....'}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Người tạo phiếu:</span></div>
                            <div className='importContent'>{item?.createBy}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Trạng thái phiếu:</span></div>
                            <div className='importContent'>{item?.status === 'PENDING' ? 'Chờ xác nhận' : item?.status === 'CONFIRMED' ? 'Đã xác nhận' : 'Từ chối'}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Nhà cung cấp:</span></div>
                            <div className='importContent'>{item?.supplier.supplierName}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Số lượng sản phẩm:</span></div>
                            <div className='importContent'>{item?.exportSlipItems?.length} sản phẩm</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng tiền hàng:</span></div>
                            <div className='importContent'>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.totalAmount - item?.discount)}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng chiết khấu:</span></div>
                            <div className='importContent'>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.discount)}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent' style={{ fontWeight: 'bold' }}><span>Nhà cung cấp cần trả:</span></div>
                            <div className='importContent'>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item?.totalAmount)}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Lí do trả nhà cung cấp:</span></div>
                            <div className='importContent'>{item?.note || 'N/A'}</div>
                        </div>
                        <div style={{ display: 'flex', flex: '1', marginTop: '50px', justifyContent: 'center' }}>
                            {item?.status !== 'CONFIRMED' && (
                                <Button onClick={handleEdit} variant="contained" className='btn' >
                                    Sửa
                                </Button>
                            )}
                            <Button type='btn' variant="contained" className='btn' onClick={() => { navigate('/export') }}>
                                Quay lại
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <p><strong>Chỉnh sửa lần cuối ngày {formattedModifyDate} bởi {item && item.lastModifiedBy ? (<>{item.lastModifiedBy}</>) : (<>....</>)}</strong></p>
                </div>
            </div>
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
            <Dialog open={openRejectDialog} onClose={() => setOpenRejectDialog(false)}>
                <DialogTitle style={{ textAlign: 'center', color: '#09446d', fontWeight: 'bold' }}>Lý do từ chối</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        fullWidth
                        multiline
                        rows={5}
                        label="Nhập lý do từ chối"
                        value={rejectReason}
                        onChange={(e) => setRejectReason(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        sx={{
                            backgroundColor: 'white',
                            width: '400px',
                            '& .MuiInputBase-input': { color: '#022742' },
                        }}
                        InputLabelProps={{
                            style: { color: '#022742' },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReject} color="primary">Lưu</Button>
                    <Button onClick={() => setOpenRejectDialog(false)} color="primary">Hủy</Button>
                </DialogActions>
            </Dialog>
        </DefaultTemplate >
    );
};

export default ReturnSupplierDetail;