import './App.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Home from './screens/authentication/Home';
import Login from './screens/authentication/Login';
import ChangePassword from './screens/authentication/ChangePassword';
import ForgotPassword from './screens/authentication/ForgotPassword';
import UserList from './screens/user/UserList';
import ProductList from './screens/product/ProductList';
import AddUnitProduct from './screens/product/AddUnitForm';
import TypeScreen from './screens/product/TypeScreen';
import UnitScreen from './screens/product/UnitScreen';
import EditTypeForm from './screens/product/EditTypeForm';
import ImportList from './screens/import/ImportList';
import CustomerList from './screens/customer/CustomerList';
import AddCustomer from './screens/customer/AddCustomer';
import EditCustomer from './screens/customer/EditCustomer';
import DetailCustomer from './screens/customer/DetailCustomer';
import DoctorList from './screens/doctor/DoctorList';
import ImportReceiptCreate from './screens/import/ImportReceiptCreate';
import ImportReceiptDetail from './screens/import/ImportReceiptDetail';
import ImportReceiptEdit from './screens/import/ImportReceiptEdit';
import SupplierList from './screens/supplier/SupplierList';
import DetailSupplier from './screens/supplier/DetailSupplier';
import AddSupplier from './screens/supplier/AddSupplier';
import EditSupplier from './screens/supplier/EditSupplier';
import ExportList from './screens/export/ExportList';
import ExportCreate from './screens/export/ExportCreate';
import ExportReceiptCreate from './screens/export/ExportReceiptCreate';
import ExportReceiptEdit from './screens/export/ExportReceiptEdit';
import ExportReceiptDetail from './screens/export/ExportReceiptDetail';
import ReturnSupplierCreate from './screens/export/ReturnSupplierCreate';
import ReturnSupplierEdit from './screens/export/ReturnSupplierEdit';
import ReturnSupplierDetail from './screens/export/ReturnSupplierDetail';
import SaleList from './screens/sale/SaleList';
import SaleReceiptCreate from './screens/sale/SaleReceiptCreate';
import SaleReceiptDetail from './screens/sale/SaleReceiptDetail';
import SalePrescriptionDetail from './screens/sale/SalePrescriptionDetail';
import ReturnOrderList from './screens/return-order/ReturnOrderList';
import ReturnOrderCreate from './screens/return-order/ReturnOrderCreate';
import ReturnOrderCreate2 from './screens/return-order/ReturnOrderCreate2';
import ReturnOrderDetail from './screens/return-order/ReturnOrderDetail';
import ReturnOrderEdit from './screens/return-order/ReturnOrderEdit';
import ReportPage from './screens/report/ReportPage';
import { useEffect } from 'react';
import { requestFCMToken } from './utils/firebaseUtils';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => {
    const fetchFCMToken = async () => {
      try {
        const token = await requestFCMToken();
        localStorage.setItem('fcmToken', token);
      } catch (err) {
        console.error("Error getting FCM token: ", err);
      }
    }
    fetchFCMToken();
  })

  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data?.type === 'NOTIFICATION_RECEIVED') {
        const { title, body } = event.data.payload.notification;
        toast.info(
            <div>
                <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
                    🔔 {title}
                </div>
                <div>{body}</div>
            </div>,
            {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            }
        );
    }
});

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/change-password' element={<ChangePassword />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/user' element={<UserList />} />
          <Route path='/products' element={<ProductList />} />
          <Route path='/add-unit-product' element={<AddUnitProduct />} />
          <Route path='/type' element={<TypeScreen />} />
          <Route path='/edit-type' element={<EditTypeForm />} />
          <Route path='/unit' element={<UnitScreen />} />
          <Route path='/customer/:page?' element={<CustomerList />} />
          <Route path='/add-customer' element={<AddCustomer />} />
          <Route path='/edit-customer/:customerId' element={<EditCustomer />} />
          <Route path='/detail-customer/:customerId' element={<DetailCustomer />} />
          <Route path='/doctor/:page?' element={<DoctorList />} />
          <Route path='/import' element={<ImportList />} />
          <Route path='/import/receipt/create' element={<ImportReceiptCreate />} />
          <Route path='/import/receipt/edit/:importId' element={<ImportReceiptEdit />} />
          <Route path='/import/receipt/detail/:importId' element={<ImportReceiptDetail />} />
          <Route path='/detail-supplier/:supplierId' element={<DetailSupplier />} />
          <Route path='/add-supplier' element={<AddSupplier />} />
          <Route path='/edit-supplier/:supplierId' element={<EditSupplier />} />
          <Route path='/supplier/:page?' element={<SupplierList />} />
          <Route path='/export' element={<ExportList />} />
          <Route path='/export/create/:type' element={<ExportCreate />} />
          <Route path='/export/receipt/create' element={<ExportReceiptCreate />} />
          <Route path='/export/receipt/edit/:exportId' element={<ExportReceiptEdit />} />
          <Route path='/export/receipt/detail/:exportId' element={<ExportReceiptDetail />} />
          <Route path='/export/return-supplier/create' element={<ReturnSupplierCreate />} />
          <Route path='/export/return-supplier/edit/:exportId' element={<ReturnSupplierEdit />} />
          <Route path='/export/return-supplier/detail/:exportId' element={<ReturnSupplierDetail />} />
          <Route path='/sale' element={<SaleList />} />
          <Route path='/sale/create/:type' element={<SaleReceiptCreate />} />
          {/* <Route path='/sale/receipt/edit/:saleId' element={<SaleReceiptEdit />} /> */}
          <Route path='/sale/receipt/detail/:saleId' element={<SaleReceiptDetail />} />
          {/* <Route path='/sale/prescription/edit/:saleId' element={<SalePrescriptionEdit />} /> */}
          <Route path='/sale/prescription/detail/:saleId' element={<SalePrescriptionDetail />} />
          <Route path='/return-order' element={<ReturnOrderList />} />
          <Route path='/return-order/create/:saleId' element={<ReturnOrderCreate />} />
          <Route path='/return-order/create' element={<ReturnOrderCreate2 />} />
          <Route path='/return-order/detail/:returnOrderId' element={<ReturnOrderDetail />} />
          <Route path='/return-order/edit/:returnOrderId' element={<ReturnOrderEdit />} />
          <Route path='/report/:type' element={<ReportPage />} />
          <Route path='/report/:type/:value' element={<ReportPage />} />
          <Route path='/report' element={<ReportPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
