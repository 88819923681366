import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import '../styles/User.css';
import { LinearProgress, Button } from '@mui/material';
import { toast } from 'react-toastify';
import PopupCreateUser from '../screens/user/UserCreate';
import PopupUserDetail from '../screens/user/UserDetail';
import PopupEditUser from '../screens/user/UserEdit';
import axiosConfig from '../api/axiosConfig';
import Swal from 'sweetalert2';

const UserListLayout = ({ users, getUser, setStatus, setName, currentPage, setCurrentPage, onSearch, setRole, loading }) => {
  const [totalPage, setTotalPage] = useState(0);
  const [showPopupCreate, setShowPopupCreate] = useState(false);
  const [showPopupDetail, setShowPopupDetail] = useState(false);
  const [showPopupEdit, setShowPopupEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const handleCloseCreate = () => {
    getUser();
    setShowPopupCreate(false)
  };
  const handleOpenDetail = (user) => {
    setSelectedUser(user);
    setShowPopupDetail(true);
  };

  const handleCloseDetail = () => {
    setShowPopupDetail(false);
    setSelectedUser(null);
  };

  const handleOpenEdit = (user) => {
    setSelectedUser(user);
    setShowPopupEdit(true);
  };

  const handleCloseEdit = () => {
    getUser();
    setShowPopupEdit(false)
  };

  useEffect(() => {
    if (showPopupCreate || showPopupDetail || showPopupEdit) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showPopupCreate, showPopupDetail, showPopupEdit]);

  const pageSize = users ? users.size : 10;

  useEffect(() => {
    if (users) {
      setTotalPage(calculateTotalPages(users.total, users.size));
    }
  }, [users]);

  const calculateTotalPages = (total, size) => {
    if (size === 0) return 0;
    return Math.ceil(total / size);
  };

  const getVisiblePages = () => {
    const maxButtons = 3;
    const half = Math.floor(maxButtons / 2);
    let startPage, endPage;

    if (totalPage <= maxButtons) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (currentPage <= half) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + half >= totalPage) {
        startPage = totalPage - maxButtons + 1;
        endPage = totalPage;
      } else {
        startPage = currentPage - half;
        endPage = currentPage + half;
      }
    }
    return { startPage, endPage };
  }

  const { startPage, endPage } = getVisiblePages();
  const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

  const handleChangeStatus = async (userId, userName, currentStatus) => {
    const newStatus = currentStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    const result = await Swal.fire({
      title: `<span style="color: #09446d;">Thay đổi trạng thái nhân viên "${userName}"?</span>`,
      text: `Bạn muốn thay đổi trạng thái sang ${newStatus === 'ACTIVE' ? 'Hoạt động' : 'Ngưng hoạt động'}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E03232',
      cancelButtonColor: '#09446D',
      confirmButtonText: 'Thay đổi',
      cancelButtonText: 'Hủy',
    });
    if (result.isConfirmed) {
      try {
        const token = sessionStorage.getItem('token');
        const { data } = await axiosConfig.put(
          `/api/user/change-status-user?id=${userId}`,
          { userId, status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(data);
        getUser();
      } catch (error) {
        toast.error('Không thể thay đổi trạng thái, vui lòng thử lại.');
      }
    }
  };

  return (
    <div className="container">
      <div>
        <h2 className='title-name' style={{ textAlign: "left" }}>Nhân Viên</h2>
      </div>
      <div className="btnSearch">
        <select className="custom-select" name="role" onChange={(e) => setRole(e.target.value)}>
          <option value="" selected>Vai trò</option>
          <option value="ROLE_PRODUCT_OWNER">Chủ cửa hàng</option>
          <option value="ROLE_STOCK">Nhân viên kho</option>
          <option value="ROLE_SALE">Nhân viên bán hàng</option>
        </select>
        <select className="custom-select" name="status" onChange={(e) => setStatus(e.target.value)}>
          <option value="" selected>Trạng thái</option>
          <option value="ACTIVE">Hoạt động</option>
          <option value="INACTIVE">Ngưng hoạt động</option>
        </select>
        <div className="search-input-container">
          <input
            name="name"
            type="text"
            placeholder="Nhập tên nhân viên..."
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearch();
              }
            }}
            autocomplete="off"
          />
          <FontAwesomeIcon icon={faSearch} className="search-icon" onClick={onSearch} />
        </div>
        <div className="btnAdd">
          <Button variant="contained" onClick={() => setShowPopupCreate(true)} style={{ fontSize: '14px', padding: '6px 14px' }}>
            Thêm
          </Button>
        </div>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <table className="list">
          <thead>
            <tr>
              <th>STT</th>
              <th>Tên nhân viên</th>
              <th>Giới tính</th>
              <th>Số điện thoại</th>
              <th>Vai trò</th>
              <th>Trạng thái</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={7} style={{ padding: '20px' }}>
                  <LinearProgress color="inherit" />
                </td>
              </tr>
            ) : (
              users?.size > 0 ? users.listData.map((user, index) => (
                <tr key={user.id}>
                  <td>{(currentPage - 1) * pageSize + index + 1}</td>
                  <td>{user.fullName}</td>
                  <td>{user.gender === 'MALE' ? 'Nam' : 'Nữ'}</td>
                  <td>{user.phone}</td>
                  <td>
                    {user.rolesDto && user.rolesDto.map((r, index) => (
                      <span key={index}>
                        {index >= 1 && ', '}
                        {r.name === 'ROLE_PRODUCT_OWNER' ? 'Chủ cửa hàng' :
                          r.name === 'ROLE_STOCK' ? 'Nhân viên kho' :
                            r.name === 'ROLE_SALE' ? 'Nhân viên bán hàng' : ''}
                      </span>
                    ))}
                  </td>
                  <td>
                    <button
                      style={{ border: 'none', cursor: 'pointer', transition: 'transform 0.1s ease' }}
                      className={user.status === 'ACTIVE' ? 'status-active' : 'status-inactive'}
                      disabled={!sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER')} 
                      onClick={(e) => {
                        e.target.style.transform = 'scale(0.95)';
                        setTimeout(() => {
                          e.target.style.transform = 'scale(1)';
                        }, 100);
                        handleChangeStatus(user.id, user.fullName, user.status);
                      }}
                    >
                      {user.status === 'ACTIVE' ? 'Hoạt động' : 'Ngưng hoạt động'}
                    </button>
                  </td>
                  <td>
                    <button className="detail-btn" onClick={() => handleOpenDetail(user)}>
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                    <button className="edit-btn" onClick={() => handleOpenEdit(user)}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan={7} style={{ padding: '20px' }}>
                    Người dùng không tồn tại
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {totalPage > 1 && (
        <div className="pagination">
          {currentPage === 1 ? (
            <button disabled>&laquo;</button>
          ) : (
            <button onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</button>
          )}
          {startPage > 1 && (
            <>
              <button onClick={() => setCurrentPage(1)}>1</button>
              {startPage > 2 && (
                <button disabled>...</button>
              )}
            </>
          )}
          {visiblePages.map((page) => (
            <button onClick={() => setCurrentPage(page)}
              key={page}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </button>
          ))}
          {endPage < totalPage && (
            <>
              {endPage < totalPage - 1 && (
                <button disabled>...</button>
              )}
              <button onClick={() => setCurrentPage(totalPage)}>{totalPage}</button>
            </>
          )}
          {currentPage === totalPage ? (
            <button disabled>&raquo;</button>
          ) : (
            <button onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</button>
          )}
        </div>
      )}
      {showPopupCreate && (<PopupCreateUser onPopupClose={handleCloseCreate} />)}
      {showPopupDetail && selectedUser && (<PopupUserDetail user={selectedUser} onPopupClose={handleCloseDetail} />)}
      {showPopupEdit && selectedUser && <PopupEditUser user={selectedUser} onPopupClose={handleCloseEdit} />}
    </div>
  );
};

export default UserListLayout;