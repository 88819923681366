import { React } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation, faBoxesPacking } from "@fortawesome/free-solid-svg-icons";
import '../styles/Import.css';

function ExportFooter({ activeItem, setActiveItem }) {
  return (
    <div className="receipt-footer">
      {activeItem === true ? (
        <>
          <div
            className={`receipt-footer-item active`}
            onClick={() => setActiveItem(true)}
          >
            <FontAwesomeIcon icon={faTriangleExclamation} className="receipt-footer-icon" />
            <span style={{ paddingBottom: '3px' }}>Xuất hủy</span>
          </div>
          <div
            className={`receipt-footer-item`}
            onClick={() => setActiveItem(false)}
          >
            <FontAwesomeIcon icon={faBoxesPacking} className="receipt-footer-icon" />
            <span style={{ paddingBottom: '3px' }}>Xuất trả nhà cung cấp</span>
          </div>
        </>
      ) : (
        <>
          <div
            className={`receipt-footer-item`}
            onClick={() => setActiveItem(true)}
          >
            <FontAwesomeIcon icon={faTriangleExclamation} className="receipt-footer-icon" />
            <span style={{ paddingBottom: '3px' }}>Xuất hủy</span>
          </div>
          <div
            className={`receipt-footer-item active`}
            onClick={() => setActiveItem(false)}
          >
            <FontAwesomeIcon icon={faBoxesPacking} className="receipt-footer-icon" />
            <span style={{ paddingBottom: '3px' }}>Xuất trả nhà cung cấp</span>
          </div></>
      )}
    </div>
  );
}

export default ExportFooter;