import React, { useEffect, useState } from 'react';
import '../../styles/User.css';
import DefaultTemplate from '../../templates/DefaultTemplate';
import ImportListLayout from '../../layouts/ImportListLayout';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';

const ImportList = () => {
  const [imports, setImports] = useState([]);
  const [supplierName, setSupplierName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("PENDING");

  const getImports = async (page, supplierName, status, fromDate, toDate) => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
    try {
      let url = `/api/import/get-all-import-paging?page=${page - 1}&size=10`;
      if (supplierName) url += `&supplierName=${supplierName}`;
      if (status) url += `&status=${status}`;
      if (fromDate) url += `&fromDate=${fromDate}`;
      if (toDate) url += `&toDate=${toDate}`;
      console.log("URL:" + url);
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = response.data;
      setImports(data);
    } catch (error) {
      if (error.status === 404) {
        setImports([]);
      } else if (error.status >= 400 && error.status < 500) {
        toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [supplierName, status, fromDate, toDate]);

  useEffect(() => {
    getImports(currentPage, supplierName, status, fromDate, toDate);
  }, [currentPage, supplierName, status, fromDate, toDate]);

  return (
    <DefaultTemplate>
      {imports && (
        <ImportListLayout
          imports={imports}
          getImports={() => getImports(1, supplierName, status, fromDate, toDate)}
          setStatus={setStatus}
          setSupplierName={setSupplierName}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setFromDate={setFromDate}
          setToDate={setToDate}
          loading={loading}
          onSearch={() => {
            setCurrentPage(1);
            getImports(1, supplierName, status, fromDate, toDate);
          }}
        />
      )}
    </DefaultTemplate>
  );
};

export default ImportList;