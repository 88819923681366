import React, { useEffect, useState } from 'react';
import '../../styles/User.css';
import DefaultTemplate from '../../templates/DefaultTemplate';
import ProductListLayout from '../../layouts/ProductListLayout';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';

const ProductList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [productName, setProductName] = useState('');
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('');

  const getProducts = async (page, productName, category, status) => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
    try {
      let url = `/api/product/get-all-product-paging?page=${page - 1}&size=10`;
      if (productName) url += `&productName=${productName}`;
      if (category) url += `&category=${category}`;
      if (status) url += `&status=${status}`;
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      console.log(response);
      setProducts(data);
    } catch (error) {
      if (error.status === 404) {
        setProducts([]);
      } else if (error.status >= 400 && error.status < 500) {
        toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [productName, category, status]);

  useEffect(() => {
    getProducts(currentPage, productName, category, status);
  }, [currentPage, productName, category, status]);

  return (
    <DefaultTemplate>
      {products && (
        <ProductListLayout
          products={products}
          setStatus={setStatus}
          setProductName={setProductName}
          setCategory={setCategory}
          getProducts={() => getProducts(1, productName, category, status)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          onSearch={() => {
            setCurrentPage(1);
            getProducts(1, productName, category, status);
          }}
        />
      )}
    </DefaultTemplate>
  );
};

export default ProductList;
