import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import Select from 'react-select';
import api from '../../api/axiosConfig';

const PopupCreateUser = ({ onPopupClose }) => {
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [gender, setGender] = useState('');
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [cic, setCic] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [note, setNote] = useState('');
    const [username, setUsername] = useState('');
    const [role, setRole] = useState([]);
    const [status, setStatus] = useState('ACTIVE');
    const [avatar, setAvatar] = useState('');
    const [newAvatar, setNewAvatar] = useState('');
    const [downloading, setDownloading] = useState(false);

    const handleDateChange = (event) => {
        setDateOfBirth(event.target.value);
    };

    useEffect(() => {
        if (avatar !== '') {
            setNewAvatar(URL.createObjectURL(avatar));
        }
    }, [avatar]);

    const handleCreate = async (e) => {
        setDownloading(true);
        const formattedDob = dateOfBirth ? new Date(dateOfBirth).toISOString() : '';
        const u = sessionStorage.getItem('username');
        const rolesDto = role.map(roleValue => ({ name: roleValue.value }));

        const userData = {
            fullName: fullName,
            gender: gender ? gender : null,
            dob: formattedDob,
            phone: phone,
            cic: cic,
            email: email,
            address: address,
            note: note,
            username: username,
            status: status,
            rolesDto: rolesDto,
            createBy: u ? u : 'anonymous'
        };
        const token = sessionStorage.getItem('token');
        if (userData.id === null) {
            toast.error("Thiếu ID người dùng.");
            return;
        }
        try {
            e.preventDefault();
            const formData = new FormData();
            formData.append('userDto', new Blob([JSON.stringify(userData)], { type: 'application/json' }));
            const maxFileSize = 2 * 1024 * 1024;
            if (avatar && avatar !== '') {
                if (avatar.size > maxFileSize) {
                    toast.error("Kích thước file phải bé hơn 2MB");
                    return;
                }
                formData.append('file', avatar);
            }
            console.log(userData);
            const { data } = await api.post(`api/user/create-user`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            onPopupClose();
            toast.success(data);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error('Không thể tạo người dùng, vui lòng thử lại.');
            }
        } finally {
            setDownloading(false);
        }
    };

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            width: '100%',
        }),
        control: (provided) => ({
            ...provided,
            width: '85%',
            padding: '0',
            border: '1px solid #ccc',
            borderRadius: '2px',
            fontSize: '14px',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#f3f3f3',
            color: '#333',
            borderRadius: '3px',
            padding: '3px',
            margin: '2px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#333',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#333',
            cursor: 'pointer',
        }),
    };

    const allOptions = [
        { value: 'ROLE_PRODUCT_OWNER', label: 'Chủ cửa hàng' },
        { value: 'ROLE_SALE', label: 'Nhân viên bán hàng' },
        { value: 'ROLE_STOCK', label: 'Nhân viên kho' }
    ];

    const [options, setOptions] = useState(allOptions);

    const handleRoleChange = (selectedRoles) => {
        setRole(selectedRoles);
        if (selectedRoles.some(role => role.value === 'ROLE_PRODUCT_OWNER')) {
            setOptions([{ value: 'ROLE_PRODUCT_OWNER', label: 'Chủ cửa hàng' }]);
        } else {
            const nonStoreOwnerRoles = selectedRoles.filter(role => role.value !== 'ROLE_PRODUCT_OWNER');
            if (nonStoreOwnerRoles.length > 0) {
                setOptions(allOptions.filter(option => option.value !== 'ROLE_PRODUCT_OWNER'));
            } else {
                setOptions(allOptions);
            }
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setAvatar(file);
    };

    return (
        <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            textAlign: 'start',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div style={{ width: '70%', maxHeight: '80vh', overflowY: 'auto', backgroundColor: 'white', textAlign: 'start', padding: '15px 25px', borderRadius: '10px' }}>
                <form onSubmit={handleCreate}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}></div>
                        <div style={{ flex: 1 }}>
                            <h2 style={{ textAlign: 'center', margin: '10px 0' }}>Thêm Nhân Viên</h2>
                        </div>
                        <div style={{ flex: 1, textAlign: 'end' }}>
                            <FontAwesomeIcon onClick={onPopupClose} style={{ fontSize: '30px', cursor: 'pointer' }} icon={faXmark} />
                        </div>
                    </div>
                    <p style={{ fontSize: '15px', color: '#09446d', fontWeight: 'bold', marginLeft: '30px' }}>Thông tin nhân viên</p>
                    <div style={{ marginRight: '40px' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: '2' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='fieldText' style={{ flex: '1' }}>
                                        <span><span style={{ color: 'red' }}>*</span><strong>Tên nhân viên</strong></span>
                                    </div>
                                    <div className='fieldInput' style={{ flex: '2' }}>
                                        <input type='text' required value={fullName} onChange={(e) => setFullName(e.target.value)} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='fieldText' style={{ flex: '1' }}>
                                        <span><span style={{ color: 'red' }}>*</span><strong>Giới tính</strong></span>
                                    </div>
                                    <div className='fieldInput' style={{ flex: '2' }}>
                                        <select style={{ width: '94.5%' }} required value={gender} onChange={(e) => setGender(e.target.value)}>
                                            <option value="" disabled>Chọn giới tính</option>
                                            <option value="MALE">Nam</option>
                                            <option value="FEMALE">Nữ</option>
                                        </select>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='fieldText' style={{ flex: '1' }}>
                                        <span><span style={{ color: 'red' }}>*</span><strong>Ngày sinh</strong></span>
                                    </div>
                                    <div className='fieldInput' style={{ flex: '2' }}>
                                        <input type='date' required value={dateOfBirth} onChange={handleDateChange} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ flex: '1' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        {newAvatar !== '' ? (
                                            <>
                                                <div style={{
                                                    display: "inline-block", width: "120px", height: "120px", backgroundImage: `url(${newAvatar})`,
                                                    backgroundSize: 'cover', backgroundPosition: 'center', border: '2px solid #709dc1', borderRadius: '50%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                                                }}>
                                                </div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id="fileInput"
                                                    onChange={(e) => handleImageChange(e)}
                                                />
                                                <label className='changeAvatarBtn' htmlFor="fileInput">
                                                    Thay ảnh
                                                </label>
                                            </>
                                        ) : (
                                            <>
                                                <div style={{ width: "120px", height: "120px", border: 'solid 2px #709dc1', borderRadius: '50%', backgroundColor: "transparent", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <FontAwesomeIcon icon={faUser} size="5x" style={{ color: "#709dc1" }} />
                                                </div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id="fileInput"
                                                    onChange={(e) => handleImageChange(e)}
                                                />
                                                <label className='changeAvatarBtn' htmlFor="fileInput">
                                                    Thêm ảnh
                                                </label>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='fieldText' style={{ flex: '1' }}>
                                <span><span style={{ color: 'red' }}>*</span><strong>Số điện thoại</strong></span>
                            </div>
                            <div className='fieldInput' style={{ flex: '3.5' }}>
                                <input type='number' required value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='fieldText' style={{ flex: '1' }}>
                                <span><span style={{ color: 'red' }}>*</span><strong>CCCD</strong></span>
                            </div>
                            <div className='fieldInput' style={{ flex: '3.5' }}>
                                <input type='number' required value={cic} onChange={(e) => setCic(e.target.value)} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='fieldText' style={{ flex: '1' }}>
                                <span><span style={{ color: 'red' }}>*</span><strong>Email</strong></span>
                            </div>
                            <div className='fieldInput' style={{ flex: '3.5' }}>
                                <input type='text' required value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='fieldText' style={{ flex: '1' }}>
                                <span><span style={{ color: 'red' }}>*</span><strong>Địa chỉ</strong></span>
                            </div>
                            <div className='fieldInput' style={{ flex: '3.5' }}>
                                <input type='text' required value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='fieldText' style={{ flex: '1' }}>
                                <span><strong>Ghi chú</strong></span>
                            </div>
                            <div className='fieldInput' style={{ flex: '3.5' }}>
                                <input type='text' value={note} onChange={(e) => setNote(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <p style={{ fontSize: '15px', color: '#09446d', fontWeight: 'bold', marginLeft: '30px' }}>Thông tin đăng nhập</p>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: '1' }}>
                            <div style={{ display: 'flex', alignItems: 'center', width: '90%' }}>
                                <div className='fieldText' style={{ flex: '1' }}>
                                    <span><span style={{ color: 'red' }}>*</span><strong>Tên đăng nhập</strong></span>
                                </div>
                                <div className='fieldInput' style={{ flex: '1.1' }}>
                                    <input required style={{ width: '92%' }} type='text' value={username} onChange={(e) => setUsername(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '90%' }}>
                                <div className='fieldText' style={{ flex: '1' }}>
                                    <span><span style={{ color: 'red' }}>*</span><strong>Trạng thái</strong></span>
                                </div>
                                <div className='fieldInput' style={{ flex: '1.1' }}>
                                    <select required style={{ width: '100%' }} value={status} onChange={(e) => setStatus(e.target.value)}>
                                        <option value="ACTIVE">Hoạt động</option>
                                        <option value="INACTIVE">Ngưng hoạt động</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: '1' }}>
                            <div style={{ display: 'flex', alignItems: 'center', width: '90%' }}>
                                <div className='fieldText' style={{ flex: '1' }}>
                                    <span><span style={{ color: 'red' }}>*</span><strong>Vai trò</strong></span>
                                </div>
                                <div className='fieldInput' style={{ flex: '2' }}>
                                    <Select
                                        value={role}
                                        onChange={handleRoleChange}
                                        options={options}
                                        isMulti
                                        required
                                        styles={customStyles}
                                        placeholder="Chọn vai trò"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div style={{ margin: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" className='btn' onClick={handleCreate}>
                        Thêm
                    </Button>
                    <Button variant="contained" className='btn' onClick={onPopupClose}>
                        Hủy
                    </Button>
                </div>
            </div>
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
        </div >
    );
};

export default PopupCreateUser;