import React, { useState } from 'react';
import ReceiptTemplate from '../../templates/ReceiptTemplate';
import ExportFooter from '../../layouts/ExportFooter';
import ExportReceiptCreate from './/ExportReceiptCreate';
import ReturnSupplierCreate from './/ReturnSupplierCreate';
import { useParams } from 'react-router-dom';

const ExportCreate = () => {
    const { type } = useParams();
    const [activeItem, setActiveItem] = useState(type !== 'return');

    return (
        <ReceiptTemplate>
            {activeItem === true ? (
                <ExportReceiptCreate />
            ) : (
                <ReturnSupplierCreate />
            )}
            <ExportFooter activeItem={activeItem} setActiveItem={setActiveItem} />
        </ReceiptTemplate >
    );
};

export default ExportCreate;