import React from 'react';
import '../styles/StartPage.css';

const Background = () => {
    return (
        <div className='content'>
            <div className="pharmacy-text">
                <p>Quầy Thuốc Lê Thị Hằng</p>
                <p>Hoằng Thành - Hoằng Hóa - Thanh Hóa</p>
            </div>
        </div>
    );
};

export default Background;