import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark, faBell, faUser, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/logo.png';
import '../styles/Navbar.css';
import { Badge, Chip } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../api/axiosConfig';

function Navbar2() {
    const [navbarExpanded, setNavbarExpanded] = useState(false);
    const [sanPhamDropdown, setSanPhamDropdown] = useState(false);
    const [nhapXuatDropdown, setNhapXuatDropdown] = useState(false);
    const [baoCaoDropdown, setBaoCaoDropdown] = useState(false);
    const [moiQuanHeDropdown, setMoiQuanHeDropdown] = useState(false);
    const [isSettingsOpen, setSettingsOpen] = useState(false);
    const [isNotificationOpen, setNotificationOpen] = useState(false);
    const id = sessionStorage.getItem('id');
    const username = sessionStorage.getItem('username');
    const roles = sessionStorage.getItem('roles');
    const avatar = sessionStorage.getItem('avatar');
    const [notifications, setNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [notificationType, setNotificationType] = useState('all');
    const navigate = useNavigate();
    const notificationTypes = [
        { type: 'all', label: 'Tất cả' },
        { type: 'stockInOut', label: 'Nhập xuất' },
        { type: 'outOfStock', label: 'Hết hàng' },
        { type: 'expired', label: 'Hết hạn' },
        { type: 'unread', label: 'Chưa đọc' },
    ];

    const handleToggleNavbar = () => {
        setNavbarExpanded(!navbarExpanded);
    };

    const handleSanPhamDropdown = () => {
        setSanPhamDropdown(!sanPhamDropdown);
    };

    const handleNhapXuatDropdown = () => {
        setNhapXuatDropdown(!nhapXuatDropdown);
    };

    const handleBaoCaoDropdown = () => {
        setBaoCaoDropdown(!baoCaoDropdown);
    };

    const handleMoiQuanHeDropdown = () => {
        setMoiQuanHeDropdown(!moiQuanHeDropdown);
    };

    const toggleSetting = () => {
        setSettingsOpen(!isSettingsOpen);
    };

    const toggleNotification = () => {
        setNotificationOpen(!isNotificationOpen);
    };

    const handleRedirect = async (redirect, notificationId) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/notifications/${notificationId}/read`;
            await api.put(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(redirect);
            navigate(redirect);
            getNotifications();
            getUnreadNotifications();
        } catch (error) {
            if (error.status === 404) {
                setNotifications([]);
            } else if (error !== 404 && error.status >= 400 && error.status < 500) {
                toast.error("Lỗi khi tải dữ liệu. Vui lòng thử lại sau!");
            }
        }
    }

    const getNotifications = async (type) => {
        const token = sessionStorage.getItem('token');
        if (id) {
            try {
                let url = `/api/notifications/notification/user?userId=${id}`;
                if (type === 'stockInOut') {
                    url += `&notificationType=STOCK_IN_OUT`;
                } else if (type === 'expired') {
                    url += `&notificationType=EXPIRED`;
                } else if (type === 'outOfStock') {
                    url += `&notificationType=OUT_OF_STOCK`;
                }
                const response = await api.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = response.data;
                setNotifications(data);
            } catch (error) {
                if (error.status === 404) {
                    setNotifications([]);
                } else if (error !== 404 && error.status >= 400 && error.status < 500) {
                    toast.error("Lỗi khi tải dữ liệu. Vui lòng thử lại sau!");
                }
            }
        }
    }

    const getUnreadNotifications = async () => {
        const token = sessionStorage.getItem('token');
        if (id) {
            try {
                let url = `/api/notifications/notification/user?userId=${id}&isRead=false`;
                const response = await api.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = response.data;
                setUnreadNotifications(data);
            } catch (error) {
                if (error.status === 404) {
                    setUnreadNotifications([]);
                } else if (error !== 404 && error.status >= 400 && error.status < 500) {
                    toast.error("Lỗi khi tải dữ liệu. Vui lòng thử lại sau!");
                }
            }
        }
    }

    useEffect(() => {
        const handleNotification = (event) => {
            if (event.data.type === 'NOTIFICATION_RECEIVED') {
                getNotifications();
                getUnreadNotifications();
            }
        };
        getUnreadNotifications();
        getNotifications();
        navigator.serviceWorker.addEventListener('message', handleNotification);
        return () => {
            navigator.serviceWorker.removeEventListener('message', handleNotification);
        };
    }, []);

    useEffect(() => {
        getNotifications(notificationType);
    }, [notificationType])

    const timeDifferenceFromNow = (timestamp) => {
        const now = new Date();
        const targetDate = new Date(parseFloat(timestamp) * 1000);
        const diffInSeconds = Math.floor((now - targetDate) / 1000);
        const secondsInMinute = 60;
        const secondsInHour = 60 * secondsInMinute;
        const secondsInDay = 24 * secondsInHour;
        const secondsInMonth = 30 * secondsInDay;
        const secondsInYear = 12 * secondsInMonth;
        if (diffInSeconds < secondsInMinute) {
            return `${diffInSeconds} giây`;
        } else if (diffInSeconds < secondsInHour) {
            const minutes = Math.floor(diffInSeconds / secondsInMinute);
            return `${minutes} phút`;
        } else if (diffInSeconds < secondsInDay) {
            const hours = Math.floor(diffInSeconds / secondsInHour);
            return `${hours} giờ`;
        } else if (diffInSeconds < secondsInMonth) {
            const days = Math.floor(diffInSeconds / secondsInDay);
            return `${days} ngày`;
        } else if (diffInSeconds < secondsInYear) {
            const months = Math.floor(diffInSeconds / secondsInMonth);
            return `${months} tháng`;
        } else {
            const years = Math.floor(diffInSeconds / secondsInYear);
            return `${years} năm`;
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', background: '#09446d', justifyContent: 'space-between', alignItems: 'center', padding: '0 0.5rem' }}>
                {id && (
                    <div>
                        <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={handleToggleNavbar}>
                            <FontAwesomeIcon style={{ color: 'white' }} icon={navbarExpanded ? faXmark : faBars} />
                        </button>
                    </div>
                )}
                <div>
                    <a href="/" className="navbar-logo">
                        <img src={logo} alt="Pharmacy Logo" />
                    </a>
                </div>
                {id ? (
                    <div className="navbar-icons">
                        {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                            <div>
                                <button onClick={toggleNotification} className='dropdown-button'>
                                    <Badge color="error" badgeContent={unreadNotifications?.totalElements}>
                                        <FontAwesomeIcon icon={faBell} style={{ paddingTop: '2px', paddingBottom: '2px' }} />
                                    </Badge>
                                </button>
                                {isNotificationOpen && (
                                    <div className="dropdown-content" style={{ right: '20px', width: '450px', maxHeight: '400px', overflowY: 'auto', top: '50px', color: 'black' }}>
                                        <h1 style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'left', color: '#09446D', paddingLeft: '20px' }}>Thông báo</h1>
                                        <div style={{ display: 'flex', padding: '0 5px' }}>
                                            {notificationTypes.map(({ type, label }) => (
                                                <Chip
                                                    key={type}
                                                    onClick={type !== notificationType ? () => setNotificationType(type) : undefined}
                                                    style={{ margin: '0 5px', cursor: 'pointer' }}
                                                    label={label}
                                                    variant={type === notificationType ? undefined : 'outlined'}
                                                />
                                            ))}
                                        </div>
                                        {notificationType === 'unread' ? (
                                            unreadNotifications?.content?.length > 0 ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    {unreadNotifications.content?.map((data, index) => (
                                                        <button
                                                            key={index}
                                                            onClick={() => handleRedirect(data?.url, data?.id)}
                                                            className={`notification-item ${data.isRead ? 'read' : 'unread'}`}
                                                        >
                                                            <div className='notification-content'>
                                                                <h4 className='notification-title'>{data?.title}</h4>
                                                                <p className='notification-body'>{data?.message}</p>
                                                                <p className='notification-time'>{data?.createdAt ? timeDifferenceFromNow(data?.createdAt) : ''}</p>
                                                            </div>
                                                        </button>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <p>Không có thông báo</p>
                                                </div>
                                            )
                                        ) : (
                                            notifications?.content?.length > 0 ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    {notifications.content?.map((data, index) => (
                                                        <button
                                                            key={index}
                                                            onClick={() => handleRedirect(data?.url, data?.id)}
                                                            className={`notification-item ${data.isRead ? 'read' : 'unread'}`}
                                                        >
                                                            <div className='notification-content'>
                                                                <h4 className='notification-title'>{data?.title}</h4>
                                                                <p className='notification-body'>{data?.message}</p>
                                                                <p className='notification-time'>{data?.createdAt ? timeDifferenceFromNow(data?.createdAt) : ''}</p>
                                                            </div>
                                                        </button>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <p>Không có thông báo</p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                            </div>)}
                        <div>
                            <button onClick={toggleSetting} className='dropdown-button'>
                                <FontAwesomeIcon icon={faUser} style={{ paddingTop: '2px', paddingBottom: '2px' }} />
                            </button>
                            {isSettingsOpen && (
                                <div className="dropdown-content" style={{ right: '20px', top: '50px' }}>
                                    <div className="user-info">
                                        <div className="user-avatar">
                                            {avatar ? (
                                                <>
                                                    <img src={avatar} alt="Avatar" className="avatar" />
                                                </>
                                            ) : (
                                                <>
                                                    <div className="avatar" style={{ border: 'solid 2px #709dc1', backgroundColor: "transparent", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <FontAwesomeIcon icon={faUser} size="2x" style={{ color: "#709dc1" }} />
                                                    </div>
                                                </>
                                            )}
                                            <span className="status-indicator"></span>
                                        </div>
                                        <div>
                                            <strong>{username}</strong><br />
                                            <strong>
                                                {Array.isArray(roles)
                                                    ? roles
                                                        .map((role) => {
                                                            if (role === 'ROLE_PRODUCT_OWNER') return 'Chủ cửa hàng';
                                                            if (role === 'ROLE_CUSTOMER') return 'Khách hàng';
                                                            return 'Nhân viên';
                                                        })
                                                        .join(', ')
                                                    : roles === 'ROLE_PRODUCT_OWNER'
                                                        ? 'Chủ cửa hàng'
                                                        : roles === 'ROLE_CUSTOMER'
                                                            ? 'Khách hàng'
                                                            : 'Nhân viên'}
                                            </strong>
                                        </div>
                                    </div>
                                    <hr />
                                    <a href="/change-password">Đổi mật khẩu</a>
                                    <a href="#" onClick={() => {
                                        sessionStorage.clear();
                                        window.location.href = '/login';
                                    }}>
                                        Đăng xuất
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <button className="login-btn">
                        <a href="/login">
                            <span className="icon">
                                <AccountCircle />
                            </span>
                            Đăng nhập
                        </a>
                    </button>
                )}
            </div>
            {navbarExpanded && (
                <div
                    style={{ position: 'fixed', background: '#09446d', top: '73px', left: '0', overflowY: 'auto', width: '250px', height: '100vh', zIndex: '1000' }}>
                    <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
                        <button className="sidebar-btn" onClick={handleSanPhamDropdown}>
                            <span>Sản Phẩm</span>
                            <FontAwesomeIcon icon={sanPhamDropdown ? faChevronDown : faChevronRight} />
                        </button>
                        {sanPhamDropdown && (
                            <div style={{ paddingLeft: '0.75rem', width: '79%' }}>
                                <a href="/products" className="sidebar-btn">Danh sách sản phẩm</a>
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                                    <a href="/type" className="sidebar-btn">Nhóm sản phẩm</a>)}
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                                    <a href="/unit" className="sidebar-btn">Đơn vị sản phẩm</a>)}
                            </div>
                        )}
                        <button onClick={handleNhapXuatDropdown} className="sidebar-btn">
                            <span>Nhập Xuất</span>
                            <FontAwesomeIcon icon={nhapXuatDropdown ? faChevronDown : faChevronRight} />
                        </button>
                        {nhapXuatDropdown && (
                            <div style={{ paddingLeft: '0.75rem', width: '79%' }}>
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                                    <a href="/import/receipt/create" className="sidebar-btn">Nhập kho</a>)}
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                                    <a href="/export/create/receipt" className="sidebar-btn">Xuất kho</a>)}
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                                    <a href="/sale/create/receipt" className="sidebar-btn">Bán hàng</a>)}
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                                    <a href="/return-order/create" className="sidebar-btn">Trả hàng</a>)}
                            </div>
                        )}
                        <button className="sidebar-btn" onClick={handleBaoCaoDropdown}>
                            <span>Báo cáo</span>
                            <FontAwesomeIcon icon={baoCaoDropdown ? faChevronDown : faChevronRight} />
                        </button>
                        {baoCaoDropdown && (
                            <div style={{ paddingLeft: '0.75rem', width: '79%' }}>
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                                    <a href='/report/sale' className="sidebar-btn">Bán Hàng</a>)}
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                                    <a href='/report/customer' className="sidebar-btn">Khách Hàng</a>)}
                                {sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') && (
                                    <a href='/report/supplier' className="sidebar-btn">Nhà Cung Cấp</a>)}
                                {sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') && (
                                    <a href='/report/income' className="sidebar-btn">Thu Chi</a>)}
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                                    <a href='/report/inventory' className="sidebar-btn">Kho Hàng</a>)}
                            </div>
                        )}
                        <button className="sidebar-btn" onClick={handleMoiQuanHeDropdown}>
                            <span>Mối quan hệ</span>
                            <FontAwesomeIcon icon={moiQuanHeDropdown ? faChevronDown : faChevronRight} />
                        </button>
                        {moiQuanHeDropdown && (
                            <div style={{ paddingLeft: '0.75rem', width: '79%' }}>
                                {sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') && (
                                    <a href="/user" className="sidebar-btn">Nhân viên</a>)}
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                                    <a href="/customer" className="sidebar-btn">Khách hàng</a>)}
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
                                    <a href="/supplier" className="sidebar-btn">Nhà cung cấp</a>)}
                                {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
                                    <a href="/doctor" className="sidebar-btn">Bác sĩ</a>)}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Navbar2;
