import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import '../../styles/Report.css';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { Tab, Tabs } from '@mui/material';
import ReportInventory from "../../layouts/ReportInventory";
import ReportSupplier from "../../layouts/ReportSupplier";
import ReportIncome from "../../layouts/ReportIncome";
import ReportCustomer from "../../layouts/ReportCustomer";
import ReportSale from "../../layouts/ReportSale";
import { useParams } from "react-router-dom";

const ReportPage = () => {
  const { type } = useParams();
  const { value } = useParams();
  const [dateRange, setDateRange] = useState(null);
  const [dateRangeApi, setDateRangeApi] = useState(null);
  const [currentTab, setCurrentTab] = useState('sale');
  const [status, setStatus] = useState('inventory');

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (type === 'sale') {
      setCurrentTab('sale');
    } else if (type === 'customer') {
      setCurrentTab('customer');
    } else if (type === 'supplier') {
      setCurrentTab('supplier');
    } else if (type === 'income') {
      setCurrentTab('income');
    } else if (type === 'inventory') {
      setCurrentTab('inventory');
    } else {
      setCurrentTab('sale');
    }
  }, [type])

  useEffect(() => {
    setStatus(value);
  }, [value]);

  return (
    <DefaultTemplate>
      <div className="container">
        <div className="header">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 className='title-name' style={{ marginBottom: '10px' }}>Báo Cáo</h2>
            <div className="date-range-picker" style={{ marginLeft: '50px', border: 'none', height: '20px' }}>
              <Flatpickr
                value={dateRange}
                onChange={([startDate, endDate]) => {
                  setDateRange([startDate, endDate])
                  setDateRangeApi(null)
                }}
                options={{
                  mode: "range",
                  dateFormat: "d/m/Y",
                  maxDate: "today",
                  locale: {
                    rangeSeparator: " ~ ",
                  },
                }}
                placeholder="__/__/____ ~ __/__/____"
              />
              <span className="icon">&#x1F4C5;</span>
            </div>
          </div>
          <Tabs value={currentTab} onChange={handleTabChange}>
            {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
              <Tab style={{ padding: '12px' }} label="Bán Hàng" value={'sale'} />)}
            {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_SALE')) && (
              <Tab style={{ padding: '12px' }} label="Khách Hàng" value={'customer'} />)}
            {sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') && (
              <Tab style={{ padding: '12px' }} label="Nhà Cung Cấp" value={'supplier'} />)}
            {sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') && (
              <Tab style={{ padding: '12px' }} label="Thu Chi" value={'income'} />)}
            {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
              <Tab style={{ padding: '12px' }} label="Kho Hàng" value={'inventory'} />)}
          </Tabs>
        </div>
        <div className="tab-content">
          {currentTab === 'sale' && <ReportSale dateRange={dateRangeApi ? dateRangeApi : dateRange} />}
          {currentTab === 'customer' && <ReportCustomer dateRange={dateRangeApi ? dateRangeApi : dateRange} />}
          {currentTab === 'supplier' && <ReportSupplier dateRange={dateRangeApi ? dateRangeApi : dateRange} />}
          {currentTab === 'income' && <ReportIncome dateRange={dateRangeApi ? dateRangeApi : dateRange} />}
          {currentTab === 'inventory' && <ReportInventory status={status} dateRange={dateRangeApi ? dateRangeApi : dateRange} />}
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default ReportPage;
