import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import '../styles/Product.css';
import axiosConfig from '../api/axiosConfig';
import Swal from 'sweetalert2';
import { Box, LinearProgress, Modal, Button } from '@mui/material';
import AddDoctor from '../screens/doctor/AddDoctor';
import EditDoctor from '../screens/doctor/EditDoctor';
import DetailDoctor from '../screens/doctor/DetailDoctor';

const DoctorListLayout = ({ currentPage, setCurrentPage, onSearch, doctors, setDoctorName, getDoctor, setStatus, loading }) => {
  const [totalPage, setTotalPage] = useState(0);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [currentDoctor, setCurrentDoctor] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  const pageSize = doctors ? doctors.size : 10;

  useEffect(() => {
    if (doctors) {
      setTotalPage(calculateTotalPages(doctors.total, doctors.size));
    }
  }, [doctors]);

  const calculateTotalPages = (total, size) => {
    if (size === 0) return 0;
    return Math.ceil(total / size);
  };

  const getVisiblePages = () => {
    const maxButtons = 3;
    const half = Math.floor(maxButtons / 2);
    let startPage, endPage;

    if (totalPage <= maxButtons) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (currentPage <= half) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + half >= totalPage) {
        startPage = totalPage - maxButtons + 1;
        endPage = totalPage;
      } else {
        startPage = currentPage - half;
        endPage = currentPage + half;
      }
    }
    return { startPage, endPage };
  };

  const { startPage, endPage } = getVisiblePages();
  const visiblePages = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  const handleNavigation = () => {
    setIsOpenAdd(true);
  };

  const handleEditDoctor = (doctor) => {
    setCurrentDoctor(doctor);
    setIsOpenEdit(true);
  };

  const handleCloseAdd = () => {
    setIsOpenAdd(false);
    getDoctor();
  };

  const handleCloseEdit = () => {
    setIsOpenEdit(false);
    setCurrentDoctor(null);
  };

  const handleOpenDetail = (doctor) => {
    setCurrentDoctor(doctor);
    setIsOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setIsOpenDetail(false);
    setCurrentDoctor(null);
  };

  const handleChangeStatus = async (doctorId, doctorName, currentStatus) => {
    const newStatus = currentStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    const result = await Swal.fire({
      title: `<span style="color: #09446d;">Thay đổi trạng thái bác sĩ "${doctorName}"?</span>`,
      text: `Bạn muốn thay đổi trạng thái sang ${newStatus === 'ACTIVE' ? 'Hoạt động' : 'Ngưng hoạt động'}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E03232',
      cancelButtonColor: '#09446D',
      confirmButtonText: 'Thay đổi',
      cancelButtonText: 'Hủy',
    });
    if (result.isConfirmed) {
      try {
        const token = sessionStorage.getItem('token');
        const { data } = await axiosConfig.put(
          `/api/doctor/change-status-doctor?id=${doctorId}`,
          { doctorId, status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(data);
        getDoctor();
      } catch (error) {
        toast.error('Không thể thay đổi trạng thái, vui lòng thử lại.');
      }
    }
  };
  return (
    <div className='container'>
      <div className='header'>
        <h2 className='title-name'>Bác Sĩ</h2>
        <div className='btnSearch'>
          <select className="custom-select" name="status" onChange={(e) => setStatus(e.target.value)}>
            <option value="" selected>Trạng thái</option>
            <option value="ACTIVE">Hoạt động</option>
            <option value="INACTIVE">Ngưng hoạt động</option>
          </select>
          <div className='search-input-container'>
            <input
              name='doctorName'
              type='text'
              placeholder='Nhập tên bác sĩ...'
              onChange={(e) => setDoctorName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearch();
                }
              }}
              autocomplete="off"
            />
            <FontAwesomeIcon icon={faSearch} className='search-icon' onClick={onSearch} />
          </div>
          <div className="btnAdd">
            <Button variant="contained" onClick={handleNavigation} style={{ fontSize: '14px', padding: '6px 14px' }}>
              Thêm
            </Button>
          </div>
        </div>
        <Modal
          open={isOpenAdd}
          onClose={handleCloseAdd}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box>
            <AddDoctor onCancel={handleCloseAdd} />
          </Box>
        </Modal>

        <Modal
          open={isOpenEdit}
          onClose={handleCloseEdit}
          aria-labelledby='modal-edit-title'
          aria-describedby='modal-edit-description'
        >
          <Box>
            <EditDoctor
              doctor={currentDoctor}
              onCancel={handleCloseEdit}
            />
          </Box>
        </Modal>
        <Modal
          open={isOpenDetail}
          onClose={handleCloseDetail}
          aria-labelledby='modal-detail-title'
          aria-describedby='modal-detail-description'
        >
          <Box>
            {currentDoctor ? (
              <DetailDoctor
                doctor={currentDoctor}
                onCancel={handleCloseDetail}
              />
            ) : (
              <div>Loading...</div>
            )}
          </Box>
        </Modal>
      </div>
      <table className='list'>
        <thead>
          <tr>
            <th>STT</th>
            <th>Tên bác sĩ</th>
            <th>Số điện thoại</th>
            <th>Nơi công tác</th>
            <th>Ghi chú</th>
            <th>Trạng thái</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={7} style={{ padding: '20px' }}>
                <LinearProgress color="inherit" />
              </td>
            </tr>
          ) : (
            doctors.length !== 0 ? (
              doctors.listData.map((doctor, index) => (
                <tr key={doctor.id}>
                  <td>{(currentPage - 1) * pageSize + index + 1}</td>
                  <td>{doctor.fullName}</td>
                  <td>{doctor.phoneNumber || 'N/A'}</td>
                  <td>{doctor.address}</td>
                  <td>{doctor.note || 'N/A'}</td>
                  <td>
                    <button
                      style={{ border: 'none', cursor: 'pointer', transition: 'transform 0.1s ease' }}
                      className={doctor.status === 'ACTIVE' ? 'status-active' : 'status-inactive'}
                      disabled={!sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER')} 
                      onClick={(e) => {
                        e.target.style.transform = 'scale(0.95)';
                        setTimeout(() => {
                          e.target.style.transform = 'scale(1)';
                        }, 100);
                        handleChangeStatus(doctor.id, doctor.fullName, doctor.status);
                      }}
                    >
                      {doctor.status === 'ACTIVE' ? 'Hoạt động' : 'Ngưng hoạt động'}
                    </button>
                  </td>
                  <td>
                    <button className="detail-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenDetail(doctor);
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                    <button className="edit-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        handleEditDoctor(doctor);
                      }}
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} style={{ textAlign: 'center' }}>
                  Bác sĩ không tồn tại
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
      {totalPage > 1 && (
        <div className="pagination">
          {currentPage === 1 ? (
            <button disabled>&laquo;</button>
          ) : (
            <button onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</button>
          )}
          {startPage > 1 && (
            <>
              <button onClick={() => setCurrentPage(1)}>1</button>
              {startPage > 2 && (
                <button disabled>...</button>
              )}
            </>
          )}
          {visiblePages.map((page) => (
            <button onClick={() => setCurrentPage(page)}
              key={page}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </button>
          ))}
          {endPage < totalPage && (
            <>
              {endPage < totalPage - 1 && (
                <button disabled>...</button>
              )}
              <button onClick={() => setCurrentPage(totalPage)}>{totalPage}</button>
            </>
          )}
          {currentPage === totalPage ? (
            <button disabled>&raquo;</button>
          ) : (
            <button onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</button>
          )}
        </div>
      )}
    </div>
  );
};

export default DoctorListLayout;
