import React, { useState } from 'react';
import LoginTemplate from '../../templates/LoginTemplate';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import api from "../../api/axiosConfig";
import '../../styles/Login.css';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
    const [formData, setFormData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const togglePasswordVisibility3 = () => {
        setShowPassword3(!showPassword3);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (!formData.oldPassword || !formData.newPassword || !formData.confirmPassword) {
            toast.error("Thông tin không được trống");
            return;
        }

        try {
            const token = sessionStorage.getItem('token');
            const response = await api.put(`/api/user/reset-password`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    token: token,
                },
            });

            if (response.status === 200) {
                setTimeout(() => {
                    toast.success("Đổi mật khẩu thành công");
                }, 500);
                navigate('/login');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Không thể đổi mật khẩu, vui lòng thử lại.');
            }
        }
    };

    return (
        <LoginTemplate>
            <div className="login-container">
                <ToastContainer />
                <div className="background-image" />
                <form className="form-contain" onSubmit={handleChangePassword}>
                    <div className="logo-container">
                        <img src={require('../../assets/images/logo1.png')} alt="Pharmacy Logo" className="logo" />
                    </div>
                    <div className="input-field">
                        <input
                            id="oldPassword"
                            name="oldPassword"
                            type={showPassword ? "text" : "password"}
                            placeholder="Mật khẩu hiện tại"
                            className="input-element"
                            style={{ paddingLeft: '0' }}
                            onChange={handleChange}
                        />
                        <span onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon color='#65676d' icon={showPassword ? faEyeSlash : faEye} />
                        </span>
                    </div>
                    <div className="input-field">
                        <input
                            id="newPassword"
                            name="newPassword"
                            type={showPassword2 ? "text" : "password"}
                            placeholder="Mật khẩu mới"
                            className="input-element"
                            style={{ paddingLeft: '0' }}
                            onChange={handleChange}
                        />
                        <span onClick={togglePasswordVisibility2} style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon color='#65676d' icon={showPassword2 ? faEyeSlash : faEye} />
                        </span>
                    </div>
                    <div className="input-field" style={{ marginBottom: '0' }}>
                        <input
                            id="confirmPassword"
                            name="confirmPassword"
                            type={showPassword3 ? "text" : "password"}
                            placeholder="Xác nhận mật khẩu mới"
                            className="input-element"
                            style={{ paddingLeft: '0' }}
                            onChange={handleChange}
                        />
                        <span onClick={togglePasswordVisibility3} style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon color='#65676d' icon={showPassword3 ? faEyeSlash : faEye} />
                        </span>
                    </div>
                    <div className="forgot-password">
                        <a href="#" onClick={() => { navigate(-1) }}>Quay lại</a>
                    </div>
                    <Button
                        variant="contained"
                        fullWidth
                        className="login-button"
                        type='submit'
                    >
                        Đổi mật khẩu
                    </Button>
                </form>
                <div className="footer-text">
                    <a>
                        Pharmacy © 2024 SEP490_G27
                    </a>
                </div>
            </div>
        </LoginTemplate>
    );
};

export default ChangePassword;
