import React, { useEffect, useState } from 'react';
import '../../styles/User.css';
import DefaultTemplate from '../../templates/DefaultTemplate';
import CustomerLayout from '../../layouts/CustomerLayout';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';

const CustomerList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [status, setStatus] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(true);

  const getCustomer = async (status, phoneNumber, page) => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
    try {
      let url = `/api/customer/get-all-customer-paging?page=${page - 1}&size=10`;
      if (phoneNumber) url += `&keyword=${phoneNumber}`;
      if (status) url += `&status=${status}`;
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setCustomers(data);
    } catch (error) {
      if (error.status === 404) {
        setCustomers([]);
      } else if (error !== 404 && error.status >= 400 && error.status < 500) {
        toast.error("Lỗi khi tải dữ liệu. Vui lòng thử lại sau!");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [status, phoneNumber]);

  useEffect(() => {
    getCustomer(status, phoneNumber, currentPage);
  }, [currentPage, status, phoneNumber]);

  return (
    <DefaultTemplate>
      <CustomerLayout
        customers={customers}
        setStatus={setStatus}
        setPhoneNumber={setPhoneNumber}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        getCustomer={() => getCustomer(status, phoneNumber, 1)}
        loading={loading}
        onSearch={() => {
          setCurrentPage(1);
          getCustomer(status, phoneNumber, 1);
        }}
      />
    </DefaultTemplate>
  );
};

export default CustomerList;
