import React, { useEffect, useRef, useState } from 'react';
import '../../styles/Import.css';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faSearch, faPlus, faTrashCan, faPenToSquare, faSave } from '@fortawesome/free-solid-svg-icons';
import { Button, Box, Modal, TextField, Autocomplete, Divider, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import { format } from 'date-fns';
import PopupAddProduct from '../product/AddProduct';
import AddSupplier from '../supplier/AddSupplier';
import api from '../../api/axiosConfig';

const ImportReceiptCreate = () => {
    const navigate = useNavigate();
    const [showPopupProduct, setShowPopupProduct] = useState(false);
    const [products, setProducts] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [supplierName, setSupplierName] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [unitPrice, setUnitPrice] = useState(0);
    const [productName, setProductName] = useState('');
    const [supplier, setSupplier] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [VAT, setVAT] = useState(0);
    const [minQuantity, setMinQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);
    const [finalDiscount, setFinalDiscount] = useState(0);
    const [formData, setFormData] = useState({});
    const [paymentMethod, setPaymentMethod] = useState('CASH');
    const [note, setNote] = useState(null);
    const [conversionFactor, setConversionFactor] = useState(0);
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [batchNumber, setBatchNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [totalVAT, setTotalVAT] = useState(0);
    const [finalVAT, setFinalVAT] = useState(0);
    const [remainingQuantity, setRemainingQuantity] = useState(null);
    const [indexRow, setIndexRow] = useState(null);
    const [payPrice, setPayPrice] = useState(null);
    const [image, setImage] = useState(null);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [searching, setSearching] = useState(false);
    const [searching1, setSearching1] = useState(false);
    const [downloading, setDownloading] = useState(false);
    let abortController = null;

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setImage(files[0]);
        setUploadedImages(files);
    };

    const handleCloseProduct = (e) => {
        setShowPopupProduct(false)
        if (e != null && e !== undefined) {
            console.log(e);
            const filteredUnit = e?.productUnitList?.filter(unit => unit.conversionFactor > 0)[0];
            setFormData((prevFormData) => ({
                products: [
                    ...(prevFormData.products || []),
                    {
                        id: e.id,
                        batchNumber: '',
                        expiryDate: '',
                        productCode: e.productCode,
                        productName: e.productName,
                        unitName: '',
                        unitPrice: filteredUnit.importPrice,
                        unitName: filteredUnit.unitName,
                        quantity: 1,
                        discount: 0,
                        conversionFactor: filteredUnit.conversionFactor,
                        vat: 0,
                        totalPrice: 0,
                        totalVAT: 0,
                        productUnitList: e.productUnitList,
                        product: {
                            productId: e.id,
                            productCode: e.productCode,
                            productName: e.productName,
                        }
                    }
                ]
            }));
        }
    };

    const getProducts = async (productName, signal) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `api/import/get-product?productName=${productName}`;
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                signal,
            });
            const data = response.data;
            setProducts(data);
        } catch (error) {
            if (error.status === 404) {
                toast.error(error.response.data.message);
            } else if (error.status >= 400 && error.status < 500) {
                toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
            }
        } finally {
            setSearching1(false);
        }
    };

    const getSuppliers = async (supplierName, signal) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/import/get-supplier?supplierName=${supplierName}`;
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                signal,
            });
            const data = response.data;
            setSupplier(data);
            setShowDropdown1(true);
        } catch (error) {
            if (api.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else if (error.response && error.response.status === 404) {
                toast.error(error.response.data.message);
            } else if (error.response && error.response.status >= 400 && error.response.status < 500) {
                toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
            } else {
                console.error('Error fetching suppliers:', error);
            }
        } finally {
            setSearching(false);
        }
    };

    const handleSetSelectedSupplier = (e) => {
        setSupplierName(e.supplierName);
        setSelectedSupplier(e);
    }

    const handlePaymentMethodChange = (e) => { setPaymentMethod(e.target.value) }

    const onSearchChange = (e) => {
        setActiveIndex(-1);
        const value = e.target.value;
        setProductName(value);
        setSelectedProduct(null);
        setShowDropdown(false);
        if (abortController) {
            abortController.abort();
        }
        if (value) {
            setSearching1(true);
            abortController = new AbortController();
            getProducts(value, abortController.signal);
            setShowDropdown(true);
        } else {
            setSearching1(false);
            setTotalVAT(0);
            setShowDropdown(false);
            setSelectedUnit(null);
            setBatchNumber('');
            setExpiryDate('');
            setUnitPrice(0);
            setMinQuantity(0);
            setQuantity(0);
            setRemainingQuantity(0);
            setDiscount(0);
            setVAT(0);
        }
    };

    useEffect(() => {
        const total = unitPrice * quantity;
        const discountValue = 1 - (discount / 100);
        const vatValue = 1 + (VAT / 100);
        const totalPrice = total * discountValue * vatValue;
        const vat = totalPrice - (total * discountValue);
        setTotalVAT(vat);
        setTotalPrice(totalPrice);
    }, [unitPrice, quantity, discount, VAT])

    const handleSupplierChange = (e) => {
        const value = e.target.value;
        setShowDropdown1(false);
        setSupplierName(value);
        setSelectedSupplier(null);

        if (abortController) {
            abortController.abort();
        }

        if (value) {
            setSearching(true);
            abortController = new AbortController();
            getSuppliers(value, abortController.signal);
            setShowDropdown1(true);
        } else {
            setSearching(false);
            setShowDropdown1(false);
        }
    };


    const handleSelectSupplier = (supplier) => {
        setShowDropdown1(false);
        setSelectedSupplier(supplier);
        setSupplierName(supplier.supplierName);
    };

    const handleFieldChange = (index, fieldName, fieldValue) => {
        setFormData((prevFormData) => {
            const updatedProducts = [...prevFormData.products];
            let tempAmount = updatedProducts[index].totalPrice;
            const tempUnitPrice = updatedProducts[index].unitPrice;
            const tempQuantity = updatedProducts[index].quantity;
            const tempTax = updatedProducts[index].vat;
            const tempDiscount = updatedProducts[index].discount;
            if (fieldName === 'unitPrice') {
                tempAmount = calculateTotalAmount(fieldValue, tempQuantity, tempDiscount, tempTax)
            } else if (fieldName === 'quantity') {
                tempAmount = calculateTotalAmount(tempUnitPrice, fieldValue, tempDiscount, tempTax)
            } else if (fieldName === 'discount') {
                tempAmount = calculateTotalAmount(tempUnitPrice, tempQuantity, fieldValue, tempTax)
            } else if (fieldName === 'vat') {
                tempAmount = calculateTotalAmount(tempUnitPrice, tempQuantity, tempDiscount, fieldValue)
            }
            updatedProducts[index] = {
                ...updatedProducts[index],
                [fieldName]: fieldValue,
                totalPrice: tempAmount,
            };
            setFinalDiscount(calculateTotalDiscount(updatedProducts))
            setFinalPrice(calculateTotalPrice(updatedProducts));
            setFinalVAT(calculateTotalTax(updatedProducts));
            setPayPrice(calculatePayPrice(updatedProducts));
            return {
                ...prevFormData,
                products: updatedProducts,
            };
        });
    };

    const addProductToReceipt = () => {
        const total = unitPrice * quantity;
        const discountValue = 1 - (discount / 100);
        const discountedPrice = total * discountValue;
        const productVAT = discountedPrice * (VAT / 100);
        const productTotalPrice = discountedPrice + productVAT;
        setFinalVAT(finalVAT + productVAT);
        setFormData((prevFormData) => ({
            products: [
                ...(prevFormData.products || []),
                {
                    id: selectedProduct.id,
                    batchNumber: batchNumber,
                    expiryDate: expiryDate,
                    productCode: selectedProduct.productCode,
                    productName: selectedProduct.productName,
                    unitName: selectedUnit.unitName,
                    unitPrice: unitPrice,
                    quantity: quantity || 1,
                    discount: discount || 0,
                    conversionFactor: conversionFactor,
                    vat: VAT || 0,
                    totalPrice: productTotalPrice,
                    totalVAT: productVAT,
                    productUnitList: selectedProduct.productUnitList,
                    product: {
                        productId: selectedProduct.id,
                        productCode: selectedProduct.productCode,
                        productName: selectedProduct.productName,
                    }
                }
            ]
        }));
        setIndexRow(formData?.products?.length || 0);
        setFinalPrice(finalPrice + productTotalPrice);
        setProductName('');
        setBatchNumber('');
        setExpiryDate('');
        setSelectedProduct(null);
        setSelectedUnit(null);
        setUnitPrice(0);
        setMinQuantity(0);
        setQuantity(0);
        setRemainingQuantity(0);
        setDiscount(0);
        setConversionFactor(0);
        setVAT(0);
    };

    const handleDeleteProduct = (indexToDelete) => {
        setFinalPrice(finalPrice - formData.products[indexToDelete].totalPrice);
        const updatedProducts = formData.products.filter((_, index) => index !== indexToDelete);
        const updatedFinalVAT = updatedProducts.reduce((acc, product) => acc + product.totalVAT, 0);
        setFormData((prevFormData) => ({
            ...prevFormData,
            products: updatedProducts
        }));
        setFinalVAT(updatedFinalVAT);
    };

    const handleNavigation = () => {
        setIsOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setIsOpenAdd(false);
    };

    const handleSelectItem = (item) => {
        const productExists = formData && formData.products?.some(
            (product) => product.productCode === item.productCode
        );
        if (!productExists) {
            const filteredUnit = item?.productUnitList?.filter(unit => unit.conversionFactor > 0)[0];
            console.log(item);
            setExpiryDate('');
            setSelectedProduct(item);
            setProductName(item.productCode + ' - ' + item.productName);
            setSelectedUnit(filteredUnit);
            setUnitPrice(filteredUnit.importPrice);
            setConversionFactor(filteredUnit.conversionFactor);
            setRemainingQuantity(item.totalQuantity);
            setMinQuantity(1);
            setQuantity(1);
            setShowDropdown(false);
            setDiscount(0);
            setVAT(0);
        } else {
            toast.error("Đã tồn tại sản phẩm trong phiếu nhập.");
        }
    };

    useEffect(() => {
        if (showPopupProduct) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showPopupProduct]);

    const calculateTotalAmount = (unitPrice, quantity, discount, VAT) => {
        const total = unitPrice * quantity;
        const discountValue = 1 - (discount / 100);
        const vatValue = 1 + (VAT / 100);
        const totalPrice = total * discountValue * vatValue;
        return totalPrice;
    };

    const calculateTotalTax = (list) => {
        let totalTax = 0;
        for (var i = 0; i < list.length; i++) {
            const total = list[i].unitPrice * list[i].quantity;
            const vatValue = 1 + (list[i].vat / 100);
            const discountValue = 1 - (list[i].discount / 100);
            const totalPrice = total * vatValue * discountValue;
            const vat = totalPrice - (total * discountValue);
            totalTax += vat;
        }
        return totalTax;
    }

    const calculatePayPrice = (list) => {
        let totalPay = 0;
        for (var i = 0; i < list.length; i++) {
            const total = list[i].unitPrice * list[i].quantity;
            const discountValue = 1 - (list[i].discount / 100);
            const vatValue = 1 + (list[i].vat / 100);
            const totalPrice = total * discountValue * vatValue;
            totalPay += totalPrice;
        }
        return totalPay;
    }

    const calculateTotalPrice = (list) => {
        let totalPrice = 0;
        for (var i = 0; i < list.length; i++) {
            const total = list[i].unitPrice * list[i].quantity;
            totalPrice += total;
        }
        return totalPrice;
    }

    const calculateTotalDiscount = (list) => {
        let totalDiscount = 0;
        for (let i = 0; i < list.length; i++) {
            const total = list[i].unitPrice * list[i].quantity;
            const discountValue = list[i].discount / 100;
            const discount = total * discountValue;
            totalDiscount += discount;
        }
        return totalDiscount;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedSupplier) {
            toast.error("Nhà cung cấp không được trống");
            return;
        }
        setDownloading(true);
        const u = sessionStorage.getItem('id');
        const submitData = new FormData();
        submitData.append(
            "importRequestDto",
            new Blob([JSON.stringify({
                paymentMethod: paymentMethod,
                totalAmount: payPrice,
                note: note,
                tax: finalVAT,
                discount: finalDiscount,
                importItems: formData.products?.map((product) => ({
                    quantity: product.quantity,
                    unitPrice: product.unitPrice,
                    unit: product.unitName,
                    discount: Number(product.discount) || 0,
                    tax: Number(product.vat) || 0,
                    totalAmount: product.totalPrice,
                    productId: product.id,
                    batchNumber: product.batchNumber,
                    expiryDate: new Date(product.expiryDate).getTime(),
                    conversionFactor: product.conversionFactor
                })),
                userId: u,
                importDate: "2024-10-19T11:39:53.673Z",
                supplierId: selectedSupplier.id
            })], { type: 'application/json' })
        );
        submitData.append('image', image);
        const token = sessionStorage.getItem('token');
        try {
            const { data } = await api.post(`api/import/create-import-receipt`, submitData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                }
            });
            setTimeout(() => {
                toast.success(data);
            }, 500);
            navigate(`/import`);
        } catch (err) {
            console.log(err);
            if (err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error('Không thể tạo phiếu nhập, vui lòng thử lại.');
            }
        } finally {
            setDownloading(false);
        }
    };

    const today = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    const [activeIndex, setActiveIndex] = useState(-1);
    const [activeIndex1, setActiveIndex1] = useState(-1);
    const itemRefs = useRef([]);

    useEffect(() => {
        if (activeIndex >= 0 && itemRefs.current[activeIndex]) {
            itemRefs.current[activeIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [activeIndex]);

    useEffect(() => {
        if (activeIndex1 >= 0 && itemRefs.current[activeIndex1]) {
            itemRefs.current[activeIndex1].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [activeIndex1]);

    useEffect(() => {
        if (selectedProduct) {
            addProductToReceipt();
        }
    }, [selectedProduct])

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setShowDropdown(true);
            setActiveIndex((prevIndex) => Math.min(prevIndex + 1, products.length - 1));
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (e.key === "Enter" && activeIndex >= 0) {
            handleSelectItem(products[activeIndex]);
        }
    };

    const handleKeyDown1 = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setShowDropdown1(true);
            setActiveIndex1((prevIndex) => Math.min(prevIndex + 1, supplier.length - 1));
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setActiveIndex1((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (e.key === "Enter" && supplier.length >= 0) {
            handleSelectSupplier(supplier[activeIndex1]);
        }
    };

    return (
        <DefaultTemplate>
            <div className="receipt-container">
                <div className="header">
                    <h3 className='title-name' style={{ marginBottom: '0', marginLeft: '10px' }}>
                        <Link to="/import" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Phiếu Nhập
                        </Link>
                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronRight} />
                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faChevronRight} />
                        Tạo Phiếu Nhập
                    </h3>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '4', textAlign: 'start', margin: '0 10px', borderRadius: '10px' }}>
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <div style={{ padding: '0 5vw 10px 0', display: 'flex', flex: '1' }}>
                                <div className="search-container" style={{ position: 'relative', textAlign: 'start', width: '20vw' }}>
                                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                                    <input
                                        name="supplierName"
                                        type="text"
                                        value={supplierName || selectedSupplier?.supplierName || ''}
                                        placeholder="Nhập tên nhà cung cấp..."
                                        onChange={handleSupplierChange}
                                        onKeyDown={handleKeyDown1}
                                        autocomplete="off"
                                    />
                                    {showDropdown1 && (
                                        <div style={{ position: 'absolute', top: '100%', left: 0, height: 'auto', maxHeight: '100px', overflowY: 'auto', width: '100%', backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', zIndex: 1000 }}>
                                            {supplier && supplier?.length > 0 ? (
                                                supplier.map((supplier, index) => (
                                                    <div key={index} ref={(el) => (itemRefs.current[index] = el)} className="onHover" onClick={() => handleSelectSupplier(supplier)} style={{ padding: '8px', cursor: 'pointer', backgroundColor: activeIndex1 === index ? "#f0f0f0" : "white" }}>
                                                        <span>{supplier.supplierName}<br />
                                                            <strong>SĐT:</strong>{supplier.phoneNumber}
                                                            <strong> - Mã số thuế:</strong>{supplier.tax}
                                                        </span>
                                                    </div>
                                                ))
                                            ) : (
                                                <div style={{ padding: '10px', cursor: 'pointer' }}>
                                                    Không tìm thấy nhà cung cấp
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {searching && (
                                        <div style={{ position: 'absolute', top: '100%', left: 0, height: 'auto', maxHeight: '100px', overflowY: 'auto', padding: '20px 0', width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', zIndex: 1000 }}>
                                            <CircularProgress size={40} />
                                        </div>
                                    )}
                                </div>
                                <div className="add">
                                    <button type="add" onClick={handleNavigation}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                            </div>
                            <Modal
                                open={isOpenAdd}
                                onClose={handleCloseAdd}
                                aria-labelledby='modal-modal-title'
                                aria-describedby='modal-modal-description'
                            >
                                <Box>
                                    <AddSupplier onCancel={handleCloseAdd} setSelectedSupplier={handleSetSelectedSupplier} />
                                </Box>
                            </Modal>
                            <div style={{ padding: '0 5vw 10px 0', display: 'flex', flex: '1' }}>
                                <div className="search-container" style={{ position: 'relative', textAlign: 'start', width: '20vw' }}>
                                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                                    <input
                                        name="productName"
                                        type="text"
                                        value={productName}
                                        placeholder="Nhập tên sản phẩm..."
                                        onChange={onSearchChange}
                                        onKeyDown={handleKeyDown}
                                        autocomplete="off"
                                    />
                                    {showDropdown && (
                                        <div
                                            style={{ position: 'absolute', top: '100%', left: 0, height: 'auto', maxHeight: '100px', overflowY: 'auto', width: '100%', backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', zIndex: 1000 }}>
                                            {products.length > 0 ? (
                                                products.map((product, index) => (
                                                    <div key={index} ref={(el) => (itemRefs.current[index] = el)} className="onHover" onClick={() => handleSelectItem(product)} style={{ padding: '10px', cursor: 'pointer', backgroundColor: activeIndex === index ? "#f0f0f0" : "white" }}>
                                                        <span>
                                                            {product.productName}<br />
                                                            <strong>Mã:</strong>{product.productCode}
                                                            <strong> - Tồn:</strong>{product.totalQuantity || '0'} ({product.productUnitList?.find((unit) => unit.conversionFactor === 1).unitName})
                                                            <strong> - SĐK:</strong>{product.registrationNumber}
                                                            <strong> - Hãng SX:</strong>{product.manufacturer}
                                                            <strong> - QC.Đóng gói:</strong>{product.packingMethod}
                                                        </span>
                                                    </div>
                                                ))
                                            ) : (
                                                <div style={{ padding: '10px', cursor: 'pointer' }}>
                                                    Không tìm thấy sản phẩm
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {searching1 && (
                                        <div style={{ position: 'absolute', top: '100%', left: 0, height: 'auto', maxHeight: '100px', overflowY: 'auto', padding: '20px 0', width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', zIndex: 1000 }}>
                                            <CircularProgress size={40} />
                                        </div>
                                    )}
                                </div>
                                <div className="add">
                                    <button type="button" onClick={() => setShowPopupProduct(true)}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                            </div>
                            <Button
                                component="label"
                                variant="contained"
                                sx={{ fontSize: '14px', padding: '4px 8px', width: '180px', backgroundColor: '#09446d', marginBottom: '10px' }}>
                                Chọn ảnh hóa đơn
                                <input
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={handleImageUpload}
                                />
                            </Button>
                        </div>
                        <div className="import-list" style={{ height: '500px', overflow: 'auto' }}>
                            <table style={{ boxShadow: 'none' }}>
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>Mã - Tên sản phẩm</th>
                                        <th>Đơn vị nhập</th>
                                        <th>Đơn giá</th>
                                        <th>Số lượng</th>
                                        <th>C.K (%)</th>
                                        <th>VAT (%)</th>
                                        <th>Số lô</th>
                                        <th>Hạn dùng</th>
                                        <th>Thành tiền</th>
                                        <th>#</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: 'center' }}>
                                    {formData.products && formData.products.length > 0 &&
                                        formData.products.map((product, index) => (
                                            <>
                                                {index === indexRow ? (
                                                    <tr key={index + 1} style={{ textAlign: 'center' }} >
                                                        <td>{index + 1}</td>
                                                        <td style={{ textAlign: 'left' }}>{product.productCode} - {product.productName}</td>
                                                        <td>
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Autocomplete
                                                                    style={{ width: '80px' }}
                                                                    value={product.productUnitList.find(unit => unit.unitName === product.unitName) || null}
                                                                    onChange={(event, newValue) => {
                                                                        handleFieldChange(index, 'unitName', newValue ? newValue.unitName : '')
                                                                        handleFieldChange(index, 'unitPrice', newValue ? newValue.importPrice : 0)
                                                                        handleFieldChange(index, 'conversionFactor', newValue ? newValue.conversionFactor : 0)
                                                                    }}
                                                                    options={product.productUnitList.filter((item) => item.conversionFactor > 0)}
                                                                    getOptionLabel={(option) => option.unitName}
                                                                    renderInput={(params) => <TextField {...params} variant='standard' />}
                                                                    disableClearable
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                style={{ width: '70px' }}
                                                                name="unitPrice"
                                                                type="number"
                                                                value={product.unitPrice || 0}
                                                                onChange={(e) => handleFieldChange(index, 'unitPrice', e.target.value)}
                                                                inputProps={{
                                                                    onKeyPress: (e) => {
                                                                        if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                                            e.preventDefault();
                                                                        }
                                                                    },
                                                                    min: 0,
                                                                }}
                                                                variant="standard"
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                style={{ width: '60px' }}
                                                                name="quantity"
                                                                type="number"
                                                                value={product.quantity}
                                                                onChange={(e) => {
                                                                    if (e.target.value <= 0) {
                                                                        handleFieldChange(index, 'quantity', 1)
                                                                    } else {
                                                                        handleFieldChange(index, 'quantity', e.target.value)
                                                                    }
                                                                }}
                                                                inputProps={{
                                                                    onKeyPress: (e) => {
                                                                        if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                                            e.preventDefault();
                                                                        }
                                                                    }
                                                                }}
                                                                variant="standard"
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                style={{ width: '50px' }}
                                                                name="ck"
                                                                type="number"
                                                                value={product.discount}
                                                                onChange={(e) => {
                                                                    if (e.target.value > 100) {
                                                                        handleFieldChange(index, 'discount', 100)
                                                                    } else {
                                                                        handleFieldChange(index, 'discount', e.target.value)
                                                                    }
                                                                }}
                                                                inputProps={{
                                                                    onKeyPress: (e) => {
                                                                        if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                                            e.preventDefault();
                                                                        }
                                                                    },
                                                                    min: 0,
                                                                    max: 100,
                                                                }}
                                                                variant="standard"
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                style={{ width: '50px' }}
                                                                name="vat"
                                                                type="number"
                                                                value={product.vat}
                                                                onChange={(e) => {
                                                                    if (e.target.value > 100) {
                                                                        handleFieldChange(index, 'vat', 100)
                                                                    } else {
                                                                        handleFieldChange(index, 'vat', e.target.value)
                                                                    }
                                                                }}
                                                                inputProps={{
                                                                    onKeyPress: (e) => {
                                                                        if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                                            e.preventDefault();
                                                                        }
                                                                    },
                                                                    min: 0,
                                                                    max: 100,
                                                                }}
                                                                variant="standard"
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                style={{ width: '80px' }}
                                                                name="batchNumber"
                                                                type="text"
                                                                value={product.batchNumber}
                                                                onChange={(e) => handleFieldChange(index, 'batchNumber', e.target.value)}
                                                                variant="standard"
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                style={{ width: '112px' }}
                                                                name="expiryDate"
                                                                type="date"
                                                                value={product.expiryDate ? format(new Date(product.expiryDate * 1000), 'yyyy-MM-dd') : ''}
                                                                onChange={(e) => handleFieldChange(index, 'expiryDate', (new Date(e.target.value).getTime() / 1000))}
                                                                variant="standard"
                                                                inputProps={{
                                                                    min: format(new Date(), 'yyyy-MM-dd'),
                                                                }}
                                                            />
                                                        </td>
                                                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product?.totalPrice)}</td>
                                                        <td>
                                                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                                <button className="edit-btn"
                                                                    onClick={() => setIndexRow(null)}
                                                                >
                                                                    <FontAwesomeIcon icon={faSave} />
                                                                </button>
                                                                <button className="delete-btn"
                                                                    onClick={() => handleDeleteProduct(index)}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr key={index + 1} style={{ textAlign: 'center' }}>
                                                        <td>{index + 1}</td>
                                                        <td style={{ textAlign: 'left' }}>{product.productCode} - {product.productName}</td>
                                                        <td>{product.unitName}</td>
                                                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.unitPrice)}</td>
                                                        <td>{product.quantity}</td>
                                                        <td>{product.discount}</td>
                                                        <td>{product.vat}</td>
                                                        <td>{product.batchNumber || 'N/A'}</td>
                                                        <td>{product.expiryDate ? format(new Date(product.expiryDate * 1000), 'dd/MM/yyyy') : 'N/A'}</td>
                                                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.totalPrice)}</td>
                                                        <td>
                                                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                                <button className="edit-btn"
                                                                    onClick={() => setIndexRow(index)}
                                                                >
                                                                    <FontAwesomeIcon icon={faPenToSquare} />
                                                                </button>
                                                                <button className="delete-btn"
                                                                    onClick={() => handleDeleteProduct(index)}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        ))}
                                    <tr>
                                        <td colSpan={11} style={{ textAlign: 'center' }}>
                                            <div>
                                                {uploadedImages.map((image, index) => (
                                                    <div key={index}>
                                                        <img
                                                            src={URL.createObjectURL(image)}
                                                            alt={`Uploaded ${index}`}
                                                            style={{ maxWidth: '100%', height: 'auto', border: '1px solid #ddd' }}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody >
                            </table>
                        </div>
                    </div>
                    {showPopupProduct && (
                        <PopupAddProduct onPopupClose={handleCloseProduct} />
                    )}
                    <div style={{ backgroundColor: 'white', marginRight: '5px', textAlign: 'start', padding: '15px 25px', borderRadius: '10px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)', flex: '1' }}>
                        <div style={{ alignItems: 'center', textAlign: 'right', fontSize: '13px' }}>{today}</div>
                        <Divider />
                        <h4 className='title-name' style={{ color: '#022742' }}>Thông tin phiếu nhập</h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Số lượng sản phẩm:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>{formData?.products?.length} sản phẩm</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng tiền hàng:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>
                                {finalPrice ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(finalPrice) : '0 đ'}
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng chiết khấu:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>
                                {finalDiscount ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(finalDiscount) : '0 đ'}
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng thuế:</span></div>
                            <p style={{ color: '#09446d', fontSize: '14px' }}>
                                {finalVAT ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(finalVAT) : '0 đ'}
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent' style={{ fontWeight: 'bold' }}><span>Tổng tiền phải trả:</span></div>
                            <p style={{ color: '#09446d', fontWeight: 'bold', fontSize: '14px' }}>
                                {finalPrice ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(payPrice) : '0 đ'}
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'>
                                <span>Phương thức thanh toán:</span>
                            </div>
                            <div>
                                <select
                                    name='pttt'
                                    style={{ width: '130px' }}
                                    value={paymentMethod}
                                    onChange={handlePaymentMethodChange}
                                >
                                    <option value={'CASH'}>Tiền mặt</option>
                                    <option value={'TRANSFER'}>Chuyển khoản</option>
                                </select>
                            </div>
                        </div>
                        <TextField
                            name="note"
                            type="text"
                            label="Ghi chú"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            variant="standard"
                            fullWidth
                            inputProps={{
                                autoComplete: "off",
                            }}
                        />
                        <div style={{ display: 'flex', flex: '1', marginTop: '50px', justifyContent: 'center' }}>
                            <Button onClick={handleSubmit} variant="contained" className='btn' >
                                Ghi phiếu
                            </Button>
                            <Button type='btn' variant="contained" className='btn' onClick={() => { navigate('/import') }}>
                                Hủy
                            </Button>
                        </div>
                    </div>
                </div>
                {downloading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100vh',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            zIndex: 99999,
                        }}
                    >
                        <CircularProgress size="5rem" />
                    </div>
                )}
            </div>
        </DefaultTemplate >
    );
};

export default ImportReceiptCreate;