import React, { useState } from 'react';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from '@mui/material';
import { format } from 'date-fns';
import EditProduct from './EditProduct';

const PopupDetailProduct = ({ onPopupClose, product }) => {

  const formattedCreateDate = product && product.createDate
    ? format(new Date(product.createDate * 1000), 'dd/MM/yyyy')
    : '../../....';

  const formattedModifyDate = product && product.lastModifiedDate
    ? format(new Date(product.lastModifiedDate * 1000), 'dd/MM/yyyy')
    : '../../....';

  const [showEditPopup, setShowEditPopup] = useState(false);

  const handleEditClick = () => {
    setShowEditPopup(true);
  };

  const handleEditCancel = () => {
    setShowEditPopup(false);
    onPopupClose();
  };

  if (showEditPopup) {
    return <EditProduct product={product} onPopupClose={handleEditCancel} />;
  }

  return (
    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      textAlign: 'start',
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{ width: '65%', maxHeight: '80vh', overflowY: 'auto', backgroundColor: 'white', textAlign: 'start', padding: '15px 25px', borderRadius: '10px' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 3 }}>
            <h2 style={{ textAlign: 'center' }}>Chi Tiết Sản Phẩm {product.productName}</h2>
          </div>
          <div style={{ flex: 1, textAlign: 'end' }}>
            <FontAwesomeIcon onClick={onPopupClose} style={{ fontSize: '30px', cursor: 'pointer' }} icon={faXmark} />
          </div>
        </div>
        <div className='product-form'>
          <div className='form-column' >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2', fontSize: '20px' }}>
                <span><strong>Tên sản phẩm:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3', fontSize: '20px' }}>
                <span>{product.productName}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Mã sản phẩm:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.productCode}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Nhóm sản phẩm:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.category}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Số đăng ký:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.registrationNumber}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Thành phần hoạt tính:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.activeIngredient}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Dạng bào chế:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.dosageForms}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Nồng độ - Hàm lượng:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.dosageConcentration}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Chỉ định:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.indication}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Chống chỉ định:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.contraindication}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Số lượng cảnh báo:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.numberWarning}</span>
              </div>
            </div>
          </div>
          <div className='form-column' style={{ marginRight: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'start' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Ảnh sản phẩm:</strong></span>
              </div>
              <div style={{ flex: '3' }}>
                <img
                  style={{ width: '140px', height: '140px' }}
                  src={product.imageProduct}
                  alt='N/A'
                  className='product-image'
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Quy cách đóng gói:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.packingMethod}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Thuốc kê theo đơn:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.prescriptionDrug ? 'Có' : 'Không'}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Hãng sản xuất:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.manufacturer}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Nước sản xuất:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.countryOfOrigin}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Mô tả:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.description}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='fieldText' style={{ flex: '2' }}>
                <span><strong>Tác dụng phụ:</strong></span>
              </div>
              <div className='fieldContent' style={{ flex: '3' }}>
                <span>{product.sideEffect}</span>
              </div>
            </div>
          </div>
        </div>
        {product.productUnitList
          ?.filter((productUnit) => productUnit.retailPrice !== 0 && productUnit.conversionFactor !== 0)
          .sort((a, b) => a.conversionFactor - b.conversionFactor)
          .map((productUnit) => {
            return (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ flex: '1', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <div className='fieldText' style={{ flex: '2' }}>
                      <span><strong>Đơn vị:</strong></span>
                    </div>
                    <div className='fieldContent' style={{ flex: '1' }}><span>{productUnit.unitName}</span></div>
                  </div>
                  <br></br>
                  <div style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className='fieldText' style={{ flex: '2' }}>
                      <span><strong>Hệ quy đổi:</strong></span>
                    </div>
                    <div className='fieldContent' style={{ flex: '1' }}><span>{productUnit.conversionFactor}</span></div>
                  </div>
                  <div style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className='fieldText' style={{ flex: '1' }}>
                      <span><strong>Giá bán:</strong></span>
                    </div>
                    <div className='fieldContent' style={{ flex: '1' }}><span>{new Intl.NumberFormat('vi-VN', {
                      style: 'decimal',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(productUnit.retailPrice)}</span></div>
                  </div>
                  <div style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className='fieldText' style={{ flex: '1' }}>
                      <span><strong>Giá nhập:</strong></span>
                    </div>
                    <div className='fieldContent' style={{ flex: '1' }}><span>{new Intl.NumberFormat('vi-VN', {
                      style: 'decimal',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(productUnit.importPrice)}</span></div>
                  </div>
                </div>
              </>
            );
          })}
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <p><strong>Tạo ngày {formattedCreateDate}, chỉnh sửa lần cuối ngày {formattedModifyDate} bởi {product ? (<>{product.lastModifiedBy}</>) : (<>....</>)}</strong></p>
        </div>
        <div style={{ margin: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {(sessionStorage.getItem('roles').includes('ROLE_PRODUCT_OWNER') || sessionStorage.getItem('roles').includes('ROLE_STOCK')) && (
            <Button variant="contained" className='btn' onClick={handleEditClick}>
              Sửa
            </Button>)}
          <Button variant="contained" className='btn' onClick={onPopupClose}>
            Quay lại
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PopupDetailProduct;