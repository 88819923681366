import React, { useEffect, useState } from "react";
import "flatpickr/dist/themes/material_blue.css";
import '../styles/Report.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTruckField } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress, Card, CardContent, Typography, Grid, Button, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import api from '../api/axiosConfig';
import { FiDownload } from 'react-icons/fi';

const ReportSupplier = ({ dateRange }) => {
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [supplierReport, setSupplierReport] = useState(null);
    const [supplierReportList, setSupplierReportList] = useState([]);
    const [supplierName, setSupplierName] = useState('');
    const [isNewSupplier, setIsNewSupplier] = useState('');
    const [downloading, setDownloading] = useState(false);

    const convertToLocalDate = (date) => {
        if (date) {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);

            return newDate.toISOString().split('T')[0];
        }
        return null;
    };

    const getSupplierReportList = async (page) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/reports/list-suppliers?page=${page - 1}&size=10`;
            if (dateRange && dateRange[0] && dateRange[1]) {
                url += `&startDate=${convertToLocalDate(dateRange[0])}&endDate=${convertToLocalDate(dateRange[1])}`;
            }
            if (supplierName && supplierName !== '') {
                url += `&name=${supplierName}`
            }
            if (isNewSupplier && isNewSupplier !== '') {
                url += `&isNewSupplier=${isNewSupplier}`
            }
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSupplierReportList(response.data);
            setTotalPage(response.data.totalPages);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const getSupplierReport = async () => {
        const token = sessionStorage.getItem('token');
        try {
            let url = '/api/reports/suppliers';
            if (dateRange && dateRange[0] && dateRange[1]) {
                url += `?startDate=${convertToLocalDate(dateRange[0])}&endDate=${convertToLocalDate(dateRange[1])}`;
            }
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSupplierReport(response.data);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const getExportExcel = async () => {
        if (!dateRange) {
            toast.error('Vui lòng chọn thời gian');
            return;
        }
        setDownloading(true);
        const token = sessionStorage.getItem('token');
        try {
            if (dateRange && dateRange[0] && dateRange[1]) {
                let url = `/api/reports/export-excel-supplier-report?fromDate=${convertToLocalDate(dateRange[0])}&toDate=${convertToLocalDate(dateRange[1])}`;
                const response = await api.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                });
                const blobUrl = window.URL.createObjectURL(response.data);
                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = 'Bao_cao_nha_cung_cap.xlsx';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            console.error(error);
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        } finally {
            setDownloading(false);
        }
    };

    useEffect(() => {
        getSupplierReportList(currentPage);
        getSupplierReport();
    }, [currentPage, dateRange, supplierName, isNewSupplier])

    const getVisiblePages = () => {
        const maxButtons = 3;
        const half = Math.floor(maxButtons / 2);
        let startPage, endPage;

        if (totalPage <= maxButtons) {
            startPage = 1;
            endPage = totalPage;
        } else {
            if (currentPage <= half) {
                startPage = 1;
                endPage = maxButtons;
            } else if (currentPage + half >= totalPage) {
                startPage = totalPage - maxButtons + 1;
                endPage = totalPage;
            } else {
                startPage = currentPage - half;
                endPage = currentPage + half;
            }
        }
        return { startPage, endPage };
    }

    const renderCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
    };
    const { startPage, endPage } = getVisiblePages();
    const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    const data = [
        { icon: faTruckField, value: `${supplierReport?.newSuppliers || 0} / ${renderCurrency(supplierReport?.newSuppliersAmount || 0)}`, label: "Nhà cung cấp mới / Tổng tiền", color: "#a17cf8" },
        { icon: faTruckField, value: `${supplierReport?.oldSuppliers || 0} / ${renderCurrency(supplierReport?.oldSuppliersAmount || 0)}`, label: "Nhà cung cấp cũ / Tổng tiền", color: "#56ccf2" },
        { icon: faTruckField, value: `${supplierReport?.totalSuppliers || 0} / ${renderCurrency(supplierReport?.totalImportAmount || 0)}`, label: "Tổng số nhà cung cấp / Tổng tiền", color: "#f2994a" },
    ];
    return (
        <div className="report-container">
            <div className="btnSearch" style={{ marginBottom: '0' }}>
                <select className="custom-select" name="supplier" onChange={(e) => setIsNewSupplier(e.target.value)}>
                    <option value='' selected>Tất cả nhà cung cấp</option>
                    <option value={true}>Nhà cung cấp mới</option>
                    <option value={false}>Nhà cung cấp cũ</option>
                </select>
                <div className="search-input-container">
                    <input
                        name="name"
                        type="text"
                        onChange={(e) => setSupplierName(e.target.value)}
                        placeholder="Nhập tên nhà cung cấp..."
                        autocomplete="off"
                    />
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                </div>
                <Button className="export-btn" style={{ backgroundColor: "#09446d", color: '#fff' }} onClick={getExportExcel}>
                    Xuất file
                    <FiDownload />
                </Button>
            </div>
            <Grid container columnSpacing={6} style={{ padding: '20px 0' }}>
                {data.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card style={{ borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                            <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '16px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ backgroundColor: `${item.color}33`, borderRadius: '50%', padding: '12px', marginRight: '16px', width: '30px', height: '30px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        <FontAwesomeIcon icon={item.icon} style={{ color: item.color, fontSize: '24px' }} />
                                    </div>
                                    <div>
                                        <Typography variant="h5" style={{ fontWeight: 600 }}>
                                            {item.value}
                                        </Typography>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {item.label}
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <div style={{ overflowX: 'auto' }}>
                <table className="list">
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Nhà cung cấp</th>
                            <th>Số điện thoại</th>
                            <th>Tổng hóa đơn</th>
                            <th>Tổng sản phẩm</th>
                            <th>Tổng tiền trả hàng</th>
                            <th>Tổng tiền nhập hàng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {supplierReportList?.content ? (
                            supplierReportList.content.length > 0 ? (
                                supplierReportList.content.map((supplier, index) => (
                                    <tr key={index}>
                                        <td>{(index + 1) + ((currentPage - 1) * 10)}</td>
                                        <td>{supplier?.supplierName}</td>
                                        <td>{supplier?.phoneNumber}</td>
                                        <td>{supplier?.invoiceCount}</td>
                                        <td>{supplier?.totalProductQuantity}</td>
                                        <td>
                                            {new Intl.NumberFormat('vi-VN', {
                                                style: 'currency',
                                                currency: 'VND',
                                            }).format(supplier?.totalReturnAmount || 0)}
                                        </td>
                                        <td>
                                            {new Intl.NumberFormat('vi-VN', {
                                                style: 'currency',
                                                currency: 'VND',
                                            }).format(supplier?.totalImportAmount || 0)}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7} style={{ textAlign: 'center', padding: '20px' }}>
                                        Không có dữ liệu
                                    </td>
                                </tr>
                            )
                        ) : (
                            <tr>
                                <td colSpan={7} style={{ padding: '20px' }}>
                                    <LinearProgress color="inherit" />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {totalPage > 1 && (
                <div className="pagination">
                    {currentPage === 1 ? (
                        <button disabled>&laquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</button>
                    )}
                    {startPage > 1 && (
                        <>
                            <button onClick={() => setCurrentPage(1)}>1</button>
                            {startPage > 2 && (
                                <button disabled>...</button>
                            )}
                        </>
                    )}
                    {visiblePages.map((page) => (
                        <button onClick={() => setCurrentPage(page)}
                            key={page}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}
                    {endPage < totalPage && (
                        <>
                            {endPage < totalPage - 1 && (
                                <button disabled>...</button>
                            )}
                            <button onClick={() => setCurrentPage(totalPage)}>{totalPage}</button>
                        </>
                    )}
                    {currentPage === totalPage ? (
                        <button disabled>&raquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</button>
                    )}
                </div>
            )}
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
        </div>
    );
};

export default ReportSupplier;
