import React, { useEffect, useState } from 'react';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPenToSquare, faSave } from '@fortawesome/free-solid-svg-icons';
import { Button, TextField, Divider, Autocomplete, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate, useParams, Link } from 'react-router-dom';
import api from '../../api/axiosConfig';

const ReturnOrderCreate = () => {
    const { saleId } = useParams();
    const [item, setItem] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);
    const [finalDiscount, setFinalDiscount] = useState(0);
    const [formData, setFormData] = useState([]);
    const [saleOrder, setSaleOrder] = useState([]);
    const [indexRow, setIndexRow] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const navigate = useNavigate();

    const getSaleOrder = async () => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `api/return-order/get-sale-order-for-return-id?id=${saleId}`;
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data = response.data;
            setSaleOrder(data);
        } catch (error) {
            if (error.status === 404) {
                toast.error(error.response.data.message);
            } else if (error.status >= 400 && error.status < 500) {
                toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
            }
        }
    }

    useEffect(() => { getSaleOrder() }, [])

    useEffect(() => {
        const products = saleOrder?.saleOrderItems?.map((item) => ({
            id: item.id,
            productCode: item.product.productCode,
            productName: item.product.productName,
            productId: item.product.id,
            unitName: item.unit,
            unitPrice: item.unitPrice,
            quantity: item.quantity,
            discount: item.discount,
            conversionFactor: item.conversionFactor,
            totalPrice: item.totalAmount,
            remainingQuantity: item.product.totalQuantity,
            productUnitList: item.product.productUnitList,
            importItemBatchDtos: item.importItemBatchDtos,
        })) || [];
        setFormData(() => ({
            products: products,
        }));
    }, [saleOrder]);

    const handleReturnReasonChange = (e) => {
        setItem(prevItem => ({
            ...prevItem,
            returnReason: e.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDownloading(true);
        const u = sessionStorage.getItem('id');
        const submitData = {
            saleOrderId: saleId,
            returnReason: item?.returnReason || '',
            totalAmount: finalPrice,
            returnOrderItems: formData.products?.map((product) => ({
                productId: product.productId,
                quantity: Number(product.returnQuantity || 0),
                unitPrice: product.unitPrice,
                unit: product.unitName,
                batchRequestDtos: product?.importItemBatchDtos?.map((importItem) => ({
                    invoiceNumber: importItem.invoiceNumber,
                    batchNumber: importItem.batchNumber,
                    quantity: importItem.returnQuantity || 0,
                })),
                conversionFactor: product.conversionFactor,
            })),
        };
        const token = sessionStorage.getItem('token');
        try {
            const { data } = await api.post(`api/return-order/create-return-order`, submitData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setTimeout(() => {
                toast.success(data);
            }, 500);
            navigate(`/return-order`);
        } catch (err) {
            console.log(err);
            if (err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error('Không thể tạo phiếu trả hàng, vui lòng thử lại.');
            }
        } finally {
            setDownloading(false);
        }
    };

    const calculateTotalAmount = (unitPrice, quantity, discount) => {
        const total = unitPrice * quantity;
        const discountValue = 1 - (discount / 100);
        const totalPrice = total * discountValue;
        return totalPrice;
    };

    const handleFieldChange = (index, fieldName, fieldValue) => {
        setFormData((prevFormData) => {
            const updatedProducts = [...prevFormData.products];

            if (fieldName === 'conversionFactor') {
                const updatedImportItems = updatedProducts[index].importItemBatchDtos.map((item) => ({
                    ...item,
                    returnQuantity: 0,
                }));
                updatedProducts[index].importItemBatchDtos = updatedImportItems;
                updatedProducts[index] = {
                    ...updatedProducts[index],
                    returnQuantity: 0,
                };
            }

            updatedProducts[index] = {
                ...updatedProducts[index],
                [fieldName]: fieldValue,
            };

            return {
                ...prevFormData,
                products: updatedProducts,
            };
        });
    };

    const handleFieldChange2 = (index, index2, fieldName, fieldValue) => {
        setFormData((prevFormData) => {
            const updatedImportItems = [...prevFormData.products[index].importItemBatchDtos];

            updatedImportItems[index2] = {
                ...updatedImportItems[index2],
                [fieldName]: fieldValue,
            };

            const updatedProducts = [...prevFormData.products];
            updatedProducts[index] = {
                ...updatedProducts[index],
                importItemBatchDtos: updatedImportItems,
            };

            const totalReturnQuantity = updatedImportItems.reduce(
                (sum, item) => sum + (item.returnQuantity || 0),
                0
            );

            const tempUnitPrice = updatedProducts[index].unitPrice || 0;
            const tempDiscount = updatedProducts[index].discount || 0;
            const tempAmount = calculateTotalAmount(tempUnitPrice, totalReturnQuantity, tempDiscount);

            updatedProducts[index] = {
                ...updatedProducts[index],
                returnQuantity: totalReturnQuantity,
                returnTotalPrice: tempAmount,
            };

            const totalTotalPrice = updatedProducts.reduce(
                (sum, product) => sum + ((product.unitPrice || 0) * (product.returnQuantity || 0)),
                0
            );
            const totalFinalPrice = updatedProducts.reduce((sum, product) => {
                const discount = product.discount || 0;
                const priceAfterDiscount = ((product.unitPrice || 0) * (product.returnQuantity || 0)) * (1 - discount / 100);
                return sum + priceAfterDiscount;
            }, 0);

            setTotalPrice(totalTotalPrice);
            setFinalPrice(totalFinalPrice);
            setFinalDiscount(totalFinalPrice - totalTotalPrice);

            return {
                ...prevFormData,
                products: updatedProducts,
            };
        });
    };

    const today = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    return (
        <DefaultTemplate>
            <div className="receipt-container">
                <div className="header">
                    <h3 className='title-name' style={{ marginBottom: '0', marginLeft: '10px' }}>
                        <Link to="/return-order" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Trả Hàng
                        </Link>
                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronRight} />
                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faChevronRight} />
                        Tạo phiếu trả hàng
                    </h3>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="import-list" style={{ flex: '4', textAlign: 'start', margin: '0 10px', borderRadius: '10px', overflow: 'auto' }}>
                        <table style={{ boxShadow: 'none' }}>
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Mã - Tên sản phẩm</th>
                                    <th>Đơn vị</th>
                                    <th>Đơn giá</th>
                                    <th>Số lô</th>
                                    <th>Số lượng trả</th>
                                    <th>C.K (%)</th>
                                    <th>Thành tiền</th>
                                    <th>#</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {formData.products && formData.products.length > 0 && (
                                    formData.products.map((product, index) => (
                                        <>
                                            {index === indexRow ? (
                                                <tr key={index + 1} style={{ textAlign: 'center' }}>
                                                    <td style={{ paddingTop: '30px' }}>{index + 1}</td>
                                                    <td style={{ paddingTop: '30px', textAlign: 'left' }}>{product.productCode} - {product.productName}</td>
                                                    <td style={{ paddingTop: '20px' }}>
                                                        <Autocomplete
                                                            sx={{ width: 80 }}
                                                            value={product.unitName}
                                                            onChange={(event, newValue) => {
                                                                handleFieldChange(index, 'unitName', newValue);
                                                                const value = product.productUnitList.find((unit) => unit.unitName === newValue);
                                                                if (value) {
                                                                    handleFieldChange(index, 'unitPrice', value.retailPrice);
                                                                    handleFieldChange(index, 'conversionFactor', value.conversionFactor);
                                                                }
                                                            }}
                                                            options={product?.productUnitList
                                                                ?.filter((unit) => unit.conversionFactor > 0)
                                                                ?.map((unit) => unit.unitName)}
                                                            renderInput={(params) => <TextField {...params} variant="standard" />}
                                                            disableClearable
                                                        />
                                                    </td>
                                                    <td style={{ paddingTop: '30px' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.unitPrice)}</td>
                                                    <td>
                                                        {product?.importItemBatchDtos?.map((importItem) => {
                                                            return <p style={{ paddingTop: '20px' }}>{importItem.batchNumber}</p>
                                                        })}
                                                    </td>
                                                    <td>
                                                        {product?.importItemBatchDtos?.map((importItem, importItemIndex) => {
                                                            return <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                                                <TextField
                                                                    style={{ width: '60px' }}
                                                                    name="quantity"
                                                                    type="number"
                                                                    value={importItem?.returnQuantity || 0}
                                                                    onChange={(e) => {
                                                                        if (e.target.value <= 0) {
                                                                            handleFieldChange2(index, importItemIndex, 'returnQuantity', 0)
                                                                        } else if (e.target.value > Math.floor(importItem.quantityToSell / product.conversionFactor)) {
                                                                            handleFieldChange2(index, importItemIndex, 'returnQuantity', Math.floor(importItem.quantityToSell / product.conversionFactor))
                                                                        } else {
                                                                            handleFieldChange2(index, importItemIndex, 'returnQuantity', Number(e.target.value))
                                                                        }
                                                                    }}
                                                                    inputProps={{
                                                                        onKeyPress: (e) => {
                                                                            if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                                                e.preventDefault();
                                                                            }
                                                                        },
                                                                        style: {
                                                                            textAlign: 'center'
                                                                        }
                                                                    }
                                                                    }
                                                                    variant="standard"
                                                                />
                                                                <span style={{ paddingRight: '14px', paddingTop: '5px' }}>
                                                                    / {Math.floor(importItem.quantityToSell / product.conversionFactor)}
                                                                </span>
                                                            </div>
                                                        })}
                                                    </td>
                                                    <td style={{ paddingTop: '30px' }}>{product.discount || 0}</td>
                                                    <td style={{ paddingTop: '30px' }}>{product.returnTotalPrice ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.returnTotalPrice) : 0}</td>
                                                    <td style={{ paddingTop: '30px' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <button className="edit-btn" onClick={() => setIndexRow(null)}>
                                                                <FontAwesomeIcon icon={faSave} />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr key={index + 1} style={{ textAlign: 'center' }}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textAlign: 'left' }}>{product.productCode} - {product.productName}</td>
                                                    <td>{product.unitName}</td>
                                                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.unitPrice)}</td>
                                                    <td>
                                                        {product?.importItemBatchDtos?.map((importItem) => {
                                                            return <p>{importItem.batchNumber}</p>
                                                        })}
                                                    </td>
                                                    <td>
                                                        {product?.importItemBatchDtos?.map((importItem) => {
                                                            return <p>{importItem.returnQuantity || 0}</p>
                                                        })}
                                                    </td>
                                                    <td>{product.discount || 0}</td>
                                                    <td>{product.returnTotalPrice ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.returnTotalPrice) : 0}</td>
                                                    <td>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <button className="edit-btn" onClick={() => setIndexRow(index)}>
                                                                <FontAwesomeIcon icon={faPenToSquare} />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ backgroundColor: 'white', marginRight: '5px', textAlign: 'start', padding: '15px 25px', borderRadius: '10px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)', flex: '1' }}>
                        <div style={{ alignItems: 'center', textAlign: 'right', fontSize: '13px' }}>{today}</div>
                        <Divider />
                        <h4 className='title-name' style={{ color: '#022742', marginBottom: '10px' }}>Trả hàng hóa đơn {saleOrder?.invoiceNumber}</h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Ngày tạo hóa đơn:</span></div>
                            <div className='importContent'>{saleOrder?.saleDate ? new Date(saleOrder.saleDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '../../....'}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Khách hàng:</span></div>
                            <div className='importContent'>{saleOrder?.customer?.customerName}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng tiền hóa đơn gốc:</span></div>
                            <div className='importContent'>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(saleOrder?.totalAmount || 0)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Tổng tiền hàng trả:</span></div>
                            <div className='importContent'>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(totalPrice || 0)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent'><span>Giảm giá:</span></div>
                            <div className='importContent'>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(finalDiscount || 0)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className='fieldContent' style={{ fontWeight: 'bold' }}><span>Tiền trả khách:</span></div>
                            <div className='importContent'>
                                {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(finalPrice || 0)}
                            </div>
                        </div>
                        <TextField
                            name="returnReason"
                            type="text"
                            label="Lí do trả hàng"
                            value={item?.returnReason}
                            onChange={handleReturnReasonChange}
                            variant="standard"
                            fullWidth
                            inputProps={{
                                autoComplete: "off",
                            }}
                        />
                        <div style={{ display: 'flex', flex: '1', marginTop: '80px', justifyContent: 'center' }}>
                            <Button onClick={handleSubmit} variant="contained" className='btn' >
                                Ghi phiếu
                            </Button>
                            <Button type='btn' variant="contained" className='btn' onClick={() => { navigate('/sale') }}>
                                Hủy
                            </Button>
                        </div>
                    </div>
                </div>
                {downloading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100vh',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            zIndex: 99999,
                        }}
                    >
                        <CircularProgress size="5rem" />
                    </div>
                )}
            </div>
        </DefaultTemplate>
    );
};

export default ReturnOrderCreate;