import React, { useState, useEffect } from 'react';
import axiosConfig from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const AddDoctor = ({ doctor, onCancel, setSelectedDoctor }) => {
  const [downloading, setDownloading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    address: '',
    phoneNumber: '',
    note: '',
  });

  useEffect(() => {
    if (doctor) {
      setFormData({
        fullName: doctor.fullName || '',
        address: doctor.address || '',
        phoneNumber: doctor.phoneNumber || '',
        note: doctor.note || '',
      });
    }
  }, [doctor]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDownloading(true);
    const token = sessionStorage.getItem('token');
    try {
      const response = await axiosConfig.post('/api/doctor/create-doctor', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (setSelectedDoctor) {
        setSelectedDoctor(response?.data)
      }
      toast.success("Tạo thành công");
      setTimeout(() => {
        onCancel();
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        toast.error(error.response?.data?.message || 'Không thể thêm bác sĩ, vui lòng thử lại.');
      }, 500);
    } finally {
      setDownloading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index + 1]?.focus();
    }
  };

  const styles = {
    container: {
      width: '500px',
      marginTop: '120px',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    formColumn: {
      width: '100%',
    },
    fieldContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px 0',
      marginTop: '40px',
    },
    fieldText: {
      flex: 1,
      marginRight: '20px',
      fontWeight: 'bold',
      color: '#072f4d',
      textAlign: 'end',
    },
    input: {
      width: '300px',
      marginRight: '20px',
      padding: '10px',
      border: '2px solid #ccc',
      borderRadius: '4px',
      outline: 'none',
      height: '10px',
    },
    inputFocus: {
      borderColor: '#0056b3',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
    },
    button: {
      backgroundColor: '#072f4d',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      padding: '10px 20px',
      cursor: 'pointer',
      margin: '0 10px',
      transition: 'background-color 0.3s ease',
    },
    buttonHover: {
      backgroundColor: '#055c91',
    },
  };

  return (
    <form onSubmit={handleSubmit} style={styles.container}>
      <h2 style={{ textAlign: 'center' }}>Thêm bác sĩ</h2>
      <div style={styles.formColumn}>
        <div style={styles.fieldContainer}>
          <label style={styles.fieldText}>
            <span style={{ color: 'red' }}>*</span> Tên bác sĩ
          </label>
          <input
            type='text'
            name='fullName'
            value={formData.fullName}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label style={styles.fieldText}>
            <span style={{ color: 'red' }}>*</span> Số điện thoại
          </label>
          <input
            type='text'
            name='phoneNumber'
            value={formData.phoneNumber}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label style={styles.fieldText}>
            <span style={{ color: 'red' }}>*</span> Nơi công tác
          </label>
          <input
            type='text'
            name='address'
            value={formData.address}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label style={styles.fieldText}>Ghi chú</label>
          <textarea
            type='text'
            name='note'
            value={formData.note}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={{
              width: '300px',
              padding: '10px',
              marginRight: '20px',
              border: '2px solid #ccc',
              borderRadius: '4px',
              outline: 'none',
              height: '50px',
            }}
          />
        </div>
      </div>

      <div style={styles.buttons}>
        <button type='submit' style={styles.button}>
          Thêm
        </button>
        <button type='button' style={styles.button} onClick={onCancel}>
          Hủy
        </button>
      </div>
      {downloading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 99999,
          }}
        >
          <CircularProgress size="5rem" />
        </div>
      )}
    </form>
  );
};

export default AddDoctor;