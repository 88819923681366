import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import api from "../../api/axiosConfig";
import '../../styles/Login.css';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email) {
      toast.error("Vui lòng nhập email!");
      return;
    }

    try {
      const response = await api.post('/api/auth/forgot-password', null, {
        params: { email: formData.email },
      });
      if (response.status >= 200 && response.status < 300) {
        setTimeout(() => {
          toast.success("Vui lòng kiểm tra email để lấy lại mật khẩu");
        }, 500);
        navigate('/login');
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Yêu cầu đặt lại mật khẩu không thành công, vui lòng thử lại.");
      }
    }
  };

  return (
    <div className="login-container">
      <ToastContainer />
      <div className="background-image" />
      <form className="form-contain" onSubmit={handleSubmit}>
        <div className="logo-container">
          <img src={require('../../assets/images/logo1.png')} alt="Pharmacy Logo" className="logo" />
        </div>
        <div className="input-field" style={{ marginBottom: '0' }}>
          <input
            id="email"
            name="email"
            type="text"
            placeholder="Nhập Email"
            className="input-element"
            style={{ paddingLeft: '0' }}
            onChange={handleChange}
          />
        </div>
        <div className="forgot-password">
          <a href="#" onClick={() => { navigate(-1) }}>Quay lại</a>
        </div>
        <Button
          variant="contained"
          fullWidth
          className="login-button"
          type='submit'
        >
          Xác nhận
        </Button>
      </form>
      <div className="footer-text">
        <a>
          Pharmacy © 2024 SEP490_G27
        </a>
      </div>
    </div>
  );
};

export default ForgotPassword;
