import React, { useEffect, useState, useCallback } from "react";
import "flatpickr/dist/themes/material_blue.css";
import '../styles/Report.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBoxesStacked, faTruckRampBox, faTruckMoving, faBoxesPacking, faCalendarDays, faCalendarCheck, faCartFlatbed, faClipboard, faPenToSquare, faSave } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress, Card, CardContent, Typography, Grid, Button, TextField, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import api from '../api/axiosConfig';
import { FiDownload } from 'react-icons/fi';

const ReportInventory = ({ status, dateRange }) => {
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [inventoryReport, setInventoryReport] = useState(null);
    const [inventoryReportList, setInventoryReportList] = useState(null);
    const [outOfStockReportList, setOutOfStockReportList] = useState(null);
    const [expiredReportList, setExpiredReportList] = useState(null);
    const [productName, setProductName] = useState('');
    const [reportType, setReportType] = useState(status || 'inventory');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [tempWaringNumber, setTempWaringNumber] = useState(null);
    const [downloading, setDownloading] = useState(false);

    const getData = async () => {
        const token = sessionStorage.getItem('token');
        try {
            const response = await api.get("api/category/get-all-category-list", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response;
        } catch (error) {
            throw new Error('Lỗi khi lấy dữ liệu: ' + error.message);
        }
    };

    useEffect(() => {
        if (status) {
            setReportType(status);
        }
    }, [status])

    const fetchData = useCallback(async () => {
        try {
            const response = await getData();
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching levels:", error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const convertToLocalDate = (date) => {
        if (date) {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);

            return newDate.toISOString().split('T')[0];
        }
        return null;
    };

    const getInventoryReport = async () => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/reports/inventory-report`;
            if (dateRange && dateRange[0] && dateRange[1]) {
                url += `?startDate=${convertToLocalDate(dateRange[0])}&endDate=${convertToLocalDate(dateRange[1])}`;
            }
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setInventoryReport(response.data);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const getInventoryReportList = async (page) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/reports/inventory-report-product?pageNumber=${page - 1}&pageSize=10`;
            if (dateRange && dateRange[0] && dateRange[1]) {
                url += `&startDate=${convertToLocalDate(dateRange[0])}&endDate=${convertToLocalDate(dateRange[1])}`;
            }
            if (productName && productName !== '') {
                url += `&productName=${productName}`;
            }
            if (selectedCategory && selectedCategory !== '') {
                url += `&categoryId=${selectedCategory}`;
            }
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setInventoryReportList(response.data);
            setTotalPage(response.data.totalPages);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const renderDate = (value) => {
        const date = new Date(Math.floor(value) * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const getOutOfStockReportList = async (page) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/reports/out-of-stock?pageNumber=${page - 1}&pageSize=10`;
            if (dateRange && dateRange[0] && dateRange[1]) {
                url += `&startDate=${convertToLocalDate(dateRange[0])}&endDate=${convertToLocalDate(dateRange[1])}`;
            }
            if (productName && productName !== '') {
                url += `&searchText=${productName}`;
            }
            if (selectedCategory && selectedCategory !== '') {
                url += `&categoryId=${selectedCategory}`;
            }
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setOutOfStockReportList(response.data);
            setTotalPage(response.data.totalPages);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const getExpiredReportList = async (page) => {
        const token = sessionStorage.getItem('token');
        try {
            let url = `/api/reports/expired?pageNumber=${page - 1}&pageSize=10`;
            if (dateRange && dateRange[0] && dateRange[1]) {
                url += `&startDate=${convertToLocalDate(dateRange[0])}&endDate=${convertToLocalDate(dateRange[1])}`;
            }
            if (productName && productName !== '') {
                url += `&searchText=${productName}`;
            }
            if (selectedCategory && selectedCategory !== '') {
                url += `&categoryId=${selectedCategory}`;
            }
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setExpiredReportList(response.data);
            setTotalPage(response.data.totalPages);
        } catch (error) {
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        }
    };

    const getExportExcel = async () => {
        if (!dateRange) {
            toast.error('Vui lòng chọn thời gian');
            return;
        }
        setDownloading(true);
        const token = sessionStorage.getItem('token');
        try {
            if (dateRange && dateRange[0] && dateRange[1]) {
                let url = `/api/reports/export-inventory-report?fromDate=${convertToLocalDate(dateRange[0])}&toDate=${convertToLocalDate(dateRange[1])}`;
                const response = await api.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                });
                const blobUrl = window.URL.createObjectURL(response.data);
                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = 'Bao_cao_ton_kho.xlsx';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            console.error(error);
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        } finally {
            setDownloading(false);
        }
    };

    const updateWarningNumber = async (id, numberWarning) => {
        try {
            const token = sessionStorage.getItem('token');
            const u = sessionStorage.getItem('id');
            const { data } = await api.put(`/api/product/set-warning-number?id=${id}&numberWarning=${numberWarning}`, {
                userId: Number(u),
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(data);
            getOutOfStockReportList(currentPage);
            setCurrentIndex(null);
        } catch (error) {
            console.error(error);
            toast.error('Lỗi khi cập nhật số lượng cảnh báo. Vui lòng thử lại sau!');
        }
    };

    useEffect(() => {
        console.log(reportType);
        if (reportType === 'inventory') {
            getInventoryReportList(currentPage);
        } else if (reportType === 'outOfStock') {
            getOutOfStockReportList(currentPage);
        } else if (reportType === 'expired') {
            console.log(productName);
            getExpiredReportList(currentPage);
        }
    }, [currentPage, dateRange, productName, selectedCategory, reportType])

    useEffect(() => {
        getInventoryReport();
    }, [dateRange])

    const getVisiblePages = () => {
        const maxButtons = 3;
        const half = Math.floor(maxButtons / 2);
        let startPage, endPage;

        if (totalPage <= maxButtons) {
            startPage = 1;
            endPage = totalPage;
        } else {
            if (currentPage <= half) {
                startPage = 1;
                endPage = maxButtons;
            } else if (currentPage + half >= totalPage) {
                startPage = totalPage - maxButtons + 1;
                endPage = totalPage;
            } else {
                startPage = currentPage - half;
                endPage = currentPage + half;
            }
        }
        return { startPage, endPage };
    }
    const { startPage, endPage } = getVisiblePages();

    const renderCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
    };

    const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    const data = [
        { icon: faBoxesStacked, value: `${inventoryReport?.beginningInventoryQuantity} / ${renderCurrency(inventoryReport?.beginningInventoryAmount)}`, label: "Sản phẩm tồn đầu kỳ / Tổng tiền", color: "#a17cf8" },
        { icon: faTruckRampBox, value: `${inventoryReport?.goodsReceivedQuantity} / ${renderCurrency(inventoryReport?.goodsReceivedAmount)}`, label: "Sản phẩm nhập kho / Tổng tiền", color: "#56ccf2" },
        { icon: faTruckMoving, value: `${inventoryReport?.goodsIssuedQuantity} / ${renderCurrency(inventoryReport?.goodsIssuedAmount)}`, label: "Sản phẩm xuất kho / Tổng tiền", color: "#f2994a" },
        { icon: faBoxesPacking, value: `${inventoryReport?.currentInventoryQuantity} / ${renderCurrency(inventoryReport?.currentInventoryAmount)}`, label: "Sản phẩm tồn kho / Tổng tiền", color: "#fb7185" },
        { icon: faCalendarDays, value: `${inventoryReport?.expiredItems}`, label: "Sản phẩm hết hạn", color: "#2ecc71" },
        { icon: faCalendarCheck, value: `${inventoryReport?.nearlyExpiredItems}`, label: "Sản phẩm sắp hết hạn", color: "#3498db" },
        { icon: faClipboard, value: `${inventoryReport?.outOfStockProducts}`, label: "Sản phẩm hết hàng", color: "#e74c3c" },
        { icon: faCartFlatbed, value: `${inventoryReport?.nearlyOutOfStockProducts}`, label: "Sản phẩm sắp hết hàng", color: "#9b59b6" },
    ];
    return (
        <div className="report-container">
            <div className="btnSearch" style={{ marginBottom: '0' }}>
                <select className="custom-select" name="reportType" style={{ width: '170px' }} value={reportType} onChange={(e) => setReportType(e.target.value)}>
                    <option value="inventory">Tồn kho</option>
                    <option value="expired">Hết hạn</option>
                    <option value="outOfStock">Hết hàng</option>
                </select>
                <select className="custom-select" name="category" onChange={(e) => setSelectedCategory(e.target.value)} style={{ width: '300px' }}>
                    <option value='' selected>Nhóm sản phẩm</option>
                    {categories && categories?.map((category) => (
                        <option value={category.id}>{category.name}</option>
                    ))}
                </select>
                <div className="search-input-container">
                    <input
                        name="name"
                        type="text"
                        onChange={(e) => setProductName(e.target.value)}
                        placeholder="Nhập tên sản phẩm..."
                        autocomplete="off"
                    />
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                </div>
                <Button className="export-btn" style={{ backgroundColor: "#09446d", color: '#fff' }} onClick={getExportExcel}>
                    Xuất file
                    <FiDownload />
                </Button>
            </div>
            <Grid container spacing={1} style={{ padding: '20px 0' }}>
                {data.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card style={{ borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                            <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '16px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', height: '54px' }}>
                                    <div style={{ backgroundColor: `${item.color}33`, borderRadius: '50%', padding: '12px', marginRight: '6px', width: '30px', height: '30px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        <FontAwesomeIcon icon={item.icon} style={{ color: item.color, fontSize: '24px' }} />
                                    </div>
                                    <div>
                                        <Typography
                                            variant="h5"
                                            style={{ fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'clamp(16px, 2.5vw, 24px)' }}>
                                            {item.value}
                                        </Typography>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {item.label}
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <div style={{ overflowX: 'auto' }}>
                <table className="list">
                    <thead>
                        {reportType === 'inventory' && (
                            <tr>
                                <th>STT</th>
                                <th>Mã sản phẩm</th>
                                <th>Tên sản phẩm</th>
                                <th>Tồn đầu kỳ</th>
                                <th>Giá trị đầu kỳ</th>
                                <th>Số lượng nhập</th>
                                <th>Giá trị nhập</th>
                                <th>Số lượng xuất</th>
                                <th>Giá trị xuất</th>
                                <th>Tồn cuối kỳ</th>
                                <th>Giá trị cuối kỳ</th>
                            </tr>
                        )}
                        {reportType === 'outOfStock' && (
                            <tr>
                                <th>STT</th>
                                <th>Mã sản phẩm</th>
                                <th>Tên sản phẩm</th>
                                <th>Danh mục</th>
                                <th>Đơn vị</th>
                                <th>Số lượng cảnh báo</th>
                                <th>Tổng số lượng</th>
                                <th>#</th>
                            </tr>
                        )}
                        {reportType === 'expired' && (
                            <tr>
                                <th>STT</th>
                                <th>Mã sản phẩm</th>
                                <th>Tên sản phẩm</th>
                                <th>Danh mục</th>
                                <th>Số lô</th>
                                <th>Đơn vị</th>
                                <th>Ngày hết hạn</th>
                                <th>Ngày còn lại</th>
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {reportType === 'inventory' &&
                            (inventoryReportList?.content ? (
                                inventoryReportList?.content.length > 0 ? (
                                    inventoryReportList.content.map((inventory, index) => (
                                        <tr key={index}>
                                            <td>{(index + 1) + ((currentPage - 1) * 10)}</td>
                                            <td>{inventory?.productCode}</td>
                                            <td>{inventory?.productName}</td>
                                            <td>{inventory?.beginningInventoryQuantity}</td>
                                            <td>{renderCurrency(inventory?.beginningInventoryAmount)}</td>
                                            <td>{inventory?.goodsReceivedQuantity}</td>
                                            <td>{renderCurrency(inventory?.goodsReceivedAmount)}</td>
                                            <td>{inventory?.goodsIssuedQuantity}</td>
                                            <td>{renderCurrency(inventory?.goodsIssuedAmount)}</td>
                                            <td>{inventory?.endingInventoryQuantity}</td>
                                            <td>{renderCurrency(inventory?.endingInventoryAmount)}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={11} style={{ padding: '20px' }}>
                                            Không có dữ liệu
                                        </td>
                                    </tr>
                                )
                            ) : (
                                <tr>
                                    <td colSpan={11} style={{ padding: '20px' }}>
                                        <LinearProgress color="inherit" />
                                    </td>
                                </tr>
                            ))
                        }

                        {reportType === 'outOfStock' &&
                            (outOfStockReportList?.content ? (
                                outOfStockReportList?.content.length > 0 ? (
                                    outOfStockReportList.content.map((outOfStock, index) => (
                                        <tr key={index}>
                                            <td>{(index + 1) + ((currentPage - 1) * 10)}</td>
                                            <td>{outOfStock?.productCode}</td>
                                            <td>{outOfStock?.productName}</td>
                                            <td>{outOfStock?.categoryName}</td>
                                            <td>{outOfStock?.unitName}</td>
                                            {currentIndex === index ? (
                                                <td>
                                                    <TextField
                                                        style={{ width: '112px' }}
                                                        type="number"
                                                        value={tempWaringNumber ? tempWaringNumber : Number(outOfStock?.numberWarning || 0)}
                                                        onChange={(e) => {
                                                            const value = Number(e.target.value);
                                                            if (value >= 0) {
                                                                setTempWaringNumber(e.target.value)
                                                            }
                                                        }}
                                                        variant="standard"
                                                    />
                                                </td>
                                            ) : (
                                                <td>{outOfStock?.numberWarning}</td>
                                            )}
                                            <td>{outOfStock?.totalQuantity}</td>
                                            <td>
                                                {currentIndex !== index ? (
                                                    <button className="edit-btn" onClick={() => {
                                                        setCurrentIndex(index)
                                                        setTempWaringNumber(null);
                                                    }}>
                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                    </button>
                                                ) : (
                                                    <>
                                                        <button
                                                            className="detail-btn"
                                                            onClick={() => { updateWarningNumber(outOfStock.productId, tempWaringNumber ? tempWaringNumber : Number(outOfStock?.numberWarning || 0)) }}>
                                                            <FontAwesomeIcon icon={faSave} />
                                                        </button>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={8} style={{ padding: '20px' }}>
                                            Không có dữ liệu
                                        </td>
                                    </tr>
                                )
                            ) : (
                                <tr>
                                    <td colSpan={8} style={{ padding: '20px' }}>
                                        <LinearProgress color="inherit" />
                                    </td>
                                </tr>
                            ))
                        }

                        {reportType === 'expired' &&
                            (expiredReportList?.content ? (
                                expiredReportList?.content.length > 0 ? (
                                    expiredReportList.content.map((expired, index) => (
                                        <tr key={index}>
                                            <td>{(index + 1) + ((currentPage - 1) * 10)}</td>
                                            <td>{expired?.productCode}</td>
                                            <td>{expired?.productName}</td>
                                            <td>{expired?.categoryName}</td>
                                            <td>{expired?.batchNumber}</td>
                                            <td>{expired?.unitName}</td>
                                            <td>{renderDate(expired?.expiryDate)}</td>
                                            <td>{expired?.daysRemaining}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={8} style={{ padding: '20px' }}>
                                            Không có dữ liệu
                                        </td>
                                    </tr>
                                )
                            ) : (
                                <tr>
                                    <td colSpan={8} style={{ padding: '20px' }}>
                                        <LinearProgress color="inherit" />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            {totalPage > 1 && (
                <div className="pagination">
                    {currentPage === 1 ? (
                        <button disabled>&laquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</button>
                    )}
                    {startPage > 1 && (
                        <>
                            <button onClick={() => setCurrentPage(1)}>1</button>
                            {startPage > 2 && (
                                <button disabled>...</button>
                            )}
                        </>
                    )}
                    {visiblePages.map((page) => (
                        <button onClick={() => setCurrentPage(page)}
                            key={page}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}
                    {endPage < totalPage && (
                        <>
                            {endPage < totalPage - 1 && (
                                <button disabled>...</button>
                            )}
                            <button onClick={() => setCurrentPage(totalPage)}>{totalPage}</button>
                        </>
                    )}
                    {currentPage === totalPage ? (
                        <button disabled>&raquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</button>
                    )}
                </div>
            )}
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
        </div>
    );
};

export default ReportInventory;
