import React, { useEffect, useState } from 'react';
import '../../styles/User.css';
import DefaultTemplate from '../../templates/DefaultTemplate';
import SaleListLayout from '../../layouts/SaleListLayout';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';

const SaleList = () => {
  const [sales, setSales] = useState([]);
  const [orderType, setOrderType] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
  const [loading, setLoading] = useState(true);

  const getSales = async (orderType, fromDate, toDate, paymentMethod, invoiceNumber, page) => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
    try {
      let url = `/api/sale/get-all-sale-order-paging?page=${page - 1}&size=10`;
      if (orderType) url += `&orderType=${orderType}`;
      if (invoiceNumber) url += `&invoiceNumber=${invoiceNumber}`;
      if (fromDate) url += `&fromDate=${fromDate}`;
      if (toDate) url += `&toDate=${toDate}`;
      if (paymentMethod) url += `&paymentMethod=${paymentMethod}`;
      console.log("URL:", url);
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = response.data;
      setSales(data);
    } catch (error) {
      if (error.status === 404) {
        setSales([]);
      } else if (error.status >= 400 && error.status < 500) {
        toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [fromDate, toDate, orderType, paymentMethod, invoiceNumber]);

  useEffect(() => {
    getSales(orderType, fromDate, toDate, paymentMethod, invoiceNumber, currentPage);
  }, [fromDate, toDate, orderType, paymentMethod, invoiceNumber, currentPage]);

  return (
    <DefaultTemplate>
      {sales && (
        <SaleListLayout
          sales={sales}
          getSales={() => getSales(orderType, fromDate, toDate, paymentMethod, invoiceNumber, 1)}
          setOrderType={setOrderType}
          setInvoiceNumber={setInvoiceNumber}
          setPaymentMethod={setPaymentMethod}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setFromDate={setFromDate}
          setToDate={setToDate}
          loading={loading}
          onSearch={() => {
            setCurrentPage(1);
            getSales(orderType, fromDate, toDate, paymentMethod, invoiceNumber, 1);
          }}
        />
      )}
    </DefaultTemplate>
  );
};

export default SaleList;
