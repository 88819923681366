import React, { useState } from 'react';
import '../styles/Navbar.css';
import logo from '../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccountCircle } from '@mui/icons-material';
import { faBoxOpen, faDolly, faMoneyBillWave, faChartLine, faUsers, faBell, faUser, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

function LoginNavbar() {
  const [isProductsOpen, setProductsOpen] = useState(false);
  const [isImportExportOpen, setImportExportOpen] = useState(false);
  const [isRevenueOpen, setRevenueOpen] = useState(false);
  const [isManagementOpen, setManagementOpen] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const id = sessionStorage.getItem('id');

  const toggleProducts = () => {
    setProductsOpen(!isProductsOpen);
  };

  const toggleImportExport = () => {
    setImportExportOpen(!isImportExportOpen);
  };

  const toggleRevenue = () => {
    setRevenueOpen(!isRevenueOpen);
  };

  const toggleManagement = () => {
    setManagementOpen(!isManagementOpen);
  };

  const toggleSetting = () => {
    setSettingsOpen(!isSettingsOpen);
  }

  return (
    <nav className="navbar2">
      <div className="navbar-container">
        <a href="/" className="navbar-logo">
          <img src={logo} alt="Pharmacy Logo" />
        </a>
        {id && (
          <ul className={`navbar-menu`}>
            <li className="menu-item">
              <button onClick={toggleProducts} className="dropdown-button">
                <FontAwesomeIcon icon={faBoxOpen} />
                <p style={{ margin: '0 10px' }}>Sản Phẩm</p>
                <FontAwesomeIcon icon={isProductsOpen ? faCaretUp : faCaretDown} />
              </button>
              {isProductsOpen && (
                <div className="dropdown-content">
                  <a href="/products">Danh sách sản phẩm</a>
                  <a href="/type">Nhóm sản phẩm</a>
                  <a href="/unit">Đơn vị sản phẩm</a>
                </div>
              )}
            </li>
            <li className="menu-item">
              <button onClick={toggleImportExport} className="dropdown-button">
                <FontAwesomeIcon icon={faDolly} />
                <p style={{ margin: '0 10px' }}>Nhập Xuất</p>
                <FontAwesomeIcon icon={isImportExportOpen ? faCaretUp : faCaretDown} />
              </button>
              {isImportExportOpen && (
                <div className="dropdown-content">
                  <a href="/import">Nhập Kho</a>
                  <a href="/export">Xuất Kho</a>
                  <a href="/sale">Bán Hàng</a>
                </div>
              )}
            </li>
            <li className="menu-item">
              <button onClick={toggleRevenue} className="dropdown-button">
                <FontAwesomeIcon icon={faMoneyBillWave} />
                <p style={{ margin: '0 10px' }}> Thu - Chi </p>
                <FontAwesomeIcon icon={isRevenueOpen ? faCaretUp : faCaretDown} />
              </button>
              {isRevenueOpen && (
                <div className="dropdown-content">
                  <a href="/revenue/income">Phiếu Thu - Chi</a>
                  <a href="/revenue/expenses">Công Nợ</a>
                </div>
              )}
            </li>
            <li className="menu-item">
              <a href="/reports"><FontAwesomeIcon icon={faChartLine} />
                <p style={{ margin: '0 10px' }}> Báo Cáo </p>
              </a>
            </li>
            <li className="menu-item">
              <button onClick={toggleManagement} className="dropdown-button">
                <FontAwesomeIcon icon={faUsers} />
                <p style={{ margin: '0 10px' }}> Mối Quan Hệ </p>
                <FontAwesomeIcon icon={isManagementOpen ? faCaretUp : faCaretDown} />
              </button>
              {isManagementOpen && (
                <div className="dropdown-content">
                  <a href="/user">Nhân Viên</a>
                  <a href="/customer">Khách Hàng</a>
                  <a href="/supplier">Nhà Cung Cấp</a>
                  <a href="/doctor">Bác Sĩ</a>
                </div>
              )}
            </li>
          </ul>
        )}
        {id ? (
          <div className="navbar-icons">
            <div>
              <a className='notification-btn' href="/notifications">
                <FontAwesomeIcon icon={faBell} className="icon bell-icon" />
              </a>
            </div>
            <div>
              <button onClick={toggleSetting} className='dropdown-button'>
                <FontAwesomeIcon icon={faUser} style={{paddingTop: '2px', paddingBottom: '2px'}}/>
              </button>
              {isSettingsOpen && (
                <div className="dropdown-content" style={{right: '20px', top: '50px'}}>
                  <a href="#">Thông tin cửa hàng</a>
                  <a href="/change-password">Đổi mật khẩu</a>
                  <a href="#" onClick={() => {
                    sessionStorage.removeItem('id');
                    sessionStorage.removeItem('token');
                    sessionStorage.removeItem('username');
                    window.location.href = '/login';
                  }}>
                    Đăng xuất
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : (
          <button class="login-btn">
            <a href="/login">
              <span className="icon">
                <AccountCircle />
              </span>
              Đăng nhập
            </a>
          </button>
        )}
      </div>
    </nav>
  );
}

export default LoginNavbar;
