import React, { useEffect, useState } from 'react';
import '../../styles/User.css';
import DefaultTemplate from '../../templates/DefaultTemplate';
import DoctorListLayout from '../../layouts/DoctorLayout';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';

const DoctorList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [doctors, setDoctors] = useState([]);
  const [status, setStatus] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [loading, setLoading] = useState(true);

  const getDoctor = async (status, doctorName, page) => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
    try {
      let url = `/api/doctor/get-all-doctor?page=${page - 1}`;
      if (doctorName) url += `&doctorName=${doctorName}`;
      if (status) url += `&status=${status}`;
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setDoctors(data);
    } catch (error) {
      if (error.status === 404) {
        setDoctors([]);
      } else if (error !== 404 && error.status >= 400 && error.status < 500) {
        toast.error("Lỗi khi tải dữ liệu. Vui lòng thử lại sau!");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [status, doctorName]);

  useEffect(() => {
    getDoctor(status, doctorName, currentPage);
  }, [currentPage, status, doctorName]);

  return (
    <DefaultTemplate>
      <DoctorListLayout
        doctors={doctors}
        setStatus={setStatus}
        setDoctorName={setDoctorName}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        getDoctor={() => getDoctor(status, doctorName, 1)}
        onSearch={() => {
          setCurrentPage(1);
          getDoctor(status, doctorName, 1);
        }}
      />
    </DefaultTemplate>
  );
};

export default DoctorList;
