import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import '../styles/Import.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faEye, faRotateLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress, Button, Menu, MenuItem, CircularProgress } from '@mui/material';
import { FiDownload } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../api/axiosConfig';

const SaleListLayout = ({ sales, currentPage, setFromDate, loading, setToDate, setCurrentPage, setOrderType, setPaymentMethod, setInvoiceNumber, onSearch }) => {
    const navigate = useNavigate();
    const today = new Date();
    const [dateRange, setDateRange] = useState([today, today]);
    const [totalPage, setTotalPage] = useState(0);
    const pageSize = sales ? sales.size : 10;
    const [downloading, setDownloading] = useState(false);

    const calculateTotalPages = (total, size) => {
        if (size === 0) return 0;
        return Math.ceil(total / size);
    };

    useEffect(() => {
        if (sales) {
            setTotalPage(calculateTotalPages(sales.total, sales.size));
        }
    }, [sales]);

    const getVisiblePages = () => {
        const maxButtons = 3;
        const half = Math.floor(maxButtons / 2);
        let startPage, endPage;

        if (totalPage <= maxButtons) {
            startPage = 1;
            endPage = totalPage;
        } else {
            if (currentPage <= half) {
                startPage = 1;
                endPage = maxButtons;
            } else if (currentPage + half >= totalPage) {
                startPage = totalPage - maxButtons + 1;
                endPage = totalPage;
            } else {
                startPage = currentPage - half;
                endPage = currentPage + half;
            }
        }
        return { startPage, endPage };
    }

    const convertToLocalDate = (date) => {
        if (date) {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);

            return newDate.toISOString().split('T')[0];
        }
        return null;
    };

    const { startPage, endPage } = getVisiblePages();
    const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const currentDate = new Date();

    const calculateSaleDatePlus1Week = (value) => {
        const saleDate = new Date(value * 1000);
        return new Date(saleDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    };

    const getExportExcel = async () => {
        setDownloading(true);
        if (!dateRange) {
            toast.error('Vui lòng chọn thời gian');
            setDownloading(false);
            return;
        }
        const token = sessionStorage.getItem('token');
        try {
            if (dateRange && dateRange[0] && dateRange[1]) {
                let url = `/api/sale/export-excel-sale-orders?fromDate=${convertToLocalDate(
                    dateRange[0]
                )}&toDate=${convertToLocalDate(dateRange[1])}`;
                const response = await api.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                });
                const blobUrl = window.URL.createObjectURL(response.data);
                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = 'Danh_sach_ban_hang.xlsx';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            console.error(error);
            toast.error('Lỗi khi tải dữ liệu. Vui lòng thử lại sau!');
        } finally {
            setDownloading(false);
        }
    };

    return (
        <div className="container">
            <div className="header">
                <h2 className='title-name'>Bán Hàng</h2>
                <div className="btnSearch" style={{ marginBottom: '0' }}>
                    <div className="date-range-picker">
                        <Flatpickr
                            value={dateRange}
                            onChange={([startDate, endDate]) => {
                                setDateRange([startDate, endDate])
                                setFromDate(convertToLocalDate(startDate))
                                setToDate(convertToLocalDate(endDate))
                            }}
                            options={{
                                mode: "range",
                                dateFormat: "d/m/Y",
                                locale: {
                                    rangeSeparator: " ~ ",
                                },
                            }}
                            placeholder="__/__/____ ~ __/__/____"
                        />
                        <span className="icon">&#x1F4C5;</span>
                    </div>
                    <select className="custom-select" name="orderType" onChange={(e) => setOrderType(e.target.value)}>
                        <option value="" selected>Loại hóa đơn</option>
                        <option value="NORMAL">Bán thường</option>
                        <option value="PRESCRIPTION">Bán theo đơn của bác sĩ</option>
                    </select>
                    <select className="custom-select" name="paymentMethod" onChange={(e) => setPaymentMethod(e.target.value)}>
                        <option value="" selected>Phương thức thanh toán</option>
                        <option value="CASH">Tiền mặt</option>
                        <option value="TRANSFER">Chuyển khoản</option>
                    </select>
                    <div className="search-input-container">
                        <input
                            name="name"
                            type="text"
                            style={{ width: '150px' }}
                            placeholder="Nhập mã hóa đơn..."
                            onChange={(e) => setInvoiceNumber(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }}
                            autocomplete="off"
                        />
                        <FontAwesomeIcon icon={faSearch} className="search-icon" onClick={onSearch} />
                    </div>
                    <div className="btnAdd">
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                            <Button variant="contained" onClick={handleClick} style={{ fontSize: '14px', padding: '6px 14px' }}>
                                Thêm
                            </Button>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                                <MenuItem onClick={() => { navigate("/sale/create/receipt"); handleClose(); }}>Bán thường</MenuItem>
                                <MenuItem onClick={() => { navigate("/sale/create/prescription"); handleClose(); }}>Bán theo đơn của bác sĩ</MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
                <div className="file-buttons">
                    <Button className="export-btn" style={{ backgroundColor: "#09446d", color: '#fff' }} onClick={getExportExcel}>
                        Xuất file
                        <FiDownload />
                    </Button>
                </div>
            </div>
            <div style={{ overflowX: 'auto' }}>
                <table className="list">
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Mã hóa đơn</th>
                            <th>Ngày tạo hóa đơn</th>
                            <th>Người bán</th>
                            <th>Loại hóa đơn</th>
                            <th>Phương thức thanh toán</th>
                            <th>Tổng tiền</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan={8} style={{ padding: '20px' }}>
                                    <LinearProgress color="inherit" />
                                </td>
                            </tr>
                        ) : (
                            sales.listData?.length > 0 ? sales.listData.map((item, index) => (
                                <tr key={item.id}>
                                    <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                    <td>{item.invoiceNumber}</td>
                                    <td>{item.saleDate ? new Date(item.saleDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : '../../....'}</td>
                                    <td>{item.fullName}</td>
                                    <td>{item.orderType === 'NORMAL' ? 'Bán thường' : 'Bán theo đơn của bác sĩ'}</td>
                                    <td style={{ width: '120px' }}>{item.paymentMethod === 'CASH' ? 'Tiền mặt' : 'Chuyển khoản'}</td>
                                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.totalAmount)}</td>
                                    <td>
                                        <a href={`${item.orderType === 'NORMAL' ? `/sale/receipt/detail/${item.id}` : `/sale/prescription/detail/${item.id}`} `} className="detail-btn">
                                            <FontAwesomeIcon icon={faEye} />
                                        </a>
                                        {item.paymentStatus !== 'PAID' && (
                                            <a href={`${item.orderType === 'NORMAL' ? `/sale/receipt/edit/${item.id}` : `/sale/prescription/edit/${item.id}`} `} className="edit-btn">
                                                <FontAwesomeIcon icon={faPenToSquare} />
                                            </a>
                                        )}
                                        {item.paymentStatus === 'PAID' && item.checkBackOrder !== true && currentDate < calculateSaleDatePlus1Week(item?.saleDate) && (
                                            <a href={`/return-order/create/${item.id}`} className="delete-btn">
                                                <FontAwesomeIcon icon={faRotateLeft} />
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={8} style={{ padding: '20px' }}>
                                        Hóa đơn không tồn tại
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
            {totalPage > 1 && (
                <div className="pagination">
                    {currentPage === 1 ? (
                        <button disabled>&laquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</button>
                    )}
                    {startPage > 1 && (
                        <>
                            <button onClick={() => setCurrentPage(1)}>1</button>
                            {startPage > 2 && (
                                <button disabled>...</button>
                            )}
                        </>
                    )}
                    {visiblePages.map((page) => (
                        <button onClick={() => setCurrentPage(page)}
                            key={page}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}
                    {endPage < totalPage && (
                        <>
                            {endPage < totalPage - 1 && (
                                <button disabled>...</button>
                            )}
                            <button onClick={() => setCurrentPage(totalPage)}>{totalPage}</button>
                        </>
                    )}
                    {currentPage === totalPage ? (
                        <button disabled>&raquo;</button>
                    ) : (
                        <button onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</button>
                    )}
                </div>
            )}
            {downloading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 99999,
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
        </div>
    );
};

export default SaleListLayout;