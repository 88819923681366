import React, { useState } from 'react';
import { Button } from '@mui/material';
import { AccountCircle, Lock } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import api from "../../api/axiosConfig";
import '../../styles/Login.css';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    fcmToken: localStorage.getItem("fcmToken")
  });

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    console.log(localStorage.getItem("fcmToken"));
    console.log(formData);
    try {
      e.preventDefault();
      const respone = await api.post("api/auth/login", formData, {
        headers: { "Content-Type": "application/json" },
      });
      const rdata = await respone.data;
      sessionStorage.setItem("id", rdata.id);
      sessionStorage.setItem("token", rdata.token);
      sessionStorage.setItem("username", rdata.username);
      sessionStorage.setItem("avatar", rdata.avatar);
      sessionStorage.setItem("fullName", rdata.fullName);
      sessionStorage.setItem("roles", rdata.roles);
      navigate("/");
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Không thể đăng nhập, vui lòng thử lại.');
      }
    }
  };
  return (
    <div className="login-container">
      <ToastContainer />
      <div className="background-image" />
      <form className="form-contain" onSubmit={handleSubmit}>
        <div className="logo-container">
          <img src={require('../../assets/images/logo1.png')} alt="Pharmacy Logo" className="logo" />
        </div>
        <div className="input-field">
          <span className="icon">
            <AccountCircle />
          </span>
          <input
            id="username"
            name="username"
            type="text"
            placeholder="Tên đăng nhập"
            className="input-element"
            onChange={handleChange}
          />
        </div>
        <div className="input-field" style={{ marginBottom: '0' }}>
          <span className="icon">
            <Lock />
          </span>
          <input
            id="password"
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Mật khẩu"
            className="input-element"
            onChange={handleChange}
          />
          <span onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon color='#65676d' icon={showPassword ? faEyeSlash : faEye} />
          </span>
        </div>
        <div className="forgot-password">
          <a href="/forgot-password">Quên mật khẩu?</a>
        </div>
        <Button
          variant="contained"
          fullWidth
          className="login-button"
          type='submit'
        >
          Đăng nhập
        </Button>
      </form>
      <div className="footer-text">
        <a>
          Pharmacy © 2024 SEP490_G27
        </a>
      </div>
    </div>
  );
};

export default Login;
